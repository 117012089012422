import React from 'react'
import { ModalProvider } from '@diviner-protocol/uikit'
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import getLibrary from 'utils/web3React'
import { LanguageContextProvider } from 'contexts/Localisation/languageContext'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
// eslint-disable-next-line
import store, { persistor } from 'state'
import { ApolloClient, InMemoryCache, ApolloProvider, DefaultOptions } from '@apollo/client'
const Web3ProviderNetwork = createWeb3ReactRoot('NETWORK')
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export const client = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/lucky-kh1em/dstakingnft',
  cache: new InMemoryCache(),
  defaultOptions,
})
const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <HelmetProvider>
              <ApolloProvider client={client}>
                <ThemeContextProvider>
                  {/* <LanguageContextProvider> */}
                  <RefreshContextProvider>
                    <ModalProvider>{children}</ModalProvider>
                  </RefreshContextProvider>
                  {/* </LanguageContextProvider> */}
                </ThemeContextProvider>
              </ApolloProvider>
            </HelmetProvider>
          </PersistGate>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  )
}

export default Providers
