import React from 'react'
import styled from 'styled-components'

function CivilianViewSkin({onClick = null}) {
  return (
    <CivilianViewSkinStyled onClick={onClick}>
      <img src="./images/civilian-fusion/view-skin.svg" alt="view-skin" />
      <span>View Skin</span>
    </CivilianViewSkinStyled>
  )
}

const CivilianViewSkinStyled = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  img {
    width: 32px;
    margin-right: 12px;
  }
`
export default CivilianViewSkin
