import React from 'react'
import styled from 'styled-components'

import AutoScaleContain from '../AutoScaleContain'

const ContainerStyled = styled.div`
  position: relative;
`

const ImageRelative = ({ src, width, height, children, ...otherProps }) => {
  return (
    <AutoScaleContain>
      <ContainerStyled className="container" style={{ width, height }}>
        <img src={src} width={width} height={height} alt="" />
        {children}
      </ContainerStyled>
    </AutoScaleContain>
  )
}

export const ImageAbsolute = ({ src, width, height, top = 0, left = 0, isCenterIsland = false, ...otherProps }) => {
  return (
    <img
      src={src}
      alt=""
      style={{
        position: 'absolute',
        width,
        height,
        top,
        left,
        clipPath:
          isCenterIsland &&
          'polygon(39% 1%, 56% 0, 65% 35%, 99% 40%, 99% 98%, 50% 100%, 0 90%, 0 54%, 10% 37%, 34% 36%)',
      }}
      {...otherProps}
    />
  )
}

const ImgHighlighStyled = styled(ImageAbsolute)`
  --hover-color: #ff9502;
  cursor: pointer;
  transition: all 0.5s;
  filter: drop-shadow(0px 0px 0px #ffffff) drop-shadow(0px 0px 0px #ffffff) drop-shadow(0px 0px 0px #ffffff)
    drop-shadow(0px 0px 0px #ffffff);

  &:disabled,
  &[disabled] {
    /* filter: grayscale(100%) !important; */
    cursor: default;
    cursor: not-allowed;
  }
  /* clip-path: polygon(39% 1%, 56% 0, 65% 35%, 99% 40%, 99% 98%, 50% 100%, 0 90%, 0 54%, 10% 37%, 34% 36%); */
  /* clip-path: circle(80px at 50% 50%); */
  /* clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%); */

  :hover {
    filter: drop-shadow(-2px 0px 2px var(--hover-color)) drop-shadow(-2px 2px 2px var(--hover-color))
      drop-shadow(2px 2px 2px var(--hover-color)) drop-shadow(2px -2px 2px var(--hover-color));
  }
`

export const ImageHighlighAbsolute = ({ disabled = false, onClick = undefined, ...props }) => {
  return <ImgHighlighStyled {...props} disabled={disabled} onClick={disabled ? undefined : onClick} />
}

export default ImageRelative
