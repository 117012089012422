import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { priceList } from 'config/constants/prediction'
import { PredictionConfig } from 'config/constants/types'
import { useApproveCallbackCustom } from 'hooks/useApproveCallback'
import { usePredictionDepositV2 } from 'hooks/usePrediction'
import { useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchPredictionPoolV2, fetchPredictionUserDataV2 } from 'state/actionsV2'
import { useTransactionAdder } from 'state/transactions/hooks'
import { usePredictionFromPid, useActiveRoundIdSelectorV2 } from 'state/predictionV2/selector'
import styled from 'styled-components'
import { getPredictionAddressV2 } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import ButtonDepositV2 from 'components2/Modal/ButtonDepositV2'
import CardValue from 'components/CardValue'
import JoinTopModal from 'components2/Modal/DepositWithdrawModal/components/JoinTopModal'
import Input from 'components2/Modal/DepositWithdrawModal/components/Input'
import JoinBottomModal from './JoinBottomModal'

interface JoinProps {
  onSelectPool: (number) => void
  poolData: PredictionConfig
  isLong: boolean
}

const Join: React.FunctionComponent<JoinProps> = ({ onSelectPool, poolData, isLong }) => {
  const [value, setValue] = useState('0')
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isDepositing, setIsDepositing] = useState<boolean>(false)

  const { userData } = usePredictionFromPid(poolData.pid)

  const addTransaction = useTransactionAdder()

  const totalUserDeposit = isLong
    ? new BigNumber(userData?.longAmount).div(1e18).toString()
    : new BigNumber(userData?.shortAmount).div(1e18).toString()

  const dispatch = useAppDispatch()
  const { account, chainId } = useWeb3React()
  const balances = new BigNumber(userData?.balances)
  const formatBalance = getBalanceNumber(balances)
  const activeRoundId = useActiveRoundIdSelectorV2()
  const [onApprove] = useApproveCallbackCustom(poolData.lpAddresses[chainId], getPredictionAddressV2()[activeRoundId])

  const { onDeposit } = usePredictionDepositV2(parseInt(poolData.pid))

  const handleDeposit = async () => {
    if (value === '0' || value === '') return

    setIsDepositing(true)
    try {
      let tx

      if (isLong) tx = await onDeposit(value, '0')
      else tx = await onDeposit('0', value)

      tx.wait().then(() => {
        addTransaction(tx, {
          summary: 'Deposit successfully!',
        })
        dispatch(fetchPredictionPoolV2())

        if (account && chainId) {
          dispatch(fetchPredictionUserDataV2(account))
        }

        setIsDepositing(false)
      })
    } catch (error) {
      setIsDepositing(false)
    }
  }

  const handleApprove = async () => {
    setIsApproving(true)
    try {
      const tx = await onApprove()
      await tx?.wait()
      setIsApproving(false)
      dispatch(fetchPredictionUserDataV2(account))
    } catch (error) {
      setIsApproving(false)
    }
  }

  const handleChangeValue = (e) => {
    if (parseFloat(e.target.value) > new BigNumber(userData?.balances).div(1e18).toNumber()) return
    if (parseFloat(e.target.value) < 0) return
    setValue(e.target.value)
  }

  const handleChangeValueWithPercent = (percent: number) => {
    if (balances) {
      if (percent === 100)
        setValue(
          balances
            // .multipliedBy(percent / 100)
            .div(1e18)
            .toString(),
        )
      else
        setValue(
          balances
            .multipliedBy(percent / 100)
            .div(1e18)
            .toFixed(2)
            .toString(),
        )
    }
  }
  console.log('totalUserDepositV2', totalUserDeposit)
  return (
    <>
      <JoinTopModal poolData={poolData} onSelectPool={onSelectPool} setValue={setValue} />
      <GetCake href={poolData.linkPancake} target="_blank">
        <span>Get {poolData.symbol}</span> <img src="./images/icon-get.png" alt="" />
      </GetCake>
      <JoinTablesContent>
        <p>How much?</p>
        <Input value={value} type="number" onChange={handleChangeValue} />
        <p className="balance">
          Balance:
          <span>
            <CardValue value={formatBalance || 0} decimals={2}></CardValue>
          </span>
        </p>
        <div className="jointables-price">
          <div className="price">
            {priceList.map((price) => (
              <img
                key={price.id}
                src={price.src}
                alt="Price10.png"
                onClick={() => handleChangeValueWithPercent(price.price)}
              />
            ))}
          </div>
        </div>
      </JoinTablesContent>
      <JoinBottomModal totalUserDeposit={totalUserDeposit} tokenSymbol={poolData.symbol} />
      {userData && userData?.allowance === '0' ? (
        <ButtonDepositV2 title="Approve" onDeposit={handleApprove} isLoading={isApproving} />
      ) : (
        <ButtonDepositV2 title="Deposit" onDeposit={handleDeposit} isLoading={isDepositing} />
      )}
    </>
  )
}

const JoinTablesContent = styled.div`
  .price {
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
      width: 70px;
    }
  }
  .balance {
    font-size: 20px;
  }
`

const GetCake = styled.a`
  color: #51a5f3;
  font-size: 1.5em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    padding-left: 10px;
  }
`

export default Join
