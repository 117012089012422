import BigNumber from 'bignumber.js'
import AutoScaleContain from 'components2/Common/AutoScaleContain'
import GlobalModal from 'components2/static/GlobalModal'
import MemberGuard from 'guards/member/MemberGuard'
import { useActiveWeb3React } from 'hooks'
import useWeb3ReactManager from 'hooks/useWeb3ReactManager'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { fetchRewardsPool } from 'state/gameDb/actions'
import { useFetchPriceList } from 'state/hooks'
import styled from 'styled-components'
import CivilianFusion from 'views2/Games/CivilianFusion'
import Inventory from 'views2/Games/CivilianInventory'
import Migrate from 'views2/Games/Migrate'
import Staking from 'views2/Games/Staking'
import StakingNft from 'views2/Games/Staking/StakingNft'
import HomepageGame from 'views2/HomepageGame'
import PlayNow from 'views2/PlayNow'
import FlipCards from 'views2/PlayNow/Conponents/FlipCards'
import SpinnerMachine from 'views2/PlayNow/Conponents/SpinnerMachine'
import Wheel from 'views2/PlayNow/Conponents/Wheel'
import PredictionGameV1 from 'views2/PredictionGameV1'
import PredictionGameV2 from 'views2/PredictionGameV2'
import Popups from './components/Popups'
import './index.scss'
import Pages from './Pages'

function fakeRequest() {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), 1500))
}

const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
}
// This config is required for number formating
BigNumber.config({ FORMAT: fmt, EXPONENTIAL_AT: 1000, DECIMAL_PLACES: 80 })
const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released 12
  const [sizeWindow, setSizeWindow] = useState<any>()
  const dispatch = useDispatch()
  const handleResize = (e) => {
    setSizeWindow({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }
  useEffect(() => {
    console.warn = () => null
    setSizeWindow({
      width: window.innerWidth,
      height: window.innerHeight,
    })
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const { account, chainId } = useActiveWeb3React()

  useWeb3ReactManager()
  useFetchPriceList()

  useEffect(() => {
    if (!window.localStorage.getItem('reset')) {
      console.log('reset')
      window.localStorage.removeItem('persist:root')
      window.localStorage.setItem('reset', '1')
    }
  }, [])

  useEffect(() => {
    if (chainId) {
      window.localStorage.setItem('chainId', chainId.toString())
    }
  }, [chainId])
  useEffect(() => {
    dispatch(fetchRewardsPool())
  }, [])

  const [isLoading, setLoading] = useState(true)

  // useEffect(() => {
  //   fakeRequest().then(() => {
  //     setLoading(!isLoading)
  //     if (window.localStorage.RememberFirstLogin === undefined)
  //       GlobalModal.show(<Desclaimer onClose={GlobalModal.hide} />, { onPressBackground: null })
  //   })
  // }, [])

  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          account ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }
      />
    )
  }

  return (
    <HashRouter>
      <Popups />
      {/* {(isLoading || isMobile) && <LoadingFullScreen />} */}
      <FullScreenStyled sizeWindow={sizeWindow}>
        <Switch>
          <AutoScaleContain>
            <FixedScreenSizeStyled>
              <GlobalModal />
              <Route path="/" exact>
                <Pages />
              </Route>

              <Route path="/metacity">
                <MemberGuard>
                  <HomepageGame />
                </MemberGuard>
              </Route>
              <Route path="/fusion">
                <CivilianFusion />
              </Route>
              <Route path="/inventory">
                <Inventory />
              </Route>
              <Route path="/prediction-v1">
                <PredictionGameV1 />
              </Route>
              <Route path="/prediction-v2">
                <PredictionGameV2 />
              </Route>
              <Route path="/convert">
                <Migrate />
              </Route>
              <Route path="/staking">
                <Staking />
              </Route>
              <Route path="/staking-nft">
                <StakingNft />
              </Route>
              <Route path="/play-now">
                <MemberGuard>
                  <PlayNow />
                </MemberGuard>
              </Route>
              <Route path="/flip-cards">
                <FlipCards />
              </Route>
              <Route path="/wheel">
                <Wheel />
              </Route>
              <Route path="/spinner">
                <SpinnerMachine />
              </Route>
            </FixedScreenSizeStyled>
          </AutoScaleContain>

          {/* <Route path="/buy-chest">
            <AutoScaleContain>
              <FixedScreenSizeStyled>
                <BuyChest />
              </FixedScreenSizeStyled>
            </AutoScaleContain>
          </Route> */}
        </Switch>
        {/* <Modal /> */}
      </FullScreenStyled>
    </HashRouter>
  )
}

interface Props {
  sizeWindow: any
}
const FullScreenStyled = styled.div<Props>`
  width: ${(props) => props.sizeWindow?.width + 'px'};
  height: ${(props) => props.sizeWindow?.height + 'px'};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cdecfe;
`

const FixedScreenSizeStyled = styled.div`
  width: 1920px;
  height: 1080px;
  /* background: url('../images/homepage/bg-home.png') no-repeat; */
  position: relative;
  overflow: hidden;

  @media (max-width: 767px) {
    transform: rotate(90deg) scale(1.777);
    transform-origin: center;
  }
`
export default React.memo(App)
