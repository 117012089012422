import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import ButtonGame from 'views2/Games/ButtonGame'
import ButtonStaking from 'views2/Games/Staking/StakingNft/ButtonStaking'

function RarityItem({rarity, rating, job, img, limit, amount, interestPerDay}) {
  return (
    <RarityItemStyled>
      <RarityItemContentStyled>
        <h3>{job}</h3>
        <div
          className="rarity__item-img"
          style={
            rating === 1
              ? {background: '#D2E4FF'}
              : rating === 2
              ? {background: 'linear-gradient(180deg, #E0B8FF 0%, #E69FFF 100%)'}
              : rating === 3
              ? {background: 'linear-gradient(180deg, #FFE2D2 0%, #FFCDB1 100%)  '}
              : rating === 4
              ? {background: 'linear-gradient(180deg, #FFEFC5 0%, #FFE8AC 100%) '}
              : {background: '#EEE2F7'}
          }
        >
          <img src={img} alt="" />
        </div>
        <div className="rarity__item">
          <div className="rarity__item-id">
            <span>#{rating}</span>
          </div>
        </div>
        <div className="rarity__item">
          <RarityContent>
            <span>Remaining slot</span>
            <span className="content__left" style={{color: '#fff'}}>
              {limit - amount}/{limit}
            </span>
          </RarityContent>
          <RarityContent>
            <span>Rating</span>
            <span
              className="content__left"
              style={
                rating === 1
                  ? {color: '#3181CB'}
                  : rating === 2
                  ? {color: '#CF10FF'}
                  : rating === 3
                  ? {color: '#FF9E54'}
                  : rating === 4
                  ? {color: '#F7C832'}
                  : {color: '#F32234'}
              }
            >
              {rating}
            </span>
          </RarityContent>
          <RarityContent>
            <span>Daily earn</span>
            <span className="content__left" style={{color: '#55B3F8'}}>
              {interestPerDay} DPT/day
            </span>
          </RarityContent>
        </div>
        <Link to={`/staking-nft?rating=${rating}&rarity=${rarity}`}>
          <ButtonStaking
            bgStyled={
              rating === 1
                ? {background: 'linear-gradient(90.93deg, #162ADB 12.33%, #65A3FF 97.08%)'}
                : rating === 2
                ? {background: 'linear-gradient(91.08deg, #6923FF 17.54%, #C247FC 96.11%)'}
                : rating === 3
                ? {background: 'linear-gradient(92.7deg, #FF6B00 18.36%, #FFC187 98.45%) '}
                : rating === 4
                ? {background: 'linear-gradient(91.25deg, #FF9900 20.15%, #FFD74B 95.93%)'}
                : {background: 'linear-gradient(91.23deg, #F01111 18.05%, #FF9254 97.97%)'}
            }
          />
        </Link>
      </RarityItemContentStyled>
    </RarityItemStyled>
  )
}

const RarityItemStyled = styled.div`
  width: 330px;
  height: 612px;
  padding: 16px 20px 20px 20px;
  background: linear-gradient(180deg, #0f3072 0%, rgba(0, 0, 2, 0.6) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-top: 60px;
`
const RarityItemContentStyled = styled.div`
  h3 {
    color: #55b3f8;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    padding-bottom: 16px;
    text-align: center;
    text-transform: uppercase;
  }
  .rarity__item-img {
    width: 290px;
    height: 290px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    img {
      width: 250px;
      height: 250px;
    }
  }
  .rarity__item {
    padding: 8px 0px;
    &-id {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      height: 36px;
      width: 54px;
      color: #fff;
      background: rgba(255, 255, 255, 0.5);
    }
  }
`
const RarityContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px;
  color: #999999;
  font-size: 18px;
  &:nth-child(1) {
    padding-top: 0px;
  }
  &:nth-child(3) {
    padding-bottom: 20px;
  }
  .content__left {
    font-weight: 700;
  }
`

export default RarityItem
