import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CivilianItem from 'views2/Games/components/CivilianItem'

ReviewSkinItem.propTypes = {}

function ReviewSkinItem({lv, img, star, rating, power = '???', hp = '???', lucky = '???'}) {
  return (
    <ReviewSkinItemStyled>
      <CivilianItem lv={lv} img={img} star={star} rating={rating} />
      <div className="reviewSkin__rating">
        <div className="reviewSkin__content">
          <h3>Rating: {rating}</h3>
          <div className="reviewSkin__content__item">
            <img src="./images/games/power.svg" alt="power" /> <span>{power}</span>
          </div>
          <div className="reviewSkin__content__item">
            <img src="./images/games/lucky.svg" alt="lucky" /> <span>{lucky}</span>
          </div>
          <div className="reviewSkin__content__item">
            <img src="./images/games/hp.svg" alt="hp" /> <span>{hp}</span>
          </div>
        </div>
      </div>
    </ReviewSkinItemStyled>
  )
}

const ReviewSkinItemStyled = styled.div`
  width: 140px;
  h3 {
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 24px;
  }
  &__rating {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .reviewSkin__content {
    text-align: center;

    .reviewSkin__content__item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 24px;
      img {
        margin-right: 8px;
      }
    }
  }
`
export default ReviewSkinItem
