import ImageRelative, { ImageAbsolute, ImageHighlighAbsolute } from 'components2/Common/ImageRelative'
import Header from 'components2/Layout/Head/headers'
import ConnectWalletModal from 'components2/Modal/ConnectWalletModal'
import GlobalModal from 'components2/static/GlobalModal'
import { useActiveWeb3React } from 'hooks'
import useAuth from 'hooks/useAuth'
import { useEffect } from 'react'
import { BrowserRouter as Router, Link, Redirect } from 'react-router-dom'
import { HarbourBlock, MainContent, Wrapper } from './styled'

export default function DivinerHarbour() {
  const { account, chainId, connector } = useActiveWeb3React()
  const { login, logout } = useAuth()

  useEffect(() => {
    if (!account) {
      GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
    }
  }, [account])

  // const onClickBuilding = (e) => {
  //   if (!account) {
  //     GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
  //   } else {
  //     <Link to='/aa'/>
  //   }
  // }
  // const onClickBuildingGame = (e) => {
  //   if (!account) {
  //     GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
  //   } else {
  //     <Link to="/home" />
  //   }
  // }

  return (
    <Wrapper id="menu_3">
      <HarbourBlock>
        <Header account={account} logout={logout} />
        <MainContent>
          <ImageRelative src="/images/home/ground.png" width={1920} height={1080}>
            <ImageAbsolute src="/images/home/cloud.png" width={392} height={182} top={223} left={1357} />
            <ImageAbsolute src="/images/home/cloud1.png" width={480} height={183} top={771} left={1311} />
            <ImageAbsolute src="/images/home/cloud2.png" width={782} height={362} top={616} left={-157} />
            <ImageAbsolute src="/images/home/cloud3.png" width={202} height={162} top={102} left={470} />

            <a href="https://marketplace.diviner.finance/#/" target="_blank" rel="noreferrer">
              <ImageHighlighAbsolute
                // onClick={onClickBuilding}
                src="/images/home/market-house.png"
                width={279}
                height={160}
                left={695}
                top={152}
              />
            </a>

            {/* {!account && GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)} */}

            <ImageAbsolute src="/images/home/tree.png" width={48} height={49} left={875} top={278} />
            <ImageHighlighAbsolute
              // onClick={onClickBuilding}
              src="/images/home/bank-house.png"
              width={284}
              height={174}
              left={995}
              top={163}
              disabled
            />

            <ImageHighlighAbsolute
              // onClick={onClickBuilding}
              src="/images/home/governance-house.png"
              width={355}
              height={216}
              left={459}
              top={266}
              disabled
            />
            <Link to="/prediction-v2">
              <ImageHighlighAbsolute
                // onClick={onClickBuilding}
                src="/images/home/prediction-house.png"
                width={439}
                height={373}
                left={779}
                top={198}
                isCenterIsland
              />
            </Link>

            <ImageAbsolute src="/images/home/statue.png" width={110} height={121} left={666} top={437} disabled />
            <ImageHighlighAbsolute
              // onClick={onClickBuilding}
              src="/images/home/swap-house.png"
              width={337}
              height={253}
              left={1225}
              top={195}
              disabled
            />
            <a href="https://launchpad.diviner.finance/#/" target="_blank" rel="noreferrer">
              <ImageHighlighAbsolute
                onClick={null}
                src="/images/home/launchpad-house.png"
                width={306}
                height={234}
                left={341}
                top={458}
              />
            </a>
            <ImageHighlighAbsolute
              // onClick={onClickBuilding}
              src="/images/home/socialfi-house.png"
              width={362}
              height={303}
              left={1240}
              top={416}
              disabled
            />
            <Link to="/metacity">
              <ImageHighlighAbsolute
                src="/images/home/game-house.png"
                width={388}
                height={244}
                left={599}
                top={540}
                // onClick={onClickBuildingGame}
              />
            </Link>
            <ImageHighlighAbsolute
              // onClick={onClickBuilding}
              src="/images/home/farm-house.png"
              width={427}
              height={331}
              left={956}
              top={537}
              disabled
            />
          </ImageRelative>
        </MainContent>
      </HarbourBlock>
    </Wrapper>
  )
}
