import predictionConfig from 'config/constants/prediction'
import React, {useState} from 'react'
import styled from 'styled-components'

const dropList = predictionConfig.map((p) => {
  return {
    pid: p.pid,
    symbol: p.symbol,
  }
})

function JoinTopModal({onSelectPool, poolData, setValue}) {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  return (
    <JoinTopModalStyled className="jointables-top" onClick={() => setIsOpenDropdown(!isOpenDropdown)}>
      <DropTopStyled>
        <img src="./images/fileDiviner/drop.png" alt="drop.png" />
      </DropTopStyled>
      <div className="drop-content">
        <p>{poolData.symbol}</p>
      </div>

      {isOpenDropdown && (
        <JoinTablesTopListStyled>
          <div className="Toplist">
            {dropList.map((drop) => (
              <p
                key={drop.pid}
                onClick={() => {
                  setIsOpenDropdown(false)
                  onSelectPool(drop.pid)
                  setValue('0')
                }}
              >
                {drop.symbol}
              </p>
            ))}
          </div>
        </JoinTablesTopListStyled>
      )}
    </JoinTopModalStyled>
  )
}

export const JoinTopModalStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .drop-content {
    p {
      cursor: pointer;
    }
  }
`
export const DropTopStyled = styled.div`
  overflow: hidden;
  cursor: pointer;
  img {
    transition: ease all 0.5s;
    width: 50px;
    &:hover {
      transform: scale(1.1);
    }
  }
`

export const JoinTablesTopListStyled = styled.div`
  position: absolute;
  top: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
  border-radius: 24px;
  padding: 15px;
  width: 200px;
  transform: translate(-50%, 0%);
  left: 50%;
  z-index: 10;
  .Toplist {
    p {
      cursor: pointer;
      transition: ease all 0.5s;
      font-size: 22px;
      font-weight: 400;
      &:hover {
        color: #f9b507;
      }
    }
  }
`

export default JoinTopModal
