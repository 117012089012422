import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mapKeys, omit, isEmpty} from 'lodash'
import {MigrateState, CharacterSpec, ApproveStatus} from 'state/migrate/types'
import {fetchingInventory} from 'state/migrate/actions/actions'
import {fetchInventoryCharacter} from 'state/migrate/actions/inventoryAction'
import {fetchingApproveStatus, setApproveForAll, doMigrate} from 'state/migrate/actions/characterActions'

const initialState: MigrateState = {
  characters: {},
  fetchingInventory: false,
  approvalStatus: {},
}

export const migrateSlicer = createSlice({
  name: 'migrate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchingInventory, (state, action: PayloadAction<boolean>) => {
        state.fetchingInventory = action.payload
      })
      .addCase(fetchInventoryCharacter.fulfilled, (state, action) => {
        state.characters = {...state.characters, ...mapKeys(action.payload, 'tokenId')}
      })
      .addCase(fetchingApproveStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.approvalStatus = {...state.approvalStatus, [action.payload.externalContract]: action.payload}
        }
      })
      .addCase(setApproveForAll.fulfilled, (state, action) => {
        if (action.payload) {
          state.approvalStatus = {...state.approvalStatus, [action.payload.externalContract]: action.payload}
        }
      })
      .addCase(doMigrate.fulfilled, (state, action) => {
        if (action.payload) {
          state.characters = omit(state.characters, action.payload)
        }
      })
  },
})

export default migrateSlicer.reducer
