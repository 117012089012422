import styled from 'styled-components'
export const Center = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);

  .box-coin {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 50px;

    .item:nth-child(1) {
      margin-right: 10px;
    }

    .item:nth-child(2) {
      margin-left: 10px;
    }

    .item {
      position: relative;
      color: #fff;
      font-size: 16px;
      margin: 0 auto;

      img {
        max-width: 40px;
      }

      .icon.right {
        cursor: pointer;
      }

      .icon {
        position: absolute;
        top: -10px;
        left: -10px;
      }

      .right {
        left: unset;
        top: -5px;
        right: -2px;
      }

      span {
        background: rgb(0 0 0 / 30%);
        border-radius: 50px;
        padding: 2px 50px 4px 50px;
        font-weight: bold;
        border-top: 2px solid rgb(0 0 0 / 20%);
      }
    }
  }

  .info {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    width: 100%;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 10.65%,
        #525151 25.88%,
        #555555 76.51%,
        rgba(203, 236, 255, 0) 94.89%
      );
      opacity: 0.5;
      left: 0;
      top: 0;
    }

    p {
      padding: 10px 50px;
      position: relative;
      z-index: 2;
    }

    span {
      color: #6de5ff;
    }

    .overlay {
      height: auto;
      width: auto;
    }
  }
`
