import characterAbi from 'config/abi/character.json'
import erc20ABI from 'config/abi/erc20.json'
import gameConfig, { gameCharacter } from 'config/constants/game'
import tokens from 'config/constants/tokens'
import {
  getAddress,
  getCharacterAddress,
  getFakeCharacterAddress,
  getGameAddress,
  getDptAddress,
  getDgtAddress,
  getBusdAddress,
} from 'utils/addressHelpers'
import { getCharacterContract, getGameContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import { Character } from '../types'

export const fetchGameUserTokenInfo = async (account: string) => {
  const calls = []
  const dptToken = getDptAddress()
  const dgtToken = getDgtAddress()
  const busdToken = getBusdAddress()

  calls.push({ address: dptToken, name: 'balanceOf', params: [account] })
  calls.push({ address: dgtToken, name: 'balanceOf', params: [account] })
  calls.push({ address: busdToken, name: 'balanceOf', params: [account] })
  calls.push({ address: dptToken, name: 'allowance', params: [account, getGameAddress()] })
  calls.push({ address: dgtToken, name: 'allowance', params: [account, getGameAddress()] })
  calls.push({ address: busdToken, name: 'allowance', params: [account, getGameAddress()] })

  try {
    const [dptBalance, dgtBalance, busdBalance, dptAllowance, dgtAllowance, busdAllowance] = await multicall(
      erc20ABI,
      calls,
      {
        requireSuccess: false,
      },
    )

    return {
      dptBalance: dptBalance.toString(),
      dgtBalance: dgtBalance.toString(),
      busdBalance: busdBalance.toString(),
      dptAllowance: dptAllowance.toString(),
      dgtAllowance: dgtAllowance.toString(),
      busdAllowance: busdAllowance.toString(),
    }
  } catch (error) {
    console.log('error fetchGameUserTokenInfo', error)
    return {
      dptBalance: '0',
      dgtBalance: '0',
      busdBalance: '0',
      dptAllowance: '0',
      dgtAllowance: '0',
      busdAllowance: '0',
    }
  }
}

export const fetchGameUserInfo = async (account: string) => {
  try {
    const gameContract = getGameContract()

    const user = await gameContract.users(account)
    return {
      currentRound: user.currentRound.toString(),
      dptReward: user.dptReward.toString(),
      dgtReward: user.dptReward.toString(),
      lastTimeClaim: (parseInt(user.lastTimeClaim.toString()) * 1000).toString(),
    }
  } catch (error) {
    console.log('error fetchGameUserRewards', error)
    return {
      currentRound: '0',
      dptReward: '0',
      dgtReward: '0',
      lastTimeClaim: '0',
    }
  }
}

export const fetchUserCharacter = async (account: string): Promise<Character[]> => {
  const characterContract = getCharacterContract()
  const tokenIds = await characterContract?.tokensOfOwner(account)

  const calls = []
  tokenIds.forEach((tokenId) => {
    calls.push({ address: getCharacterAddress(), name: 'getHero', params: [tokenId.toString()] })
  })

  try {
    const results = await multicall(characterAbi, calls, { requireSuccess: false })

    return results
      .map((result) => {
        return {
          heroRating: result[0].heroRating.toString(),
          heroRarity: result[0].heroRarity.toString(),
          heroName: result[0].heroName.toString(),
          attack: result[0].attack.toString(),
          defense: result[0].defense.toString(),
          lucky: result[0].lucky.toString(),
          energy: result[0].energy.toString(),
          tokenId: result[0].tokenId.toString(),
          level: result[0].level.toString(),
          lastFightTime: (parseInt(result[0].lastFightTime.toString()) * 1000).toString(),
          ...gameCharacter[result[0].heroRarity.toNumber()][result[0].heroName.toNumber()],
        }
      })
      .sort((a, b) => b.heroRarity - a.heroRarity)
  } catch (error) {
    console.log('error fetchUserCharacter', error)
    return []
  }
}

export const fetchCharacter = async (heroId: string): Promise<Character> => {
  const characterContract = getCharacterContract()
  try {
    const character = await characterContract.getHero(heroId)
    return {
      heroRating: character.heroRating.toString(),
      heroRarity: character.heroRarity.toString(),
      heroName: character.heroName.toString(),
      attack: character.attack.toString(),
      defense: character.defense.toString(),
      energy: character.energy.toString(),
      tokenId: character.tokenId.toString(),
      level: character.level.toString(),
      lastFightTime: (parseInt(character.lastFightTime.toString()) * 1000).toString(),
      ...gameCharacter[character.heroRarity.toNumber()][character.heroName.toNumber()],
    }
  } catch (error) {
    console.log('error fetch character')
    return null
  }
}

export const fetchUserEquipment = async (account: string) => {
  // const enemyContract = getEquipmentContract()

  // console.log('fetch equipment')
  return []
}
