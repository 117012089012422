import {civiliansSpec} from 'config/constants/civilians'
import React from 'react'
import styled from 'styled-components'

function CivilianItem({civilian, percentEnergy}) {
  if (Number.isNaN(percentEnergy)) {
    console.log('dang load energy')
  }

  return (
    <CivilianItemStyled>
      <div className="civilian__styled__top">
        <div className="civilian__id">
          <span className="civilian__id">#{civilian.tokenId}</span>
        </div>
        <div className="civilian__rating">
          <span>Rating: 1</span>
        </div>
      </div>

      <img className="civilian__img" src={civilian.img} alt="civilian" />
      <h3 className="civilian__job">{civilian.name}</h3>
      <CivilianEnergyStyled>
        <div className="civilian__energy" style={{width: `${percentEnergy}%`}}></div>
        <span>{percentEnergy}%</span>
      </CivilianEnergyStyled>

      <div className="civilian__star">
        {[...Array(parseInt(civilian.heroRarity) + 1)].map(() => (
          <img src="./images/games/star.svg" alt="star" />
        ))}
      </div>
    </CivilianItemStyled>
  )
}

const CivilianItemStyled = styled.div`
  text-align: center;
  position: relative;

  .civilian__styled__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    .civilian__id {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      padding: 6px 10px;
      height: 35px;
      border-radius: 6px;
      background: #8797b7;
    }
  }

  img {
    width: 250px;
    height: 250px;
    margin: 0 auto;
  }
  .civilian__job {
    color: #55b3f8;
    font-size: 22px;
    font-weight: 700;
    padding: 10px 0px;
    text-transform: uppercase;
  }

  .civilian__star {
    position: absolute;
    top: 70px;
    right: 0px;
    display: grid;

    img {
      margin-bottom: 3px;
      width: 28px;
      height: 28px;
    }
  }
`

const CivilianEnergyStyled = styled.div`
  position: relative;
  margin: 0 auto;
  text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000;
  padding: 8px 0px 16px 0px;
  &::before {
    content: '';
    background: #fff;
    width: 100%;
    height: 18px;
    position: absolute;
    left: 0px;
    border-radius: 100px;
  }

  span {
    position: absolute;
    z-index: 10;
    top: 65%;
    left: 50%;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .civilian__energy {
    width: 50%;
    position: absolute;
    left: 0px;
    z-index: 100;
    height: 18px;
    background: url('./images/staking-game/bg-energy.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100px;

    &::after {
      content: '';
      background: url('./images/staking-game/energy-after.svg');
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      left: 0px;
      width: 24px;
      z-index: 100;
      height: 35px;
      top: -9px;
      transform: translate(-50%, 0%);
    }
  }
`
export default CivilianItem
