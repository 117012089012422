import { useActiveWeb3React } from 'hooks'

import BeanButton from 'components2/Common/BeanButton'
import { useAppDispatch } from 'state'
import SignModalWithNotch from 'components2/Modal/SignModal'
import { ethers } from 'ethers'
import { getUser } from 'api/actions/user'
import { loginAction } from 'state/auth/actions'
import { useState } from 'react'

const SignModal = ({ onClose }) => {
  const { library, account } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const signer = library.getSigner()
  const [isLoading, setIsLoading] = useState(false)
  const handleSign = async () => {
    setIsLoading(true)
    const user = await getUser(account)
    const nonce = user?.nonce || 0
    if (account) {
      const messageHash = ethers.utils.solidityKeccak256(['address', 'uint256'], [account, nonce])
      try {
        const signature = await signer.signMessage(ethers.utils.arrayify(messageHash))
        dispatch(loginAction({ account, signature, callback: onClose }))
      } catch (error) {
        console.log('error', error)
      }
    }
    setIsLoading(false)
  }
  return (
    <SignModalWithNotch styleInside={{ padding: '0 10px', margin: '15px' }}>
      <span>Please sign with your wallet</span>
      <button onClick={handleSign} type="button" className="ant-btn ant-btn-default" disabled={isLoading}>
        <div className="button__before"></div>
        <div className="button__after"></div>
        <span>Sign</span>
      </button>
    </SignModalWithNotch>
  )
}

export default SignModal
