import BeanButton from 'components2/Common/BeanButton'
import React from 'react'
import styled from 'styled-components'
import ButtonGame from 'views2/Games/ButtonGame'
import CivilianItem from 'views2/Games/components/CivilianItem'
import BaseModal from '../BaseModal'
import ReviewSkinItem from './components/ReviewSkinItem'

function ReviewSkin({onClose, lv, img, star, rating}) {
  return (
    <BaseModal
      header={<BeanButton className="fixed-on-top">Review Skin</BeanButton>}
      onClose={onClose}
      styleInside={{padding: '0 10px', margin: '15px'}}
      style={{height: 688}}
    >
      <ReviewSkinStyled>
        <div className="reviewSkin__style">
          <ReviewSkinItem lv={lv} img={img} star={star} rating={rating} power="240" lucky="80" hp="14" />
          <img src="./images/games/arrow.svg" alt="arrow.svg" />
          <ReviewSkinItem lv={lv} img={img} star={star} rating={rating >= 5 ? rating : rating + 1} />
        </div>
        <ButtonGame name="FUSION" />
      </ReviewSkinStyled>
    </BaseModal>
  )
}

const ReviewSkinStyled = styled.div`
  .reviewSkin__style {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    .civilianItem__style {
      margin-bottom: 24px;
      margin-top: 30px;
    }
  }
`
export default ReviewSkin
