import { useActiveWeb3React } from 'hooks'
import React, { useEffect, useState } from 'react'
import { typesGame } from 'api/actions/game'
import { useAppDispatch } from 'state'
import { fetchUserCivilians, fetchUserTokenData } from 'state/gameDb/actions'
import styled from 'styled-components'
import { getDgtAddress, getDptAddress, getRewardGameAddress } from 'utils/addressHelpers'
import { getRewardGameContract } from 'utils/contractHelpers'
import Back from 'views2/Games/components/Back'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import { getRewardsPoolSelector, getUserGameDataSelector } from 'state/gameDb/reducer'
import ReViewIcons from 'views2/Games/components/ReViewIcons'
import HeaderToken from 'views2/HomepageGame/Components/HeaderToken'
import JobTitle from './Conponents/JobTitle'
import ReviewGame from './Conponents/ReviewGame'
import Reward from './Conponents/Reward'

interface IRewardsPool {
  currentRewards: string
  limitRewards: string
}
function PlayNow(props) {
  const [isShowReview, setShowReview] = useState(true)
  const [api, setApi] = useState({
    1: { minReward: null, maxReward: null, priceToFight: null },
    2: { minReward: null, maxReward: null, priceToFight: null },
    3: { minReward: null, maxReward: null, priceToFight: null },
  })
  useEffect(() => {
    ;(async () => {
      try {
        const apiReward = await typesGame()
        setApi(apiReward)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  // const [rewardsPool, setRewardsPool] = useState<IRewardsPool>()
  const rewardsPool = useSelector(getRewardsPoolSelector)
  const JobTitleList = [
    {
      name: 'Flip Card',
      img: 'flip-card.png',
      subUrl: '/flip-cards',
    },
    {
      name: 'Lucky Wheel',
      img: 'wheel.png',
      subUrl: '/wheel',
    },
    {
      name: 'Lucky Spin',
      img: 'spin.png',
      subUrl: '/spinner',
    },
  ]
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserCivilians(account))
    }
  }, [account])

  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       const rewardContract = getRewardGameContract()
  //       const response = await rewardContract.getCurrentRewardPool(getDgtAddress())
  //       const rewardsUsed = ethers.utils.formatEther(response?.[0]) || '0'
  //       const limitRewards = ethers.utils.formatEther(response?.[1]) || '0'

  //       setRewardsPool({
  //         currentRewards: (+limitRewards - +rewardsUsed).toString(),
  //         limitRewards,
  //       })
  //     } catch (error) {
  //       console.log('error', error)
  //     }
  //   })()
  // }, [])

  return (
    <PlayNowStyled>
      <Back />
      <ReviewGame Styled={isShowReview ? 'none' : 'block'} onClick={() => setShowReview(!isShowReview)} />
      <div className="header__token__styled">
        <HeaderToken />
      </div>
      <ReViewIcons onClick={() => setShowReview(!isShowReview)} />
      <div className="bg__play-now"></div>
      <div className="play__now__style">
        <Reward {...rewardsPool} />
        <div className="play__now__content">
          {JobTitleList.map((arr, index) => (
            <JobTitle
              priceToFight={api?.[index + 1]?.priceToFight}
              minReward={api?.[index + 1]?.minReward}
              maxReward={api?.[index + 1]?.maxReward}
              level={index}
              name={arr.name}
              img={arr.img}
              key={index}
              subUrl={arr.subUrl}
            />
          ))}
        </div>
      </div>

      {/* <FlipCards /> */}
    </PlayNowStyled>
  )
}

const PlayNowStyled = styled.div`
  height: 1080px;
  width: 1920px;
  position: relative;
  .bg__play-now {
    background: url('./images/staking-game/bg-staking.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .play__now__style {
    position: absolute;
    top: 140px;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, 0%);
    /* 
    background: #3264af7a; */
    .play__now__content {
      height: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .header__token__styled {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0%);
    .widget__token-item {
      background: #0000006c;
    }
  }
`
export default PlayNow
