import { createSlice } from '@reduxjs/toolkit'
import { isFulfilledAction, isPendingAction, isRejectedAction } from '../../utils/helpers'
import { AUTH_SLICE_NAME } from './constants'

export interface IToken {
  token: string
  expires: string
}

export interface IAuthSliceState {
  isLoading: boolean
  rebound: boolean
  access: IToken
  refresh: IToken
}

const initialState = {
  isLoading: false,
  rebound: true,
  access: {
    token: '',
    expires: '',
  },
  refresh: {
    token: '',
    expires: '',
  },
} as IAuthSliceState

export const authSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      Object.keys(action?.payload || {}).forEach((key) => {
        state[key as keyof IAuthSliceState] = action.payload[key]
      })
    },
    logoutAction: (state) => {
      state.access = { token: '', expires: '' }
      state.refresh = { token: '', expires: '' }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilledAction(`${AUTH_SLICE_NAME}/`), (state, action) => {
        state.isLoading = false
        Object.keys(action?.payload || {}).forEach((key) => {
          state[key as keyof IAuthSliceState] = action.payload[key]
        })
      })
      .addMatcher(isPendingAction(`${AUTH_SLICE_NAME}/`), (state) => {
        state.isLoading = true
      })
      .addMatcher(isRejectedAction(`${AUTH_SLICE_NAME}/`), (state) => {
        state.isLoading = false
      })
  },
})

// Selectors

export const getAuthSelector = (state: any) => state.auth

export const { setAuthState, logoutAction } = authSlice.actions
export default authSlice.reducer
