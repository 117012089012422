import styled from 'styled-components'

const ButtonClaim = ({disabled, rewardValue, ...otherProps}) => {
  return (
    <ButtonClaimStyled disabled={disabled}>
      <div className="button-display-area">
        <div className="row-reward">
          <img src="./images/fileDiviner/frameprice.png" alt="frameprice.png" />
          {rewardValue}
        </div>
        <div>Claim</div>
      </div>
    </ButtonClaimStyled>
  )
}

export default ButtonClaim

const ButtonClaimStyled = styled.button`
  background: linear-gradient(180deg, #98cdff 0%, #479ff2 100%);
  border: 2px solid #1f4f97;
  border-radius: 12px;
  padding: 5px;
  position: relative;
  color: white;
  font-family: Chalkboard SE;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  width: 110px;
  cursor: pointer;

  text-align: center;

  .row-reward {
    img {
      width: 24px;
    }
  }

  .coin-icon {
    vertical-align: sub;
  }

  :disabled {
    filter: grayscale(100%);
  }

  ::before {
    position: absolute;
    content: '';
    top: 0;
    left: 3px;
    right: 3px;
    bottom: 3px;

    background: #5aafff;
    mix-blend-mode: normal;
    border-radius: 12px;
  }

  ::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 6px;
    right: 6px;
    height: 26px;

    background: #8bc6fd;
    border-radius: 12px;
  }

  .button-display-area {
    position: relative;
    z-index: 2;
  }
`
