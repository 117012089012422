import React from 'react'
import styled from 'styled-components'
import BeanButton from 'components2/Common/BeanButton'
import BaseModal from '../BaseModal'

function ModalGamePlayNow({ onClose, userReward, bonus = 0 }) {
  return (
    <BaseModal
      bgSVG={userReward === 0 ? '#EAEAEA' : '#fff'}
      header={
        <BeanButton className="fixed-on-top" backgroundColor={userReward === 0 ? '#D23C3C' : '#3C7FD2'}>
          {userReward > 0 ? 'WIN' : 'LOSE'}
        </BeanButton>
      }
      onClose={onClose}
      styleInside={
        userReward > 0
          ? {
              top: '0%',
              background: 'url(./images/play-now/bg-modal-game-win.png)no-repeat',
              backgroundSize: '100% 100%',
            }
          : {
              top: '0%',
              background: 'url(./images/play-now/bg-modal-game-lose.png)no-repeat',
              backgroundSize: '100% 100%',
            }
      }
      style={{ height: 660, width: 856 }}
      background={
        userReward === 0 ? 'linear-gradient(to left,#FF9254, #F01111 )' : 'linear-gradient(to left,#78BCFC, #4F97DA )'
      }
    >
      <ModalGamePlayNowStyled>
        <img className="token__icon" src="./images/play-now/token-dgt-new.png" alt="token" />
        <span>{userReward === 0 ? '0' : userReward}</span>
      </ModalGamePlayNowStyled>
      <br />
      <BonusStyled>
        <img className="token__icon" src="./images/play-now/token-dgt-new.png" alt="token" />
        <span>Bonus : {userReward === 0 ? '0' : '+' + bonus}</span>
      </BonusStyled>
    </BaseModal>
  )
}

const ModalGamePlayNowStyled = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 48px;
  font-weight: 700;
  span {
    margin-left: 10px;
  }
  .token__icon {
    width: 64px;
  }
`
const BonusStyled = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 18px;
  font-weight: 700;
  span {
    margin-left: 10px;
  }
  .token__icon {
    width: 20px;
  }
`
export default ModalGamePlayNow
