import React, {useRef} from 'react'
import {Tooltip} from 'antd'
import styled from 'styled-components'
import CivilianItem from './CivilianItem'

function CivilianItemHover({lv, img, star, rating, className, name, onClick}) {
  return (
    <Tooltip
      overlayClassName="civilian__tooltip__hover"
      title={
        <div className="civilian__tooltip__hover__content">
          <CivilianItem lv={lv} img={img} star={star} rating={rating} className={className} onClick={onClick} />
          <ParameterItem>
            <h3 className="parameter__items-name">{name}</h3>
            <div className="parameter__items">
              <img src="./images/games/power.svg" alt="power.svg" />
              <span>240</span>
            </div>
            <div className="parameter__items">
              <img src="./images/games/lucky.svg" alt="lucky.svg" />
              <span>80</span>
            </div>
            <div className="parameter__items">
              <img src="./images/games/hp.svg" alt="hp.svg" />
              <span>14</span>
            </div>
          </ParameterItem>
        </div>
      }
    >
      <CivilianItemHoverStyled>
        <CivilianItem lv={lv} img={img} star={star} rating={rating} className={className} onClick={onClick} />
      </CivilianItemHoverStyled>
    </Tooltip>
  )
}

const CivilianItemHoverStyled = styled.div`
  transition: ease all 0.5s;
`

const ParameterItem = styled.div`
  padding-left: 24px;
  height: 150px;
  .parameter__items-name {
    font-size: 22px;
    font-weight: 400;
    color: #fff;
    margin: 0;
    text-transform: capitalize;
    text-align: center;
  }
  .parameter__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 55px;
    padding-top: 10px;
  }
`
export default CivilianItemHover
