import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useAppDispatch } from 'state'
import { useActiveRoundIdSelectorV1 } from 'state/predictionV1/selector'
import { useActiveRoundIdSelectorV2 } from 'state/predictionV2/selector'
import { usePredictionContractsV1, usePredictionContractsV2 } from './useContract'

export const usePredictionDeposit = (pid: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const roundId = useActiveRoundIdSelectorV1()
  const predictionContract = usePredictionContractsV1()

  const handleDeposit = useCallback(
    async (longAmount: string, shortAmount: string) => {
      const longAmountBigNumber = new BigNumber(longAmount).times(new BigNumber(10).pow(18)).toString()
      const shortAmountBigNumber = new BigNumber(shortAmount).times(new BigNumber(10).pow(18)).toString()
      const txHash = await predictionContract[roundId].deposit(pid, longAmountBigNumber, shortAmountBigNumber)
      return txHash
    },
    [account, dispatch, predictionContract, pid],
  )

  return { onDeposit: handleDeposit }
}

export const usePredictionDepositV2 = (pid: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const roundId = useActiveRoundIdSelectorV2()
  const predictionContract = usePredictionContractsV2()

  const handleDeposit = useCallback(
    async (longAmount: string, shortAmount: string) => {
      const longAmountBigNumber = new BigNumber(longAmount).times(new BigNumber(10).pow(18)).toString()
      const shortAmountBigNumber = new BigNumber(shortAmount).times(new BigNumber(10).pow(18)).toString()
      const txHash = await predictionContract[roundId].deposit(pid, longAmountBigNumber, shortAmountBigNumber)
      return txHash
    },
    [account, dispatch, predictionContract, pid],
  )

  return { onDeposit: handleDeposit }
}

export const usePredictionWithdrawV1 = (pid: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const predictionContract = usePredictionContractsV1()
  const roundId = useActiveRoundIdSelectorV1()

  const handleWithdraw = useCallback(
    async (longAmount: string, shortAmount: string) => {
      const longAmountBigNumber = new BigNumber(longAmount).times(new BigNumber(10).pow(18)).toString()
      const shortAmountBigNumber = new BigNumber(shortAmount).times(new BigNumber(10).pow(18)).toString()
      const txHash = await predictionContract[roundId].withdraw(pid, longAmountBigNumber, shortAmountBigNumber)
      return txHash
    },
    [account, dispatch, predictionContract, pid],
  )

  return { onWithdraw: handleWithdraw }
}

export const usePredictionWithdrawV2 = (pid: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const predictionContract = usePredictionContractsV2()
  const roundId = useActiveRoundIdSelectorV2()

  const handleWithdraw = useCallback(
    async (longAmount: string, shortAmount: string) => {
      const longAmountBigNumber = new BigNumber(longAmount).times(new BigNumber(10).pow(18)).toString()
      const shortAmountBigNumber = new BigNumber(shortAmount).times(new BigNumber(10).pow(18)).toString()
      const txHash = await predictionContract[roundId].withdraw(pid, longAmountBigNumber, shortAmountBigNumber)
      return txHash
    },
    [account, dispatch, predictionContract, pid],
  )

  return { onWithdraw: handleWithdraw }
}
