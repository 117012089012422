import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'

function ReviewGame({ onClick, Styled }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const imgReviewList = [
    {
      img: '/images/play-now/review/spin.png',
    },
    {
      img: '/images/play-now/review/reward.png',
    },
    {
      img: '/images/play-now/review/time-reward.png',
    },
    {
      img: '/images/play-now/review/click.png',
    },
    {
      img: '/images/play-now/review/win-rate.png',
    },
    {
      img: '/images/play-now/review/civilian.png',
    },
  ]

  return (
    <ReviewGameStyled style={{ display: Styled }}>
      <Slider {...settings}>
        {imgReviewList.map((img, index) => (
          <div className="img__review" key={index}>
            <img src={img.img} alt="img" />
          </div>
        ))}
      </Slider>
      <div className="skip__review" onClick={onClick}>
        <img src="./images/play-now/skip.svg" alt="skip" />
      </div>
    </ReviewGameStyled>
  )
}

const ReviewGameStyled = styled.div`
  position: absolute;
  animation: ease opacity 0.5s;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .slick-slider.slick-initialized {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    .slick-prev {
      right: 11%;
      left: unset;
      background: url('./images/play-now/back.svg');
      &:before {
        content: 'Back';
      }
    }
    .slick-arrow {
      top: 10%;
      background-repeat: no-repeat;
      background-size: contain;
      max-width: 118px;
      max-height: 82px;
      width: 100%;
      height: 100%;
      z-index: 200;
      transition: ease all 0.5s;
      &:hover {
        filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
          drop-shadow(2px -2px 2px #ffffff);
      }
      &::before {
        content: unset;
      }
    }
    .slick-next {
      background: url('./images/play-now/next.svg');
      right: 3%;
    }
    .slick-disabled {
      filter: grayscale(1);
      &:hover {
        filter: grayscale(1);
        cursor: no-drop;
      }
    }
    .slick-list {
      z-index: -1;
    }
  }
  .skip__review {
    position: absolute;
    left: 40px;
    bottom: 70px;
    width: 120px;
    height: 82px;
    transition: ease all 0.5s;
    cursor: pointer;
    &:hover {
      filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
        drop-shadow(2px -2px 2px #ffffff);
    }
  }
`
export default ReviewGame
