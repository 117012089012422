import styled from 'styled-components'
BackgroundPrediction.propTypes = {}

function BackgroundPrediction() {
  return <BackgroundPredictionStyled></BackgroundPredictionStyled>
}

export const BackgroundPredictionStyled = styled.div`
  width: 600px;
  margin: 0 auto;
  margin-top: -15px;
  position: relative;
  border-radius: 40px;
  border: solid 16px #79befe;
  background: #fff;
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  /* z-index: 3; */

  .slick-prediction {
    width: 60px;
    position: absolute;
    top: -8%;
    cursor: pointer;
    transition: ease all 0.5s;
    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 100%;
    }
  }
  .slick-prediction.Right {
    right: 38%;
  }
  .slick-prediction.Left {
    left: 38%;
  }
`
export default BackgroundPrediction
