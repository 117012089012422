import {useWeb3React} from '@web3-react/core'
import ConnectWalletModal from 'components2/Modal/ConnectWalletModal'
import GlobalModal from 'components2/static/GlobalModal'
import React from 'react'
import {useSelector} from 'react-redux'
import {State} from 'state/types'
import {useApproveForAll, useStakeNft} from './useStakingNft'
import {getStakingNftAddress} from '../utils/addressHelpers'

const useStakingNftActionButton = ({isWorking = false, poolRating, poolRarity, tokenId}) => {
  const {account} = useWeb3React()
  const isApprovedForAll = useSelector((state: State) => state.stakingNft.isApprovalForAll)
  const isFetchingApprovedForAll = useSelector((state: State) => state.stakingNft.fetching.approval)

  // const isApprovingNft = useHasPendingTransaction(
  //   getCallData.setApprovalForAll({
  //     addressNeedApprove: getNFTsTransferProxyAddress(),
  //     nftAddress: getCharacterAddress(),
  //   }),
  // )
  // const isCancelling = useHasPendingTransaction(getCallData.cancelListing({tokenId, nftAddress}))
  // const isAddListing = useHasPendingTransaction(getCallData.addListing({tokenId, nftAddress}))
  const onApproveNFT = useApproveForAll()
  const onStakeNFT = useStakeNft({poolRating, poolRarity, tokenId, account})

  let titleButton = null
  let action = null
  // let isCancel = false
  let isLoading = false

  if (!account) {
    titleButton = 'Connect Wallet'
    action = () => {
      GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
    }
  } else if (isFetchingApprovedForAll) {
    titleButton = 'Loading...'
    isLoading = true
  } else if (!isApprovedForAll) {
    titleButton = 'Approve NFT'
    action = () => onApproveNFT([getStakingNftAddress(), true])
  } else if (!isWorking) {
    titleButton = 'Stake'
    action = onStakeNFT
  }

  return {titleButton, action, isLoading}
}

export default useStakingNftActionButton
