import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'

import API from 'api'
import config from 'api/config'
import Back from 'views2/Games/components/Back'
import GlobalModal from 'components2/static/GlobalModal'
import PopupNoitice from '../../../../components2/Modal/ModalGamePlayNow'
import { SpinnerMachineStyle } from './SpinnerMachineStyle'

const SpinnerMachine = () => {
  const [spin, setSpin] = useState(false)
  const [chance1, setChance1] = useState<number | undefined>()
  const [chance2, setChance2] = useState<number | undefined>()
  const [chance3, setChance3] = useState<number | undefined>()
  const { push } = useHistory()
  const { state }: any = useLocation()
  const renderRow = (chance) => {
    if (spin && chance === undefined) {
      return (
        <>
          <div className="ringMoving">
            <img alt="chest" src="/images/game/chest.png" />
          </div>
          <div className="ringMoving">
            <img alt="chest" src="/images/game/dpt.png" />
          </div>
          <div className="ringMoving">
            <img alt="chest" src="/images/game/dgt.png" />
          </div>
        </>
      )
    }
    if (chance === 1) {
      return (
        <>
          <div className="ringEnd">
            <img alt="chest" src="/images/game/dpt.png" />
          </div>
          <div className="ringEnd">
            <img alt="chest" src="/images/game/chest.png" />
          </div>
          <div className="ringEnd">
            <img alt="chest" src="/images/game/dgt.png" />
          </div>
        </>
      )
    }
    return (
      <>
        <div className="ringEnd">
          <img alt="chest" src="/images/game/chest.png" />
        </div>
        <div className="ringEnd">
          <img alt="chest" src="/images/game/dgt.png" />
        </div>
        <div className="ringEnd">
          <img alt="chest" src="/images/game/dpt.png" />
        </div>
      </>
    )
  }

  const handCloseModal = () => {
    GlobalModal.hide()
    push('/play-now')
  }

  useEffect(() => {
    if (!state?.tokenId) {
      push('/play-now')
    }
  }, [state])
  const rand = async () => {
    const result = await API({
      method: 'post',
      url: config.API.GAME_SERVICE,
      hasToken: true,
      data: {
        type: 3,
        tokenId: state?.tokenId,
      },
    })

    const { userReward, bonus } = result
    const isWin = userReward > 0

    console.log(userReward, bonus)
    let rand1 = Math.round(Math.random())
    let rand2 = Math.round(Math.random())
    let rand3 = Math.round(Math.random())

    if (!isWin && rand1 === rand2 && rand2 === rand3) {
      while (rand1 === rand2 && rand2 === rand3) {
        rand1 = Math.round(Math.random())
        rand2 = Math.round(Math.random())
        rand3 = Math.round(Math.random())
      }
    }

    if (isWin && !(rand1 === rand2 && rand2 === rand3)) {
      while (!(rand1 === rand2 && rand2 === rand3)) {
        rand1 = Math.round(Math.random())
        rand2 = Math.round(Math.random())
        rand3 = Math.round(Math.random())
      }
    }

    // console.log(rand1, rand2, rand3)

    setChance1(rand1)
    setTimeout(() => {
      setChance2(rand2)
    }, 1000)
    setTimeout(() => {
      setChance3(rand3)
    }, 2000)
    setTimeout(() => {
      setSpin(false)
      if (isWin) {
        GlobalModal.show(<PopupNoitice bonus={bonus} userReward={userReward} onClose={handCloseModal} />, {
          onPressBackground: null,
        })
      } else {
        GlobalModal.show(<PopupNoitice bonus={bonus} userReward={userReward} onClose={handCloseModal} />, {
          onPressBackground: null,
        })
      }
    }, 3000)
  }

  const play = () => {
    if (!spin) {
      setSpin(true)
      setChance1(undefined)
      setChance2(undefined)
      setChance3(undefined)
      setTimeout(() => {
        rand()
      }, 2000)
    }
  }

  return (
    <SpinnerMachineStyle className={`${chance3 !== undefined ? 'spined' : ''}`}>
      <Back to="/play-now" />
      <div className="traffic-light">
        <img src="images/yellow.svg" alt="yellow" />
        <img src="images/green.svg" alt="green" />
        <img src="images/red.svg" alt="red" />
      </div>

      <div className="bg__spinner">
        <img src="images/machine.png" alt="bg game 3" />
      </div>

      <div className="spinnerMachine">
        <div className="slot">
          <div className="row">{renderRow(chance1)}</div>
          <div className="row">{renderRow(chance2)}</div>
          <div className="row">{renderRow(chance3)}</div>
        </div>
        <div className="slotFoot">
          <div className="noitice-green spinButton"></div>
          <button type="button" className="spinButton" onClick={play}>
            Push
          </button>
          <div className="noitice-red spinButton"></div>
        </div>
      </div>
    </SpinnerMachineStyle>
  )
}

export default SpinnerMachine
