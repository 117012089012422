export default {
  mulltiCall: {
    4: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },

  prediction: {
    56: [
      '0x562b441959Aa00b12d00a1725992C253FB705f05',
      '0x44882ed1C425083424Ed49D26b8D504d6BE1C8Db',
      '0x06456Cd5497E801c40c41856c824a67374a31EE7',
    ],
    97: [
      '0x38A40C0473876CACE7B62edC0C0edeF826edc5b2',
      '0x38A40C0473876CACE7B62edC0C0edeF826edc5b2',
      '0x38A40C0473876CACE7B62edC0C0edeF826edc5b2',
    ],
  },

  predictionV2: {
    56: [
      '0x86D64a062F2BeBaAfcE1635703E3Dc82a0231672',
      '0x86D64a062F2BeBaAfcE1635703E3Dc82a0231672',
      '0x86D64a062F2BeBaAfcE1635703E3Dc82a0231672',
    ],
    97: [
      '0x38A40C0473876CACE7B62edC0C0edeF826edc5b2',
      '0x38A40C0473876CACE7B62edC0C0edeF826edc5b2',
      '0x38A40C0473876CACE7B62edC0C0edeF826edc5b2',
    ],
  },

  game: {
    56: '0x0506871B68E5823693E115a5e2175b9A2c4CFB42',
    97: '0xFe70F3F60894E36BcFD3dc1521aFaFb7A849E0F6',
  },
  character: {
    4: '0xCE72d5C26589c28a4D8B85FFA53D2B3B83b9a4aC',
    56: '0xcaE918c73E5D5c888AFf942B0B63C0CF4c15dC64',
    97: '0x6263712004aDe2f2C5F7B66Ca7C34bc8430606d4',
  },
  equipment: {
    56: '0xAdD5a0B24E7F987e8FAB863e86BaB1F583c6DFB6',
    97: '0x7EDD7F5aAe70E8F7795E5101F6f672A9Fb1e3cCB',
  },

  priceOracle: {
    56: '0xFBFb4C4E10EBD70DcE28B8b00FdAD6c8e5De38dc',
    97: '0x5aA7C4a25c9E3db2bCAe6E08dC2Bfe8aCed73730',
  },

  gameWiki: {
    4: '0x8f26E89A038E4E4469987cAf1E0a944AAdA6c59C',
    56: '0x7d88930d354BcB4cBB2F28eD4561b267baf147fA',
    97: '0xDf4f2E52078135365f33eF5cB541A9e7c0aa1B35',
  },

  faucet: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0xe72A4D14d49568dEE390aCebFf18bD498eDd127D',
  },

  market: {
    56: '',
    97: '0x5E47423be71321c65Cd181EFf80dC006FAD6f23e',
  },
  tokenTransferProxy: {
    56: '',
    97: '0x69949fAaB05611bAFC2C821CE4d4A3029013472c',
  },
  nftsTransferProxy: {
    56: '',
    97: '0x47477eAD132967617C0D3439203b9F6c4918e37c',
  },

  fakeCharacter: {
    56: '',
    97: '0x26656936D36148320a0d83cE75958B0aFE0d9cD2',
  },

  buyChest: {
    56: '0x2Af4A1d58E12de09306AAc5Bc108ce03459BE226',
    97: '0xA7421E3f7A1885813B1C9749Cecae48745992A78',
  },

  binanceMigrateBox: {
    // 56: '0x4Ad812E30d7FC188DFbAE1f3d524F5AA28183901',
    56: '0x022620706D81C1d381bc090F5E0E171B87978c99',
    97: '0x022620706D81C1d381bc090F5E0E171B87978c99',
  },
  stakingNft: {
    4: '0xCA0d182D374184C7a7398BF59fc11be753de267c',
    56: '0xf9de65cb1FAc2b0705390C9d9011449558f4477B',
    97: '0x44CE270Ac8c5E289A7E487d3afe439D632f3744B',
  },
  rewardGame: {
    56: '0xb52Cb2d41D95b6A93A80a2D7Cc3855a729d1dA3A',
    97: '0x860F92903DD3BD84214be7a898589e9Eb43eF5D2',
  },
}
