import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import gameAbi from 'config/abi/chestMarket.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import multicall from 'utils/multicall'
import gameCharacter from 'config/constants/game'
import {getChestMarketAddress, getFakeCharacterAddress, getDptAddress} from 'utils/addressHelpers'
import {getChestMarketContract, getFakeCharacterContract} from 'utils/contractHelpers'
import {Character} from '../types'

export const fetchGameUserTokenInfo = async (account: string) => {
  const calls = []
  const dptToken = getDptAddress()

  calls.push({address: dptToken, name: 'balanceOf', params: [account]})
  calls.push({address: dptToken, name: 'allowance', params: [account, getChestMarketAddress()]})

  try {
    const [dptBalance, dptAllowance] = await multicall(erc20ABI, calls, {
      requireSuccess: false,
    })

    return {
      dptBalance: dptBalance.toString(),
      dptAllowance: dptAllowance.toString(),
    }
  } catch (error) {
    console.log('error fetch Game User Token Info', error)
  }
}

export const fetchAmountChestUser = async (account: string) => {
  try {
    const chestMarketContract = getChestMarketContract()
    const amountChest = await chestMarketContract.users(account)
    return amountChest.toString()
  } catch (error) {
    console.log('error fetch amount chest', error)
    return null
  }
}

export const fetchUserCharacter = async (account: string): Promise<Character[]> => {
  try {
    const fakeCharacterContract = getFakeCharacterContract()
    const tokenIds = await fakeCharacterContract?.tokensOfOwner(account)

    const calls = []
    tokenIds.forEach((tokenId) => {
      calls.push({address: getFakeCharacterAddress(), name: 'getHero', params: [tokenId.toString()]})
    })

    const results = await multicall(fakeCharacterAbi, calls, {requireSuccess: false})

    return results
      .map((result) => {
        return {
          tokenId: result[0].tokenId.toString(),
          heroRarity: result[0].heroRarity.toString(),
          heroName: result[0].heroName.toString(),
          ...gameCharacter[result[0].heroRarity.toNumber()][result[0].heroName.toNumber()],
        }
      })
      .sort((a, b) => b.heroRarity - a.heroRarity)
  } catch (error) {
    console.log('error fetch User Character', error)
    return null
  }
}
