import multicall, {Call} from 'utils/multicall'

export const multicallContract = async (
  contractAddress: string,
  contractAbi: any,
  functionCall: string,
  loopDatas: any,
) => {
  try {
    if (loopDatas.length < 0) return []
    const calls: Call[] = loopDatas.map((data) => {
      const call: Call = {
        address: contractAddress,
        name: functionCall,
        params: [...data],
      }
      return call
    })

    return await multicall([...contractAbi], calls, {requireSuccess: false})
  } catch (e) {
    console.log(e)
  }
}
