export const civiliansSpec = [
  {
    name: 'Magician',
    nameInDecimal: 0,
    img: '/images/market/magician.png',
    thumbImg: '',
    rarity: 0,
  },
  {
    name: 'Officer',
    nameInDecimal: 1,
    img: '/images/market/officer.png',
    thumbImg: '',
    rarity: 0,
  },
  {
    name: 'Police',
    nameInDecimal: 0,
    img: '/images/market/police.png',
    thumbImg: '',
    rarity: 1,
  },
  {
    name: 'Painter',
    nameInDecimal: 1,
    img: '/images/market/painter.png',
    thumbImg: '',
    rarity: 1,
  },
  {
    name: 'Football Player',
    nameInDecimal: 0,
    img: '/images/market/football-player.png',
    thumbImg: '',
    rarity: 2,
  },
  {
    name: 'Singer',
    nameInDecimal: 1,
    img: '/images/market/singer.png',
    thumbImg: '',
    rarity: 2,
  },
  {
    name: 'Fireman',
    nameInDecimal: 0,
    img: '/images/market/fireman.png',
    thumbImg: '',
    rarity: 3,
  },
  {
    name: 'Farmer',
    nameInDecimal: 1,
    img: '/images/market/farmer.png',
    thumbImg: '',
    rarity: 3,
  },
  {
    name: 'Chef',
    inventoryImg: '',
    rarityImg: '',
    reviewImg: '',
    animateImg: '',
    nameInDecimal: 0,
    img: '/images/market/chef.png',
    thumbImg: '',
    rarity: 4,
  },
  {
    name: 'Doctor',
    inventoryImg: '',
    rarityImg: '',
    reviewImg: '',
    animateImg: '',
    nameInDecimal: 1,
    img: '/images/market/doctor.png',
    thumbImg: '',
    rarity: 4,
  },
]
