import priceOracleAbi from 'config/abi/PriceOracle.json'
import {ethers} from 'ethers'
import {getGameWikiAddress} from 'utils/addressHelpers'
import {getGameWikiContract} from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import {upgradeChance} from 'config/constants/game'
import {GamePrice} from '../types'

const fetchGamePrice = async (): Promise<GamePrice> => {
  const gameWikiContract = getGameWikiContract()
  const calls = []
  try {
    calls.push({address: getGameWikiAddress(), name: 'getFullDgtToFightInRound', params: []})
    calls.push({address: getGameWikiAddress(), name: 'getDptUnlockLevel', params: []})
    calls.push({address: getGameWikiAddress(), name: 'getDgtUnlockLevel', params: []})
    calls.push({address: getGameWikiAddress(), name: 'dgtToRegain1Energy', params: []})
    calls.push({address: getGameWikiAddress(), name: 'dptToOpenMysteryBox', params: []})

    const [rawFightPrice, rawDptUnlockPrice, rawDgtUnlockPrice, rawDgtToRegain1Energy, rawDptToOpenMysteryBox] =
      await multicall(priceOracleAbi, calls, {
        requireSuccess: false,
      })

    const [dptToCreateHero, usdToCreateHero, rawFailureUnlock] = await Promise.all([
      gameWikiContract.dptToCreateHero(),
      gameWikiContract.usdToCreateHero(),
      gameWikiContract.getFailureUnlockLevel(),
    ])

    return {
      createNewHeroPrice: {
        dpt: dptToCreateHero.toString(),
        busd: usdToCreateHero.toString(),
      },
      unlockLevelPrice: rawDptUnlockPrice[0].map((dpt, index) => {
        return {
          dpt: dpt.toString(),
          dgt: rawDgtUnlockPrice[0][index].toString(),
          successChance: (100 - upgradeChance[index]).toString(),
          // successChance: (100 - rawFailureUnlock[index].toNumber()).toString(),
        }
      }),
      fightPrice: rawFightPrice[0].map((p) => p.toString()),
      regain1EnergyPrice: ethers.utils.formatEther(rawDgtToRegain1Energy[0]),
      openMysteryBoxPrice: rawDptToOpenMysteryBox[0].toString(),
    }
  } catch (error) {
    console.log('error', error)
  }
}

export default fetchGamePrice
