import { Collapse, Table, Tabs } from 'antd'
import axios from 'axios'
import BeanButton from 'components2/Common/BeanButton'
import addresses from 'config/constants/contracts'
import { ethers } from 'ethers'
import { useActiveWeb3React } from 'hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import BaseModal from '../BaseModal'

const { Panel } = Collapse
const { TabPane } = Tabs

interface history {
  id: number
  poolId: string
  time: Date
  isShortWinner: boolean
  randomUser: string
}

interface historyTransfer {
  id: number
  poolId: number
  block_timestamp: Date
  longAmount: string
  shortAmount: string
  playType: number
}

const results = [
  {
    title: 'Index',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    render: (value) => {
      return new Date(value).toLocaleDateString('en')
    },
  },

  {
    title: 'Results',
    dataIndex: 'isShortWinner',
    key: 'isShortWinner',
    render: (isShortWinner) => {
      return {
        props: {
          style: { color: isShortWinner ? '#2BC355' : '#FC584F' },
        },
        children: (
          <div>
            <div>{isShortWinner ? 'Win' : 'Lose'}</div>
          </div>
        ),
      }
    },
  },

  {
    title: 'Lottery Reward',
    dataIndex: 'randomUser',
    key: 'randomUser',
    render: (value, history) => {
      return {
        props: {
          style: { color: history.isShortWinner ? '#2BC355' : '#FC584F' },
        },
        children: (
          <div>
            {value.map((user, index) => (
              <p key={index}>{`${user.slice(0, 4)}...${user.slice(38)}`}</p>
            ))}
          </div>
        ),
      }
    },
  },
]

const transfer = [
  {
    title: 'Index',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Time',
    dataIndex: 'block_timestamp',
    key: 'block_timestamp',
    render: (value) => new Date(value).toLocaleDateString('en'),
  },

  {
    title: 'DEPOSIT/WITHDRAW',
    dataIndex: 'playType',
    key: 'playType',
    render: (playType) => {
      const playTypes = {
        0: {
          name: 'deposit',
        },
        1: {
          name: 'withdraw',
        },
      }
      return {
        props: {
          style: { color: playType === 0 ? '#2BC355' : '#FC584F' },
        },
        children: (
          <div className="play-type">
            <div>{playTypes[playType].name}</div>
          </div>
        ),
      }
    },
  },

  {
    title: 'AMOUNT OF MONEY',
    dataIndex: 'longAmount',
    key: 'longAmount',
    render: (longAmount, isTransfers) => {
      return {
        props: {
          style: { color: longAmount || isTransfers.shortAmount ? '#2BC355' : '#FC584F' },
        },
        children: (
          <div>
            {longAmount > 0 && isTransfers.shortAmount > 0 ? (
              <>
                {' '}
                <p style={longAmount && { color: '#2BC355' }}> Long: {ethers.utils.formatEther(longAmount)}</p>
                <p style={longAmount && { color: '#FC584F' }}>
                  Short: {ethers.utils.formatEther(isTransfers.shortAmount)}
                </p>
              </>
            ) : longAmount > 0 ? (
              <span style={longAmount && { color: '#2BC355' }}> Long: {ethers.utils.formatEther(longAmount)}</span>
            ) : isTransfers.shortAmount > 0 ? (
              <span style={longAmount && { color: '#FC584F' }}>
                Short: {ethers.utils.formatEther(isTransfers.shortAmount)}
              </span>
            ) : (
              <span></span>
            )}
          </div>
        ),
      }
    },
  },
  {
    title: 'Token',
    dataIndex: 'poolId',
    key: 'poolId',
    render: (poolId) => {
      const tokenList = {
        0: {
          name: 'cake',
          img: './images/fileDiviner/pancakeswap.png',
        },
        1: {
          name: 'usdt-busd',
          img: './images/home/logo-bnb.png',
        },
        2: {
          name: 'dpt',
          img: './images/fileDiviner/frameprice.png',
        },
        3: {
          name: 'dpt-bnb',
          img: './images/home/logo-bnb.png',
        },
      }
      return {
        children: (
          <div className="token__pool-id">
            <img src={tokenList[poolId].img} alt="" />

            {tokenList[poolId].name}
          </div>
        ),
      }
    },
  },
]

const TransactionHistoryModal = ({ onClose }) => {
  const [isTabs, setIsTabs] = useState(1)
  const [history, setHistory] = useState<history[]>([])
  const [isTransfers, setIsTransfers] = useState<historyTransfer[]>([])
  const { chainId } = useActiveWeb3React()

  const onChange = (key: string) => {
    setIsTabs(parseInt(key))
  }

  useEffect(() => {
    if (isTabs === 1) {
      const fetch = async () => {
        const { data } = await axios.get(
          // `${PREDICTION_API_URL}v1/history/0x562b441959Aa00b12d00a1725992C253FB705f05?page=1&limit=10000`,
          `https://prediction-api.diviner.me/v1/history`,
        )
        setHistory(
          data.results.map((p, index): history => {
            return {
              id: index + 1,
              poolId: addresses.prediction[chainId].indexOf(p.contract),
              // result: p.endPrice > p.initialPrice ? 'Long' : 'Short',
              time: new Date(p.block_timestamp),
              isShortWinner: p.isShortWinner,
              randomUser: p.randomUser,
            }
          }),
        )
      }
      fetch()
    }
    if (isTabs === 2) {
      const fetch = async () => {
        const { data } = await axios.get(
          // `${PREDICTION_API_URL}v1/history/0x562b441959Aa00b12d00a1725992C253FB705f05?page=1&limit=10000`,
          `https://prediction-api.diviner.me/v1/users/?wallet=0xee2950fbf5c41ed99afd7365e8561340ca991c0d&page=1&limit=2`,
        )

        setIsTransfers(
          data.results.map((p, index): historyTransfer => {
            return {
              id: index + 1,
              poolId: parseInt(p.poolId),
              block_timestamp: new Date(p.block_timestamp),
              longAmount: p.longAmount,
              shortAmount: p.shortAmount,
              playType: parseInt(p.playType),
            }
          }),
        )
      }
      fetch()
    }
  }, [isTabs])

  return (
    <HistoryModalStyled>
      <BaseModal
        header={<BeanButton className="fixed-on-top">History</BeanButton>}
        styleInside={{ padding: '0 10px', margin: '15px' }}
        onClose={onClose}
        style={{ width: '1050px' }}
        bgSVG=" unset "
      >
        <Tabs onChange={onChange} defaultActiveKey="1" className={history && 'tab__history'}>
          <TabPane tab="Results" key="1">
            <Table
              pagination={{ defaultPageSize: 6, showSizeChanger: true, pageSizeOptions: ['8', '8'] }}
              dataSource={history}
              columns={results}
            />
          </TabPane>
          <TabPane tab="transfer" key="2">
            <Table
              pagination={{ defaultPageSize: 6, showSizeChanger: true, pageSizeOptions: ['8', '8'] }}
              dataSource={isTransfers}
              columns={transfer}
            />
          </TabPane>
        </Tabs>
      </BaseModal>
    </HistoryModalStyled>
  )
}

const HistoryModalStyled = styled.div`
  .notch-bg {
    background: url('./images/games/bg-history.png') no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
  }
  .token__pool-id {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    img {
      margin-right: 5px;
      width: 24px;
    }
  }
  .play-type {
    text-transform: uppercase;
  }
  .fixed-on-top {
    width: 300px;
    left: 48%;
  }
  .tab__history {
    .ant-table {
      .ant-table-tbody {
        height: 300px;
      }
    }
  }
  .ant-table {
    border-radius: 55px;
    .ant-table-content {
      .ant-table-thead {
        .ant-table-cell {
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;

          padding: 20px;
          &::before {
            background-color: #eaeaea !important;
            /* height: 100% !important; */
          }
          &:nth-child(1) {
            border-top-left-radius: 55px;
          }
          &:last-child {
            border-top-right-radius: 55px;
          }
        }
      }
      .ant-table-placeholder {
        td.ant-table-cell {
          border-bottom: unset;
          text-align: center;
        }
      }
      .ant-table-row.ant-table-row {
        &:last-child {
          td.ant-table-cell {
            border-bottom: unset;
          }
        }

        td.ant-table-cell {
          text-align: center;
          border-bottom: 1px dashed #eaeaea;
          border-right: 1px solid #eaeaea;
          padding: 0px 10px;
          font-weight: 500;
          font-size: 18px;
          &:last-child {
            border-right: unset;
          }
        }
      }
      .ant-table-row {
        &:hover {
          &:last-child {
            .ant-table-cell {
              background: unset !important;
            }
          }
        }
      }
    }
  }

  .ant-tabs.ant-tabs-top {
    overflow: unset !important;
  }
  .ant-tabs-ink-bar {
    background: unset;
  }
  .ant-tabs-nav-wrap {
    overflow: unset !important;
    margin-left: 50px;
  }
  .ant-tabs-nav {
    margin: 0;
    &::before {
      border-bottom: unset;
    }
    .ant-tabs-tab-btn {
      color: #d0d6e3;
      font-size: 22px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      background: url('./images/games/bg-tab-active.png');
      transition: ease all 0.5s;
      .ant-tabs-tab-btn {
        color: #fff;
      }
      &::before {
        opacity: 1;
      }
    }
    .ant-tabs-tab {
      background: url('./images/games/bg-tab.png');
      background-size: 100% 100% !important;
      background-repeat: no-repeat;
      border-radius: 26px 26px 0px 0px;
      width: 200px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: ease all 0.5s;
      margin-left: 10px;
      /* &::after {
        content: '';
        background: url('./images/games/star.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 28px;
        height: 28px;
        opacity: 0.8;
        margin-left: 12px;
      } */
      &:nth-child(1) {
        margin-left: 0px;
      }
    }
  }
  .token__history {
    img {
      margin-right: 5px;
    }
  }
`
export default TransactionHistoryModal
