import { combineReducers, configureStore, createStore } from '@reduxjs/toolkit'
import { load } from 'redux-localstorage-simple'
import { persistStore, persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage' // defaults to localStorage for web
import { useDispatch } from 'react-redux'
import marketplace from 'state/marketplace'
import migrate from 'state/migrate'
import pricesReducer from './prices'
import blockReducer from './block'
import user from './user/reducer'
import prediction from './predictionV1'
import predictionV2 from './predictionV2'
import chestMarket from './chestMarket'
import gamedb from './gameDb/reducer'
import game from './game'
import stakingNft from './stakingNft'

import lists from './lists/reducer'
import application from './application/reducer'
import multicall from './multicall/reducer'
import transactions from './transactions/reducer'
import auth from './auth/reducer'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'auth']
const persistConfig = {
  key: 'root',
  storage,
  whitelist: PERSISTED_KEYS,
}

const reducers = combineReducers({
  transactions,
  multicall,
  game,
  gamedb,
  application,
  lists,
  chestMarket,
  prediction,
  predictionV2,
  user,
  prices: pricesReducer,
  block: blockReducer,
  marketplace,
  migrate,
  stakingNft,
  auth,
})
const persistedReducer = persistReducer(persistConfig, reducers)

type MergedState = {
  user: {
    [key: string]: any
  }
  transactions: {
    [key: string]: any
  }
  auth: {
    [key: string]: any
  }
}
const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
  // middleware: [...(getDefaultMiddleware({ thunk: false }) as any), save({ states: PERSISTED_KEYS })],
  // preloadedState: loadedState,
})
export const persistor = persistStore(store)

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type AppState = ReturnType<typeof store.getState>

export default store
