import { MaxUint256 } from '@ethersproject/constants'
import { InputNumber, Select, message } from 'antd'
import { Option } from 'antd/lib/mentions'
import BeanButton from 'components2/Common/BeanButton'
import BaseModal from 'components2/Modal/BaseModal'
import { ethers } from 'ethers'
import { getSignExchangeToken } from 'api/actions/user'

import { useActiveWeb3React } from 'hooks'
import useCallContract from 'hooks/useCallContract'
import { useRewardGameContract, useTokenContract } from 'hooks/useContract'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getGameSettingSelector,
  getRewardsPoolSelector,
  getUserGameDataSelector,
  getUserTokenDataSelector,
  setGameState,
} from 'state/gameDb/reducer'
import styled from 'styled-components'
import { getDgtAddress, getDptAddress, getRewardGameAddress } from 'utils/addressHelpers'
import { getTimeRemainDraw } from 'utils/time'
import { DEADLINE_SIGNATURE_WITHDRAW } from 'config/constants/game'
import { useAppDispatch } from 'state'
import { fetchUserTokenData } from 'state/gameDb/actions'
import CustomButton from './CustomButton'

const EIP712Domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
]

const domain = {
  name: 'Diviner Game',
  version: '1',
  chainId: +process.env.REACT_APP_CHAIN_ID,
  verifyingContract: getRewardGameAddress(),
}

const Withdraw = [
  {
    name: 'token',
    type: 'address',
  },
  { name: 'account', type: 'address' },
  { name: 'amount', type: 'uint256' },
  {
    name: 'feePercent',
    type: 'uint256',
  },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' },
]
const tokenOption = [
  { name: 'DPT', address: getDptAddress() },
  { name: 'DGT', address: getDgtAddress() },
]

const DEPOSIT = 0
const WITHDRAW = 1

const ConvertModal = ({ onClose }) => {
  const [action, setAction] = useState(DEPOSIT)
  const [fromValue, setFromValue] = useState('0')
  const [toValue, setToValue] = useState('0')
  const [fromTokenIndex, setFromTokenIndex] = useState(1)
  const [countdown, setCountdown] = useState<string>(null)
  const { account, library } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const toTokenIndex = !fromTokenIndex ? 1 : 0
  const rewardsPool = useSelector(getRewardsPoolSelector)

  const userTokenData = useSelector(getUserTokenDataSelector)

  const balanceDpt = ethers.utils.formatEther(userTokenData?.dptBalance)
  const balanceDgt = ethers.utils.formatEther(userTokenData?.dgtBalance)

  const { dptAllowance, dgtAllowance } = useSelector(getUserTokenDataSelector)
  const { dptAmount, dgtAmount, firstPlayDate } = useSelector(getUserGameDataSelector)

  const swapOption = {
    DPT: {
      inUser: +balanceDpt,
      inGame: dptAmount,
    },
    DGT: {
      inUser: +balanceDgt,
      inGame: dgtAmount,
    },
  }

  let fromBalance
  let toBalance

  if (action === DEPOSIT) {
    fromBalance = swapOption[tokenOption[fromTokenIndex].name].inUser
    toBalance = swapOption[tokenOption[fromTokenIndex].name].inGame
  } else {
    fromBalance = swapOption[tokenOption[fromTokenIndex].name].inGame
    toBalance = swapOption[tokenOption[fromTokenIndex].name].inUser
  }

  const { feePerDay, numberLockDay, initialFee, decrementLockTime } = useSelector(getGameSettingSelector)

  const userAllowance = fromTokenIndex === 0 ? dptAllowance : dgtAllowance

  const { isLoading: isApproving, onCall: onApprove } = useCallContract(
    useTokenContract(tokenOption[fromTokenIndex].address),
    'approve',
  )

  const { isLoading: isBuying, onCall: onBuy } = useCallContract(useRewardGameContract(), 'buyShards')
  const { isLoading: isExchanging, onCall: onExchange } = useCallContract(
    useRewardGameContract(),
    'exchangeTokenSecured712',
  )

  const sliceAccount = account ? `${account.slice(0, 4)}...${account.slice(38)}` : '0x00...0000' // account length: 42

  const firstPlayDateWithToken = tokenOption[fromTokenIndex].name === 'DPT' ? 0 : firstPlayDate
  const convertFeePercent = useMemo(() => {
    const accumulationTime =
      Math.floor((Date.now() - firstPlayDateWithToken) / decrementLockTime) >= numberLockDay
        ? numberLockDay
        : Math.floor((Date.now() - firstPlayDateWithToken) / decrementLockTime)
    return !firstPlayDateWithToken ? 0 : initialFee - accumulationTime * feePerDay
  }, [firstPlayDateWithToken, fromTokenIndex, action])

  useEffect(() => {
    const timeCountdown = firstPlayDateWithToken + decrementLockTime * numberLockDay
    const interval = setInterval(() => {
      setCountdown(getTimeRemainDraw(timeCountdown))
    }, 1000)

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [firstPlayDateWithToken])

  const handleSwapFunction = () => {
    if (action === DEPOSIT) {
      setAction(WITHDRAW)
    } else {
      setAction(DEPOSIT)
    }
    setFromValue('0')
    setToValue('0')
  }
  const handleChange =
    (isFrom = true) =>
    (e) => {
      const fee = action === DEPOSIT ? 0 : convertFeePercent
      if (isFrom) {
        setFromValue(e)
        setToValue((e - (e * fee) / 100).toFixed(2).toString())
      }
    }

  const handleSelectToken = (value) => {
    setFromTokenIndex(tokenOption.findIndex((t) => t.name === value))
    setFromValue('0')
    setToValue('0')
  }

  const handleApprove = async () => {
    await onApprove([getRewardGameAddress(), MaxUint256])
    dispatch(fetchUserTokenData(account))
  }

  const handleBuy = async () => {
    if (fromValue > fromBalance) {
      return message.error('Exceed amount')
    }
    await onBuy([fromValue, tokenOption[fromTokenIndex].address])
    dispatch(fetchUserTokenData(account))
  }

  const bigAmount = ethers.utils.parseEther(fromValue?.toString() || '0')

  const handleExchangeToken = async () => {
    if (fromValue > fromBalance) {
      return message.error('Exceed amount')
    }
    if (fromValue > rewardsPool.currentRewards) {
      return message.error('Exceed pool amount')
    }
    const tokenAddress = tokenOption[fromTokenIndex].address
    const result = await getSignExchangeToken({
      amount: bigAmount.toString(),
      feePercent: convertFeePercent,
      tokenAddress,
    })
    const { adminSignature, nonce } = result

    const deadline = Math.round(new Date().getTime() / 1000) + DEADLINE_SIGNATURE_WITHDRAW
    const messageSign = {
      token: tokenAddress,
      account,
      amount: bigAmount.toString(),
      feePercent: convertFeePercent,
      nonce,
      deadline,
    }

    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Withdraw,
      },
      domain,
      primaryType: 'Withdraw',
      message: messageSign,
    })

    try {
      const userSignature = await library.send('eth_signTypedData_v4', [account, data])
      await onExchange([tokenAddress, bigAmount, convertFeePercent, nonce, adminSignature, userSignature, deadline])
      dispatch(fetchUserTokenData(account))
      dispatch(
        setGameState({
          rewardsPool: {
            currentRewards: (+rewardsPool.currentRewards - +fromValue).toString(),
            limitRewards: rewardsPool.limitRewards,
          },
        }),
      )
    } catch (error) {
      console.log('error', error)
    }
  }

  const selectAfterFrom = (
    <Select defaultValue="DGT" onChange={handleSelectToken} value={tokenOption[fromTokenIndex].name}>
      {/* <Option value="DPT">
        <img src={`/images/homepage-game/convert/dpt${action === WITHDRAW ? '-lock' : ''}.svg`} alt="coin game" /> DPT
      </Option> */}
      <Option value="DGT" className="alo">
        <img src={`/images/homepage-game/convert/dgt${action === WITHDRAW ? '-lock' : ''}.svg`} alt="coin game" /> DGT
      </Option>
    </Select>
  )
  const selectAfterTo = (
    <Select defaultValue="DGT" disabled>
      <Option value="DGT">
        <img
          src={`/images/homepage-game/convert/${tokenOption[fromTokenIndex].name.toLowerCase()}${
            action === DEPOSIT ? '-lock' : ''
          }.svg`}
          alt="coin game"
        />
        {tokenOption[fromTokenIndex].name}
      </Option>
    </Select>
  )

  return (
    <BaseModal
      header={<BeanButton className="fixed-on-top">Transfer</BeanButton>}
      onClose={onClose}
      styleInside={{ position: 'relative', top: 0, overflow: 'hidden' }}
      style={{ width: 825, height: 'auto' }}
    >
      <ConvertModalStyle>
        <DepositFormStyle>
          <form className="form">
            <div className="form-items">
              <div className="form-item">
                <div className="header">
                  <div className="title">From: {action === DEPOSIT ? sliceAccount : 'Contract Game'}</div>
                  <div className="balance">
                    <span>Balance:</span>
                    {fromBalance?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                </div>

                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={handleChange()}
                  className="inputCurrency"
                  addonAfter={selectAfterFrom}
                  value={fromValue}
                />
              </div>
              <div className={`form-icon ${action === DEPOSIT ? '' : 'rotate'}`}>
                <img src="/images/homepage-game/convert/convert-icon-X2.svg" alt="swap" onClick={handleSwapFunction} />
              </div>
              <div className="form-item">
                <div className="header">
                  <div className="title">To: {action === DEPOSIT ? 'Contract Game' : sliceAccount}</div>
                  <div className="balance">Balance: {toBalance?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                </div>

                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={handleChange(false)}
                  className="inputCurrency"
                  controls={false}
                  addonAfter={selectAfterTo}
                  type="tel"
                  disabled
                  value={toValue}
                />
              </div>
            </div>
            <div className="form-btn">
              {action === DEPOSIT ? (
                <>
                  {userAllowance === '0' ? (
                    <CustomButton
                      onClick={handleApprove}
                      name={`Approve ${tokenOption[fromTokenIndex].name}`}
                      disabled={isApproving}
                    />
                  ) : (
                    <CustomButton onClick={handleBuy} name="Deposit" disabled={isBuying} />
                  )}
                </>
              ) : (
                <CustomButton onClick={handleExchangeToken} name="Withdraw" disabled={isExchanging} />
              )}
            </div>
          </form>
        </DepositFormStyle>
        {action === WITHDRAW && (
          <FooterModalStyled>
            <div className="footer-line"></div>
            <div className="footer-fee">TAX: {convertFeePercent}%</div>
            <div className="footer">
              <div className="footer-text">
                <div className="footer-text-fee">Countdown to free tax:</div>
                <div className="footer-text-date">{countdown}</div>
              </div>
              <div className="footer-note">* Free tax after {numberLockDay} days since your first time rewarded</div>
            </div>
          </FooterModalStyled>
        )}
      </ConvertModalStyle>
    </BaseModal>
  )
}

const ConvertModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 25px 15px;

  /* position: absolute;
  width: 750px;
  height: 582px; */
  left: 18px;
  top: 78px;

  box-shadow: inset 7.10966px -4.06266px 4.06266px rgba(0, 0, 0, 0.25);
  border-radius: 40.6266px;
  .ant-tabs-tab {
    width: 300px;
    padding: 6px 6px 0;
    background: #143373;
    text-align: center;
    color: #fff;
    font-weight: 700;
    border-radius: 15.2px 15.2px 0px 0px;

    .ant-tabs-tab-btn {
      flex: 0 0 100%;
    }
    .innerTab {
      padding: 4px 0;
      width: 100%;
      height: 100%;
      border-radius: 15.2px 15.2px 0px 0px;
    }
    &.ant-tabs-tab-active {
      background-color: #54adff;
      .ant-tabs-tab-btn {
        color: #fff;
      }
      .innerTab {
        background: linear-gradient(180deg, rgba(84, 173, 255, 0) 0%, #379fff 100%);
        border: 1.01333px solid #0f6abe;
      }
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-nav {
    margin: 0 0 0 16px;
  }
  .ant-tabs,
  .ant-tabs-content-holder,
  .ant-tabs-content {
    height: 100%;
    color: #fff;
  }
`

const DepositFormStyle = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 40px;
  padding: 20px 26px 90px;
  margin-bottom: 50px;
  background: linear-gradient(180deg, #5aa0ff 24.21%, #003c74 79.34%);
  position: relative;

  .ant-input-number-wrapper {
    background-color: #fff;
    border-radius: 8.74126px;
    box-shadow: inset 0px 0px 2.62238px rgba(0, 0, 0, 0.29);
  }
  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-select-arrow {
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: grid;
    place-items: center;
  }

  .ant-input-number {
    border: none;
  }
  .ant-input-number-group-wrapper {
    width: 100%;
    background: #e7f8ff;
    border: 1.74825px solid rgba(162, 157, 154, 0.5);
    border-radius: 8.74126px;
    padding: 8px;
  }
  .ant-input-number-group-addon {
    width: 10rem;
    height: 30px;
    border-radius: 8.74126px;
    background: linear-gradient(180deg, #447ed5 0%, #1956b3 100%);
    .ant-select {
      width: 100%;

      color: #fff;
      font-size: 22px;

      .ant-select-selector {
        margin-top: 4px;
        height: 41px;

        .ant-select-selection-item {
          img {
            width: 40px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .inputCurrency {
    font-size: 18px;
    border-radius: 8.74126px;

    input {
      height: 72px;
    }
  }
  .form {
    &-btn {
      margin-top: 58px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    &-items {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
    &-item {
      color: white;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 1.2rem;
          align-self: center;
        }
        .balance {
          font-size: 36px;
          span {
            margin-right: 7px;
          }
        }
      }
    }
    &-icon {
      display: grid;
      place-items: center;
      margin-top: 22px;
      transition: ease all 0.5s;
      &:hover {
        transform: scale(1.1);
      }

      img {
        cursor: pointer;

        width: 135.2px;
      }
    }
    .rotate {
      transform: rotate(180deg);
    }
  }
`

const FooterModalStyled = styled.div`
  color: #333333;
  width: 100%;

  .footer {
    &-fee {
      display: grid;
      place-items: center;
      font-size: 1.2rem;
      font-weight: 400;
      margin: 10px 0px;
    }

    &-line {
      height: 2px;
      background-color: red;
      background: linear-gradient(
        90deg,
        rgba(182, 182, 182, 0.2),
        rgba(182, 182, 182, 1),
        rgba(182, 182, 182, 1),
        rgba(182, 182, 182, 0.2)
      );
    }

    &-text {
      font-size: 2rem;
      display: flex;
      justify-content: space-between;

      &-date {
        align-self: center;
        color: white;
        text-shadow: -1px -1px 0 #48bdff, 1px -1px 0 #48bdff, -1px 1px 0 #48bdff, 1px 1px 0 #48bdff;
      }
    }

    &-note {
      font-size: 1.4rem;
      color: rgba(176, 176, 176, 1);
    }
  }
`
export default ConvertModal
