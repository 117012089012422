import styled from 'styled-components'

export const ButtonDepositStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .deposit {
    p {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      z-index: 10;
      margin: 0px;
    }
    cursor: pointer;
    position: relative;
    background: #18aff7;
    border-radius: 24px;
    width: 200px;
    height: 56px;
    align-items: center;
    display: flex;
    justify-content: center;
    span:nth-child(1) {
      position: absolute;
      top: 0;
      background: #68c9ff;
      height: 50px;
      border-radius: 24px;
      width: 192px;
    }
    span:nth-child(3) {
      position: absolute;
      top: 5px;
      background: #7ed5ff;
      height: 19px;
      width: 162px;
      border-radius: 24px;
    }
  }

  .deposit.disabled {
    cursor: not-allowed;
    p {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      z-index: 10;
    }
    position: relative;
    background: #8a8a8a;
    border-radius: 24px;
    width: 200px;
    height: 56px;
    align-items: center;
    display: flex;
    justify-content: center;
    span:nth-child(1) {
      position: absolute;
      top: 0;
      background: #b2b2b2;
      height: 50px;
      border-radius: 24px;
      width: 192px;
    }
    span:nth-child(3) {
      position: absolute;
      top: 5px;
      background: #bfbfbf;
      height: 19px;
      width: 162px;
      border-radius: 24px;
    }
  }
`
