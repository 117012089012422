import { ONE_DAY } from 'constants/index'
import styled from 'styled-components'
function Energy({ energy = 0, lastFightTime }) {
  const recoveryTime = lastFightTime * 1000 + ONE_DAY - Date.now()
  const minutes = Math.floor((recoveryTime / 1000 / 60) % 60)
  const hours = Math.floor((recoveryTime / (1000 * 60 * 60)) % 24)
  return (
    <EnergyStyled style={{ bottom: `${energy >= 1 ? '10px' : '10px'}` }}>
      <div className="energy__style" style={energy ? { border: 'unset' } : { border: '2px solid #0091fb' }}>
        <div className="energy__bg" style={{ width: `${energy * 20}%` }}></div>

        <div className="energy__number" style={energy === 0 ? { color: 'red' } : { color: '#fff' }}>
          <span>{energy}/5</span>
        </div>

        <div className="energy__token">
          <img src="./images/play-now/lightning.png" alt="lightning.png" />
        </div>
      </div>

      <div className="energy__time">
        {energy === 5 ? (
          <span>Recover time: 0h</span>
        ) : (
          <span>Recover time: {recoveryTime < 0 ? '1d' : `${hours}h ${minutes}m`}</span>
        )}
      </div>
    </EnergyStyled>
  )
}

const EnergyStyled = styled.div`
  position: absolute;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 2px 0 0 #000000, -2px 0 0 #000000, 0 2px 0 #000000, 0 -2px 0 #000000, 1px 1px #000000,
    -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000;
  color: #fff;
  .energy__style {
    position: relative;
    width: 340px;
    height: 28px;
    border-radius: 206px;
    border: 2px solid #0091fb;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    .energy__bg {
      position: absolute;
      background: url('./images/market/energy.png') no-repeat;
      background-size: cover;
      border-radius: 206px;
      top: 0px;
      left: 0px;
      height: 100%;
      max-width: 100%;
    }
    .energy__number {
      position: absolute;
      span {
        margin-top: -5px;
      }
    }
    .energy__time {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #f01111;
        text-shadow: none;
        font-weight: 400;
        font-size: 28px;
        padding-right: 0px;
      }
    }
    .energy__token {
      position: absolute;
      left: -10px;
      z-index: 100;
      img {
        width: 25px;
      }
    }
  }
`
export default Energy
