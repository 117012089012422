import { createAsyncThunk } from '@reduxjs/toolkit'
import { getGameSetting } from 'api/actions/game'
import { getCivilians, getUser } from 'api/actions/user'
import erc20ABI from 'config/abi/erc20.json'
import { gameCharacter } from 'config/constants/game'
import { ethers } from 'ethers'
import { getDgtAddress, getDptAddress, getRewardGameAddress } from 'utils/addressHelpers'
import { getRewardGameContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import { GAME_ACTION } from './constants'
const { FETCH_USER_DATA, FETCH_USER_CIVILIANS, FETCH_GAME_SETTING, FETCH_REWARDS_POOL } = GAME_ACTION

export const fetchUserTokenData = createAsyncThunk<any, any>(FETCH_USER_DATA, async (account: string) => {
  const calls = []
  const dptToken = getDptAddress()
  const dgtToken = getDgtAddress()

  calls.push({ address: dptToken, name: 'balanceOf', params: [account] })
  calls.push({ address: dgtToken, name: 'balanceOf', params: [account] })
  calls.push({ address: dptToken, name: 'allowance', params: [account, getRewardGameAddress()] })
  calls.push({ address: dgtToken, name: 'allowance', params: [account, getRewardGameAddress()] })

  try {
    const [dptBalance, dgtBalance, dptAllowance, dgtAllowance] = await multicall(erc20ABI, calls, {
      requireSuccess: false,
    })

    const user = await getUser(account)
    return {
      userTokenData: {
        dptBalance: dptBalance.toString(),
        dgtBalance: dgtBalance.toString(),
        dptAllowance: dptAllowance.toString(),
        dgtAllowance: dgtAllowance.toString(),
      },
      userGameData: {
        dptAmount: user.amountDpt,
        dgtAmount: user.amountDgt,
        firstPlayDate: user.firstPlayDate,
      },
    }
  } catch (error) {
    console.log('error fetchGameUserTokenInfo', error)
    return {
      userTokenData: {
        dptBalance: '0',
        dgtBalance: '0',
        dptAllowance: '0',
        dgtAllowance: '0',
      },
      userGameData: {
        dptAmount: 0,
        dgtAmount: 0,
        firstPlayDate: 0,
      },
    }
  }
})

export const fetchUserCivilians = createAsyncThunk<any, any>(FETCH_USER_CIVILIANS, async (account: string) => {
  const { results } = await getCivilians(account)

  return {
    civilians: results.map((civilian) => {
      return {
        ...civilian,
        ...gameCharacter[civilian.heroRarity][civilian.heroName],
      }
    }),
  }
})

export const fetchGameSetting = createAsyncThunk<any>(FETCH_GAME_SETTING, async () => {
  const { feePerDay, numberLockDay, decrementLockTime, initialFee } = await getGameSetting()

  return {
    gameSetting: {
      initialFee,
      feePerDay,
      decrementLockTime,
      numberLockDay,
    },
  }
})

export const fetchRewardsPool = createAsyncThunk<any>(FETCH_REWARDS_POOL, async () => {
  const rewardContract = getRewardGameContract()
  const response = await rewardContract.getCurrentRewardPool(getDgtAddress())
  const rewardsUsed = ethers.utils.formatEther(response?.[0]) || '0'
  const limitRewards = ethers.utils.formatEther(response?.[1]) || '0'

  return {
    rewardsPool: {
      currentRewards: (+limitRewards - +rewardsUsed).toString(),
      limitRewards,
    },
  }
})
