import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

function ButtonGame({ name, onClick = null, className = 'button__game', disabled = false }) {
  return (
    <ButtonGameStyled>
      <Button disabled={disabled} onClick={onClick} className={className}>
        <div className="button__before"></div>
        {name}
      </Button>
    </ButtonGameStyled>
  )
}

const ButtonGameStyled = styled.div`
  margin: 0 auto;
  width: 220px;
  height: 60px;

  button {
    border: unset;
    background: unset;
    text-shadow: unset;
    position: relative;
    z-index: 10;
    padding-bottom: 8px;
    padding-left: 10px;
    box-shadow: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease all 0.5s;
    background: #2f93f0;
    height: 100%;
    width: 100%;
    border-radius: 55px;
    position: relative;

    &:hover,
    &:active,
    &:focus {
      background: #2f93f0;
      transform: scale(1.1);
    }

    .button__before {
      position: absolute;
      content: '';
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      background: #7ed5ff;
      width: 180px;
      height: 23px;
      top: 4px;
      border-radius: 162px;
      text-align: center;
      z-index: 2;
    }
    &::after {
      position: absolute;
      content: '';
      background: #68c9ff;
      width: 210px;
      height: 54px;
      top: 0;
      border-radius: 162px;
      z-index: 1;
    }
    span {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      z-index: 100;
    }
  }

  .button__game.disable {
    .button__before {
      background: #bababa;
    }
  }
`
export default ButtonGame
