import { GameCharacterConfig, EnemyConfig } from './types'

export const gameCharacter: GameCharacterConfig[][] = [
  [
    {
      name: 'Magician',
      chestImg: 'images/buy-chest/civilian/magician.png',
      img: './images/civilian-fusion/civilian/magician.png',
    },
    {
      name: 'Officer',
      chestImg: 'images/buy-chest/civilian/officer.png',
      img: './images/civilian-fusion/civilian/officer.png',
    },
  ],
  [
    {
      name: 'Police',
      chestImg: 'images/buy-chest/civilian/police.png',
      img: './images/civilian-fusion/civilian/police.png',
    },
    {
      name: 'Painter',
      chestImg: 'images/buy-chest/civilian/painter.png',
      img: './images/civilian-fusion/civilian/painter.png',
    },
  ],
  [
    {
      name: 'Football Player',
      chestImg: 'images/buy-chest/civilian/football-player.png',
      img: './images/civilian-fusion/civilian/football.png',
    },
    {
      name: 'Singer',
      chestImg: 'images/buy-chest/civilian/singer.png',
      img: './images/civilian-fusion/civilian/singer.png',
    },
  ],
  [
    {
      name: 'Fireman',
      chestImg: 'images/buy-chest/civilian/fireman.png',
      img: './images/civilian-fusion/civilian/fireman.png',
    },
    {
      name: 'Farmer',
      chestImg: 'images/buy-chest/civilian/farmer.png',
      img: './images/civilian-fusion/civilian/farm.png',
    },
  ],
  [
    {
      name: 'Chef',
      chestImg: 'images/buy-chest/civilian/chef.png',
      img: './images/civilian-fusion/civilian/chef.png',
    },
    {
      name: 'Doctor',
      chestImg: 'images/buy-chest/civilian/doctor.png',
      img: './images/civilian-fusion/civilian/doctor.png',
    },
  ],
]

export const enemiesConfig: EnemyConfig[] = [
  { name: 'Iron Tail', img: './images/pve/monster/beast.gif' },
  { name: 'Blade Mantis', img: './images/pve/monster/beast-1.gif' },
  { name: 'Putrid Snail', img: './images/pve/monster/beast-2.gif' },
  { name: 'Mad Rat', img: './images/pve/monster/beast-3.gif' },
  { name: 'Hooded Death', img: './images/pve/monster/beast-4.gif' },
  { name: 'Poison Stella', img: './images/pve/monster/beast-5.gif' },
  { name: 'Bully Croc', img: './images/pve/monster/beast-6.gif' },
  { name: 'Fox Beastman', img: './images/pve/monster/beast-7.gif' },
  { name: 'Cow Blower', img: './images/pve/monster/beast-8.gif' },
  { name: 'Baroda Maestro', img: './images/pve/monster/beast-9.gif' },
]

export const upgradeChance = [0, 0, 0, 0, 0, 0, 0, 0]

export const DEADLINE_SIGNATURE_WITHDRAW = 20 * 60

export default gameCharacter
