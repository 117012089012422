import API from 'api'
import config from 'api/config'

export const getUser = async (address: string) => {
  try {
    const user = await API({
      method: 'GET',
      url: `${config.API.USER_SERVICE}/${address}`,
    })
    return user
  } catch (error) {
    return error
  }
}

export const getSignExchangeToken = async (data) => {
  try {
    const result = await API({
      url: `${config.API.USER_SERVICE}/exchangeToken`,
      method: 'post',
      data,
      hasToken: true,
    })
    return result
  } catch (error) {
    return error
  }
}

export const getCivilians = async (account: string) => {
  try {
    const result = await API({
      url: `${config.API.CIVILIAN_SERVICE}?accountAddress=${account}&sortBy=heroRarity:desc`,
      method: 'get',
      params: {
        limit: 1000,
      },
    })
    return result
  } catch (error) {
    console.log('error', error)
    return error
  }
}
