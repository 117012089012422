import styled from 'styled-components'

import CloseButtonSVG from 'components2/svgIcon/CloseButtonSVG'
import WhiteBGWithNotch from './WhiteBGWithNotch'

const BaseModal = ({ children, background = undefined, styleInside = {}, style = undefined }) => {
  return (
    <DailyMissionModalStyled background={background} style={style}>
      <div className="modal-background">
        <WhiteBGWithNotch style={{ width: '100%', height: '100%' }} styleInside={styleInside}>
          {children}
        </WhiteBGWithNotch>
      </div>
    </DailyMissionModalStyled>
  )
}

const borderWidth = 20
const borderRadius = 30

const DailyMissionModalStyled = styled.div<any>`
  padding-top: 40px;
  width: 733px;
  height: 300px;
  display: flex;
  --background-style: ${({ background = 'linear-gradient(#78bcfc, #4f96da)' }) => background};

  .display-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: unset;
    width: 100%;
    max-width: 700px;
    top: unset;
    left: unset;
    transform: unset;
    background: #fff;
    border-radius: 30px;
    padding: 62px 36px !important;

    > span {
      font-size: 25px;
      color: #000000;
      margin-bottom: 20px;
    }

    button {
      border: unset;
      background: unset;
      text-shadow: unset;
      position: relative;
      z-index: 10;
      padding-bottom: 8px;
      padding-left: 10px;
      box-shadow: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: ease all 0.5s;
      background: #2f93f0;
      height: 100%;
      width: 100%;
      max-width: 200px;
      border-radius: 55px;
      position: relative;

      &:hover,
      &:active,
      &:focus {
        background: #2f93f0;
        transform: scale(1.1);
      }

      .button__before {
        position: absolute;
        content: '';
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        background: #7ed5ff;
        width: 180px;
        height: 23px;
        top: 4px;
        border-radius: 162px;
        text-align: center;
        z-index: 2;
      }

      &::after {
        position: absolute;
        content: '';
        background: #68c9ff;
        width: 210px;
        height: 54px;
        top: 0;
        border-radius: 162px;
        z-index: 1;
      }
      span {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        z-index: 100;
      }
    }
  }

  .button__log--short {
    cursor: pointer;
    .beam-btn-display {
      &:hover {
        opacity: 0.5;
        transition: 0.5s ease-in-out;
      }
    }
  }

  .fixed-on-top.active {
    padding: 5px;
  }

  .modal-background {
    width: 100%;
    height: 100%;
    border-radius: ${borderRadius + borderWidth}px;
    position: relative;
    background: var(--background-style);

    > div {
      display: flex;
    }

    .close-btn {
      transform: translate(24%, -30%);
      width: 40px;
      height: 40px;
    }

    .bean-button {
      button:nth-child(1) {
        position: absolute;
        top: 0;
        left: 35%;
        transform: translate(-50%, -50%);
        width: 220px;
      }
      button:nth-child(2) {
        position: absolute;
        top: 0;
        left: 65%;
        transform: translate(-50%, -50%);
        width: 220px;
      }
    }
  }

  .fixed-on-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 420px;
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    transform: translate(30%, -30%);
    width: 70px;
    height: 70px;
    cursor: pointer;
  }
`
export default BaseModal
