import React, {useState} from 'react'
import styled from 'styled-components'
import CivilianItem from '../../Components/CivilianItem'
import StakingNFTTime from './StakingNFTTime'
import ButtonStaking from '../ButtonStaking'
import useStakingNftActionButton from '../../../../../hooks/useStakingNftButton'
import {MAX_ENERGY} from '../../../../../config/constants/stakingNft'

function CivilianIdle({civilian, poolRating, poolRarity}) {
  const {titleButton, action, isLoading} = useStakingNftActionButton({
    isWorking: false,
    poolRating,
    poolRarity,
    tokenId: civilian.tokenId,
  })

  const [isDisabled, setIsDisabled] = useState(false)

  const percentEnergy = Math.floor((civilian.stamina / MAX_ENERGY) * 100)

  return (
    <StakingNftItemStyled>
      <CivilianItem civilian={civilian} percentEnergy={percentEnergy} />
      <>
        {percentEnergy === 100 ? (
          <div className="staking__full-energy">
            <p>Full energy</p>
          </div>
        ) : (
          <StakingNFTTime stamina={civilian.stamina} />
        )}

        <ButtonStaking
          nameButton={titleButton}
          onClick={async () => {
            setIsDisabled(true)
            await action(civilian.heroRating ?? 0, civilian.heroRarity, civilian.tokenId)
            setIsDisabled(false)
          }}
          disabled={isDisabled}
          bgStyled={{background: '#55B3F8'}}
        />
      </>
    </StakingNftItemStyled>
  )
}

const StakingNftItemStyled = styled.div`
  width: 288px;
  height: 584px;
  padding: 20px;
  background: linear-gradient(180deg, #0f3072 0%, rgba(0, 0, 2, 0.6) 100%);
  border-radius: 12px;
  margin: 32px 16px 0px 16px;
  .button__staking-nft {
    margin-bottom: 16px;
    margin-top: 12px;
  }
  .civilian__job {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
  }

  .civilian__img {
    width: 220px;
    height: 220px;
  }
  .staking__nft__character {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #999999;
    font-weight: 400;
    margin-bottom: 8px;
    .right {
      font-weight: 700;
      color: #fff;
    }
  }
  .staking__nft__character.top {
    margin-top: 15px;
  }

  .staking__full-energy {
    padding: 41px 0px 84px 0px;
    p {
      margin: 0;
      color: #ffff;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
    }
  }
`

export default CivilianIdle
