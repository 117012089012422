import { Button } from 'antd'
import styled from 'styled-components'

function ButtonPlayNow({ onClick, nameButton = 'Select Civilian', buttonProps = { disabled: false } }) {
  return (
    <ButtonPlayNowStyled className={`button__play-now ${buttonProps?.disabled ? 'disabled' : ''}`}>
      <Button onClick={onClick} {...buttonProps}>
        <div className="button__before"></div>
        <div className="button__after"></div>
        {nameButton}
      </Button>
    </ButtonPlayNowStyled>
  )
}

const ButtonPlayNowStyled = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 45px;
  &.disabled {
    button {
      .button__before {
        background: #bababa;
      }
      .button__after {
        background: #a7a7a7;
      }
      &:hover {
        background: #878787;
      }
    }
  }
  button {
    border: unset;
    background: unset;
    text-shadow: unset;
    position: relative;
    z-index: 10;
    padding: 10px;
    box-shadow: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease all 0.5s;
    background: #18aff7;
    height: 54px;
    width: 100%;
    border-radius: 55px;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      background: #18aff7;
    }

    .button__before {
      position: absolute;
      content: '';
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      background: #7ed5ff;
      width: 80%;
      height: 23px;
      top: 4px;
      border-radius: 162px;
      text-align: center;
      z-index: 2;
    }
    .button__after {
      background: #68c9ff;
      position: absolute;
      width: 96%;
      height: 47px;
      top: 0px;
      margin: 0 auto;
      border-radius: 126px;
    }
    span {
      color: #fff;
      font-size: 22px;
      font-weight: 400;
      z-index: 100;
      margin-top: -5px;
    }
  }
  .ant-btn[disabled] {
    background: #878787;
    &:hover {
      background: #878787;
    }
  }
`
export default ButtonPlayNow
