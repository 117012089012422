import styled from 'styled-components'

export const SpinnerMachineStyle = styled.div`
  background: url('./images/play-now/spin-mini-game-new.png') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  &.spined {
    z-index: -1;
  }
  .traffic-light {
    position: absolute;
    top: 122px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    gap: 10px;

    img {
      animation-name: light-animate;
      animation-iteration-count: infinite;

      &:first-of-type {
        animation-duration: 2s;
      }

      &:nth-child(2) {
        animation-duration: 4s;
      }

      &:nth-child(3) {
        animation-duration: 6s;
      }
    }
  }

  .bg__spinner {
    position: absolute;
    margin-top: 150px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      content: '';
      background: url('../images/bg-magin.png') no-repeat;
      background-size: contain;
      width: 772px;
      height: 876px;
      position: absolute;
      top: 92px;
      z-index: -1;
      left: 51%;
      transform: translateX(-50%);
    }

    img {
      width: 1000px;
      height: 1400px;
    }
  }

  .spinnerMachine {
    z-index: 4;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 638px;
    left: 12px;
    right: 0;
    margin: auto;
    align-items: center;

    .slot {
      display: flex;
      flex-direction: row;
      gap: 24px;
      width: 100%;
      height: 320px;
      overflow: hidden;
      margin-top: 320px;

      .row {
        padding: 45px 0;
      }
    }

    .slotFoot {
      display: flex;
      width: 100%;
      gap: 25px;
      align-items: center;
      justify-content: center;
      padding: 10px;

      .noitice-green,
      .noitice-red {
        position: relative;
        display: inline-block;
        outline: none;
        border: 0;
        vertical-align: middle;
        text-decoration: none;
        transform: translateY(6px);

        &.spinButton {
          font-weight: 600;
          color: #ffffff;
          text-transform: uppercase;
          padding: 0.5em 2em;
          border-radius: 0.4em;
          transform-style: preserve-3d;
          transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
          font-size: 1.3em;
          width: 130px;
          margin-top: 33px;

          &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: inherit;
            transform: translate3d(0, 0.75em, -1em);
            transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
          }
        }
      }
    }

    .noitice-green {
      &.spinButton {
        background: #4ce600;
        border: 2px solid #4ce600;

        &::before {
          background: #9beb73;
          box-shadow: 0 0 0 2px #9beb73, 0 0.625em 0 0 #4fc811;
        }
      }
    }

    .noitice-red {
      &.spinButton {
        background: #ff2c2c;
        border: 2px solid #ff2c2c;

        &::before {
          background: #ef4f4fde;
          box-shadow: 0 0 0 2px #ef4f4fde, 0 0.625em 0 0 #ca4343;
        }
      }
    }

    button {
      position: relative;
      display: inline-block;
      cursor: pointer;
      outline: none;
      border: 0;
      vertical-align: middle;
      text-decoration: none;
      font-size: inherit;
      font-family: inherit;

      &.spinButton {
        font-weight: 600;
        color: #ffffff;
        text-transform: uppercase;
        padding: 0.5em 2em;
        background: #ff3535;
        border: 2px solid #fe1313;
        border-radius: 0.75em;
        transform-style: preserve-3d;
        transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
        font-size: 1.3em;
        width: 200px;
        margin-top: 33px;

        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #ec8f8f;
          border-radius: inherit;
          box-shadow: 0 0 0 2px #f84949, 0 0.625em 0 0 #d56666;
          transform: translate3d(0, 0.75em, -1em);
          transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
        }
        &:hover {
          background: $pink;
          transform: translate(0, 0.25em);
          &::before {
            box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2;
            transform: translate3d(0, 0.5em, -1em);
          }
        }
        &:active {
          background: $pink;
          transform: translate(0em, 0.75em);
          &::before {
            box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
            transform: translate3d(0, 0, -1em);
          }
        }
      }
    }

    .buyMoreButton {
      cursor: pointer;
      font-weight: 400;
      font-style: normal;
      text-align: center;
      font-size: 1em;
      width: calc(100% - 20px);
      height: 30px;
      margin-bottom: 10px;
      background-color: #ffd900;
      border: none;
      border-radius: 2px;
    }

    .row {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      flex: 1 0 auto;
      margin: 0 5px 0 5px;
      overflow: hidden;
    }

    .ringMoving {
      height: 200px;
      line-height: 200px;
      text-align: center;
      font-size: 4em;
      animation: spinning 0.2s linear infinite;

      img {
        width: 121px;
      }
    }

    .ringEnd {
      height: 125px;
      line-height: 125px;
      text-align: center;
      font-size: 4em;
      animation: stop 0.5s ease-out forwards;

      &:first-of-type,
      &:nth-child(3) {
        img {
          opacity: 0.5;
        }
      }

      &:nth-child(2) {
        img {
          transform: scale(1.2);
          filter: drop-shadow(2px 4px 6px #ffff65);
        }
      }

      img {
        width: 121px;
      }
    }
  }

  @keyframes light-animate {
    0% {
      opacity: 20;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 1;
    }
  }

  @keyframes premio {
    0% {
      transform: translateY(100px);
    }
    80% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
      filter: drop-shadow(2px 4px 6px black);
    }
  }

  @keyframes spinning {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1000px);
    }
  }

  @keyframes stop {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-90px);
    }
    75% {
      transform: translateY(-80px);
    }
    100% {
      transform: translateY(-85px);
    }
  }
`
