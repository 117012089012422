import { useWeb3React } from '@web3-react/core'
import CardValue from 'components/CardValue'
import LoadingFullScreen from 'components2/Common/LoadingFullScreen'
import BackHome from 'components2/Layout/Head/Components/BackHome'
import CenterHead from 'components2/Layout/Head/Components/HeadCenter'
import History from 'components2/Layout/Head/Components/History'
import MusicButton from 'components2/Layout/Head/Components/MusicButton'
import ConnectWalletModal from 'components2/Modal/ConnectWalletModal'

import HistoryModal from 'components2/Modal/HistoryModal'
import GlobalModal from 'components2/static/GlobalModal'
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  DepositWithdrawGreenModal,
  DepositWithdrawRedModal,
} from 'components2/Modal/DepositWithdrawModal/DepositWithdrawV2'
import TransferToken from 'components2/Modal/TransferTokenModal'
import { useAppDispatch } from 'state'
import { fetchPredictionUserDataV1 } from 'state/actionsV1'
import { fetchPredictionPoolV2, fetchPredictionUserDataV2 } from 'state/actionsV2'
import { usePredictionV1 } from 'state/predictionV1/selector'
import { usePredictionV2 } from 'state/predictionV2/selector'
import styled from 'styled-components'
import PredictionRoundInfoV2 from 'views2/Components/Prediction/PredictionRoundInfoV2'
import {
  BackgroundPredictionGame,
  Container,
  CustomeBack,
  DivinerCha,
  DivinerContent,
  GreenCha,
  Left,
  Right,
} from './styled'

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'block' : 'none')};
  position: absolute;
  bottom: -22px;
  right: 0;
  left: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.contrast};
  color: ${({ theme }) => theme.colors.invertedContrast};
  border-radius: 16px;
  opacity: 0.7;
`

function PredictionGameV2() {
  const [widthGreen, setWidthGreen] = useState<number>(50)
  const [widthRed, setWidthRed] = useState<number>(50)
  const { account, chainId } = useWeb3React()
  const dispatch = useAppDispatch()

  const { logout } = useAuth()

  const { status, predictionPools } = usePredictionV2()

  const callUsePredictionV1 = usePredictionV1()

  const isHasTokenInOldContract = !!usePredictionV1().predictionPools.find(
    (pool) =>
      (parseInt(pool.userData?.longAmount) !== 0 && pool.userData) ||
      (parseInt(pool.userData?.shortAmount) !== 0 && pool.userData),
  )

  const showModalTransfer = () => {
    GlobalModal.show(<TransferToken onClose={GlobalModal.hide} callUsePredictionV1={callUsePredictionV1} />)
  }

  useEffect(() => {
    dispatch(fetchPredictionPoolV2())

    if (isHasTokenInOldContract) {
      showModalTransfer()
    }

    if (account && chainId) {
      dispatch(fetchPredictionUserDataV2(account))
      dispatch(fetchPredictionUserDataV1(account))
    }
  }, [account, chainId, isHasTokenInOldContract])

  const loading = status.lastUpdatedAt === null || predictionPools[0].totalLongSupply === null
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState<boolean>(false)
  const trimAccount = account ? `${account.slice(0, 4)}...${account.slice(38)}` : '0x00...0000' // account length: 42
  return (
    <BackgroundPredictionGame>
      <Left>
        <div className="avar">
          <img src="./images/home/avatar.png" alt="" />
          <span className="name">Diviner Protocol</span>

          <span className="id-number">
            {trimAccount}
            <img
              src="./images/home/icon-copy.png"
              alt=""
              onClick={() => {
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(account)
                  setIsTooltipDisplayed(true)
                  setTimeout(() => {
                    setIsTooltipDisplayed(false)
                  }, 1000)
                }
              }}
            />
            <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
          </span>
        </div>
      </Left>

      <Right>
        <div className="box-icon">
          {account ? (
            <>
              <img src="./images/home/logout-wallet.png" alt="" className="connect" onClick={logout} />
              <CustomeBack>
                <Link to="/">
                  <BackHome onClick />
                </Link>
              </CustomeBack>
              {/* <Calculators onClick={() => GlobalModal.show(<CalculatorsModal onClose={GlobalModal.hide} />)} /> */}
              <History onClick={() => GlobalModal.show(<HistoryModal onClose={GlobalModal.hide} />)} />
              <MusicButton music="./images/fileDiviner/theArcadeFull.mp3" />
            </>
          ) : (
            <>
              <img
                src="./images/home/connect-wallet.png"
                alt=""
                className="connect"
                onClick={() => {
                  GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
                }}
              />
              <CustomeBack>
                <Link to="/">
                  <BackHome onClick />
                </Link>
              </CustomeBack>
              {/* <Calculators onClick={() => GlobalModal.show(<CalculatorsModal onClose={GlobalModal.hide} />)} /> */}
              <History onClick={() => GlobalModal.show(<HistoryModal onClose={GlobalModal.hide} />)} />
              <MusicButton music="./images/fileDiviner/theArcadeFull.mp3" />
            </>
          )}
        </div>
      </Right>
      <CenterHead />
      {loading ? (
        <LoadingFullScreen style={{ background: '#ffffff88' }} />
      ) : (
        <Container>
          <DivinerCha>
            <div className="ExperienceMobile">
              <PredictionRoundInfoV2
                widthGreen={widthGreen}
                widthRed={widthRed}
                setWidthGreen={setWidthGreen}
                setWidthRed={setWidthRed}
              />
            </div>
            <DivinerContent>
              <GreenCha>
                <div className="GreenchaMain">
                  <video autoPlay loop muted playsInline style={{ width: '600px' }}>
                    <source src="./images/fileDiviner/green-char.webm" type="video/webm" />
                    <source src="./images/fileDiviner/green-char.mov" type="video/quicktime" />
                  </video>
                </div>

                <div className="GreenLong">
                  <img
                    src="./images/fileDiviner/long.png"
                    alt="Long.png"
                    onClick={() => {
                      if (!account) GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
                      else GlobalModal.show(<DepositWithdrawGreenModal onClose={GlobalModal.hide} />)
                    }}
                  />
                </div>
                <div className="GreenSD">
                  <img src="./images/fileDiviner/sdgreen.png" alt="Long.png" />
                </div>
              </GreenCha>

              <div className="Experience">
                <div className="ExperiencePC">
                  <PredictionRoundInfoV2
                    widthGreen={widthGreen}
                    widthRed={widthRed}
                    setWidthGreen={setWidthGreen}
                    setWidthRed={setWidthRed}
                  />
                </div>

                <div className="ExperienceBT">
                  {widthGreen < 5 ? (
                    <span style={{ width: `${widthGreen}%` }}>
                      <span></span>
                      <span></span>
                    </span>
                  ) : widthGreen < 15 ? (
                    <span style={{ width: `${widthGreen + 3}%` }}>
                      <span></span>
                      <span></span>
                      <img src="./images/fileDiviner/green.svg" alt="Green.svg" />
                    </span>
                  ) : widthGreen < 25 ? (
                    <span style={{ width: `${widthGreen + 3}%` }}>
                      <span></span>
                      <p className="ExperienceP">
                        <CardValue value={widthGreen} decimals={1} />
                      </p>
                      <span></span>
                      <img src="./images/fileDiviner/green.svg" alt="Green.svg" />
                    </span>
                  ) : widthGreen >= 99 ? (
                    <span style={{ width: `${widthGreen}%` }}>
                      <span></span>
                      <p className="ExperienceP">
                        <CardValue value={widthGreen} decimals={1} />%
                      </p>
                      <span></span>
                    </span>
                  ) : widthGreen < 100 ? (
                    <span style={{ width: `${widthGreen}%` }}>
                      <span></span>
                      <p className="ExperienceP">
                        <CardValue value={widthGreen} decimals={1} />%
                      </p>
                      <span></span>
                      <img src="./images/fileDiviner/green.svg" alt="Green.svg" />
                    </span>
                  ) : (
                    <span style={{ width: `${widthGreen - 3}%` }}>
                      <span></span>
                      <span></span>
                    </span>
                  )}

                  {widthRed < 15 ? (
                    <span style={{ width: `${widthRed + 3}%` }}>
                      <span></span>
                      <span></span>
                    </span>
                  ) : widthRed < 25 ? (
                    <span style={{ width: `${widthRed + 3}%` }}>
                      <span></span>
                      <span></span>
                      <p className="ExperienceP">
                        <CardValue value={widthRed} decimals={1} />
                      </p>
                    </span>
                  ) : widthRed >= 99 ? (
                    <span style={{ width: `${widthRed}%` }}>
                      <span></span>
                      <span></span>
                      <p className="ExperienceP">
                        <CardValue value={widthRed} decimals={1} />%
                      </p>
                    </span>
                  ) : (
                    <span style={{ width: `${widthRed + 3}%` }}>
                      <span></span>
                      <span></span>
                      <p>
                        <CardValue value={widthRed} decimals={1} />%
                      </p>
                    </span>
                  )}
                </div>
              </div>

              <GreenCha>
                <div className="RedchaMain">
                  <video autoPlay loop muted playsInline style={{ width: '600px' }}>
                    <source src="./images/fileDiviner/red-char.webm" type="video/webm" />
                    <source src="./images/fileDiviner/red-char.mov" type="video/quicktime" />
                  </video>
                </div>

                <div className="GreenLong">
                  <img
                    src="./images/fileDiviner/short.png"
                    alt="Short.png"
                    onClick={() => {
                      if (!account) GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
                      else GlobalModal.show(<DepositWithdrawRedModal onClose={GlobalModal.hide} />)
                    }}
                  />
                </div>

                <div className="GreenSD">
                  <img src="./images/fileDiviner/sdred.png" alt="sdred.png" />
                </div>
              </GreenCha>
            </DivinerContent>
          </DivinerCha>
        </Container>
      )}
    </BackgroundPredictionGame>
  )
}

export default PredictionGameV2
