import React from 'react'
import styled from 'styled-components'

function Reward({ currentRewards = '0', limitRewards = '0' }) {
  return (
    <RewardStyled>
      <span>Reward Pool/day: </span>
      <div className="energy__style">
        <div className="energy__bg" style={{ width: `${Math.floor((+currentRewards / +limitRewards) * 100)}%` }}></div>
        {currentRewards === '0' ? (
          <div className="energy__time">
            <span> Reset at 12:00 AM UTC</span>
          </div>
        ) : (
          <div className="energy__number">
            <span>
              {Math.round(+currentRewards || 0)}/{Math.round(+limitRewards || 0)}
            </span>
          </div>
        )}

        <div className="energy__token">
          <img src="./images/play-now/token-dgt-new.png" alt="token-dpt.svg" />
        </div>
      </div>
    </RewardStyled>
  )
}

const RewardStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  text-shadow: 2px 0 0 #1796ff, -2px 0 0 #1796ff, 0 2px 0 #1796ff, 0 -2px 0 #1796ff, 1px 1px #1796ff,
    -1px -1px 0 #1796ff, 1px -1px 0 #1796ff, -1px 1px 0 #1796ff;
  color: #fff;
  width: 1190px;
  margin: 0 auto;
  height: 135px;
  margin-bottom: 60px;
  background: url('./images/play-now/bg-total-reward.svg') no-repeat;
  background-size: 100% 100%;

  span {
    padding-right: 70px;
  }
  .energy__style {
    position: relative;
    width: 650px;
    height: 43px;
    border-radius: 206px;
    border: 2px solid #0091fb;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    .energy__bg {
      position: absolute;
      background: url('./images/play-now/energy.svg') no-repeat;
      background-size: cover;
      border-radius: 206px;
      top: 0px;
      left: 0px;
      height: 100%;
    }
    .energy__number {
      position: absolute;
      span {
        padding: 0;
        margin-top: -5px;
      }
    }
    .energy__time {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #a0a0a0;
        text-shadow: none;
        font-weight: 400;
        font-size: 28px;
        padding-right: 0px;
      }
    }
    .energy__token {
      position: absolute;
      left: -50px;
      z-index: 100;
      img {
        width: 85px;
      }
    }
  }
`
export default Reward
