import CardValue from 'components/CardValue'
import { utils } from 'ethers'
import { useActiveWeb3React } from 'hooks'
import Marquee from 'react-fast-marquee'
import { useSelector } from 'react-redux'
import { useFetchUser } from 'state/hooks'
import { getUserTokenDataSelector } from 'state/user/reducer'
import { addCommas, maxNumberAfterDot } from 'utils/formatNumber'
import { Center } from './styled'

CenterHead.propTypes = {}

function CenterHead() {
  const { account } = useActiveWeb3React()

  const useDAta = useSelector(getUserTokenDataSelector)

  useFetchUser(account)

  const bnbBalanceFormatted = utils.formatEther(useDAta?.bnb?.balance || '0')
  const dptBalanceFormatted = utils.formatEther(useDAta?.dpt?.balance || '0')

  return (
    <div>
      <Center>
        <div className="box-coin">
          <div className="item">
            <img className="icon" src="./images/home/logo-bnb.png" alt="imgs" />
            <span>
              <CardValue value={+bnbBalanceFormatted}></CardValue>
            </span>
          </div>

          <div className="item">
            <img className="icon" src="./images/fileDiviner/frameprice.png" alt="imgs" />
            <span>
              <CardValue value={+dptBalanceFormatted} decimals={2}></CardValue>
            </span>
            <a
              href="https://pancakeswap.finance/swap?outputCurrency=0xe69caef10a488d7af31da46c89154d025546e990"
              rel="noreferrer"
              target="_blank"
            >
              <img className="icon right" src="./images/home/icon-plus.png" alt="imgs" />
            </a>
          </div>

          {/* <div className="item">
            <img className="icon" src="./images/home/icon-3.png" alt="imgs" />
            <span>500</span>
          </div> */}
        </div>

        <div className="info">
          <Marquee speed={50}>
            {/* <p>
              This version is only for <span>TESTING</span>. Deposit time is 10 minutes and Lock time is 30 minutes.
              <br /> On Mainnet, Deposit time is 1 day and Lock time is 6-day.{' '}
            </p> */}
          </Marquee>
        </div>
      </Center>
    </div>
  )
}

export default CenterHead
