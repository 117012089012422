import React from 'react'
import styled from 'styled-components'

function CivilianStar({star = 5}) {
  return (
    <CivilianStarStyled className="item__star">
      {[...Array(star)].map(() => (
        <img src="./images/games/star.svg" alt="star" />
      ))}
    </CivilianStarStyled>
  )
}

const CivilianStarStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin: 0px 2px;
    width: 30px;
  }
`
export default CivilianStar
