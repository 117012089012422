import styled from 'styled-components'

export const HistoryStyled = styled.div`
  img {
    transition: 0.5s ease all;
    cursor: pointer;
    width: 88px;
    &:hover {
      filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
        drop-shadow(2px -2px 2px #ffffff);
    }
  }
`
