import { useSelector } from 'react-redux'
import BigNumber from 'bignumber.js'

import { BUSD_USDT_PRICE, CAKE_PRICE, DPT_PRICE, DPT_BNB_PRICE } from 'config/constants/prediction'
import { State } from '../types'
// Prediction
export const useActiveRoundIdSelectorV1 = () => {
  const activeRoundId = useSelector((state: State) => state.prediction.activeRoundId)
  return activeRoundId
}
export const usePriceInPool = (): BigNumber[] => {
  const price = [
    new BigNumber(CAKE_PRICE),
    new BigNumber(BUSD_USDT_PRICE),
    new BigNumber(DPT_PRICE),
    new BigNumber(DPT_BNB_PRICE),
  ]
  return price
}

export const usePredictionV1 = () => {
  const prediction = useSelector((state: State) =>
    state.prediction.round.find((r) => r.roundId === state.prediction.activeRoundId),
  )
  return prediction
}

export const usePredictionFromPid = (pid) => {
  const prediction = useSelector((state: State) =>
    state.prediction.round.find((r) => r.roundId === state.prediction.activeRoundId),
  ).predictionPools.find((p) => p.pid === pid)
  return prediction
}
