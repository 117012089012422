import {useSelector} from 'react-redux'
import {State} from 'state/types'

export const useIsLoadingSelector = (): boolean => {
  return useSelector((state: State) => state.game.isLoading)
}

export const useActiveCivilianId = () => {
  return useSelector((state: State) => state.game.activeCivilianId)
}

export const useFightStatus = () => {
  return useSelector((state: State) => state.game.fightStatus)
}

export const useFightResult = () => {
  return useSelector((state: State) => state.game.fightResult)
}

export const useGamePrice = () => {
  return useSelector((state: State) => state.game.gamePrice)
}

export const useActiveCharacter = () => {
  return useSelector((state: State) => state.game.userData.characters[state.game.activeCivilianId])
}

export const useSelectedRound = () => {
  return useSelector((state: State) => state.game.selectedRound)
}

export const useGameUserData = () => {
  return useSelector((state: State) => state.game.userData)
}

export const useGameEnemies = () => {
  return useSelector((state: State) => state.game.enemies)
}
