import React from 'react'
import styled from 'styled-components'
import CivilianStar from '../CivilianFusion/components/CivilianStar'

function CivilianItem({
  lv,
  img,
  star,
  rating,
  name = null,
  className = 'civilianItem__style',
  onClick = null,
  onClose = null,
  isDisabled = false,
}) {
  return (
    <CivilianItemStyled className={className} onClick={onClick} isDisabled={isDisabled}>
      <img src={`./images/civilian-fusion/bg-lv${parseInt(rating) + 1}.png`} alt="bg-item" />
      <div className="item__rating">
        <span>{rating}</span>
      </div>
      <div className="item__close" onClick={onClose}>
        <img src="./images/civilian-fusion/close-item.svg" alt="close" />
      </div>
      <div className="item__lv">
        <span>Lv.{lv}</span>
      </div>
      <div className="item__check">
        <img src="./images/civilian-fusion/check.png" alt="check" />
      </div>
      <div className="item__content">
        <img src={img} alt="farm.png" />
      </div>
      <CivilianStar star={star} />
      <div className="civilian__hover"></div>
    </CivilianItemStyled>
  )
}

const CivilianItemStyled = styled.div<{isDisabled: boolean}>`
  width: 134px;
  height: 134px;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  filter: ${(props) => props.isDisabled && 'grayscale(1)'};

  &.active {
    filter: drop-shadow(0px 0px 8px #000);
    .item__check {
      opacity: 1;
    }
  }

  .item__close {
    max-width: 24px;
    position: absolute;
    top: -8px;
    right: -5px;
    display: none;
    z-index: 100;
    img {
      width: 100%;
    }
  }

  img {
    width: 100%;
  }

  .item__content {
    position: absolute;
    width: 122px;
    height: 122px;
  }

  .item__rating {
    position: absolute;
    top: 3px;
    left: 14px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }

  .item__star {
    position: absolute;
    left: 50%;
    bottom: 2px;
    transform: translate(-50%, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 16px;
      margin-right: 2px;
    }
  }

  .item__lv {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-shadow: 0px 0px 2px #000000;
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 10;
  }

  .item__check {
    position: absolute;
    bottom: 22px;
    right: 12px;
    z-index: 100;
    opacity: 0;
    img {
      width: 32px;
      height: 32px;
    }
  }
`
export default CivilianItem
