import { createSlice } from '@reduxjs/toolkit'
import { ONE_DAY } from 'constants/index'
import { GameDbState, State } from 'state/types'
import { isFulfilledAction, isPendingAction, isRejectedAction } from '../../utils/helpers'
import { GAME_SLICE_NAME } from './constants'

const initialState = {
  activeCivilianId: 0,
  userTokenData: {
    dptBalance: '0',
    dgtBalance: '0',
    dptAllowance: '0',
    dgtAllowance: '0',
  },
  userGameData: {
    dptAmount: 0,
    dgtAmount: 0,
    firstPlayDate: 0,
  },
  gameSetting: {
    initialFee: 0,
    feePerDay: 0,
    decrementLockTime: 0,
    numberLockDay: 0,
  },
  civilians: [],
  isLoading: true,
  rewardsPool: {
    currentRewards: '0',
    limitRewards: '0',
  },
} as GameDbState

export const gameSlice = createSlice({
  name: GAME_SLICE_NAME,
  initialState,
  reducers: {
    setGameState: (state: any, action) => {
      Object.keys(action?.payload || {}).forEach((key) => {
        state[key as keyof GameDbState] = action.payload[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilledAction(`${GAME_SLICE_NAME}/`), (state: any, action) => {
        state.isLoading = false
        Object.keys(action?.payload || {}).forEach((key) => {
          state[key as keyof GameDbState] = action.payload[key]
        })
      })
      .addMatcher(isPendingAction(`${GAME_SLICE_NAME}/`), (state) => {
        state.isLoading = true
      })
      .addMatcher(isRejectedAction(`${GAME_SLICE_NAME}/`), (state) => {
        state.isLoading = false
      })
  },
})

// Selectors

export const getGameSelector = (state: any) => state.gamedb
export const getUserTokenDataSelector = (state: State) => state.gamedb.userTokenData
export const getUserGameDataSelector = (state: State) => state.gamedb.userGameData
export const getGameSettingSelector = (state: State) => state.gamedb.gameSetting
export const getUserCiviliansSelector = (state: State) => {
  return state.gamedb.civilians.map((item) => {
    const recoveryTime = item.lastFightTime * 1000 + ONE_DAY - Date.now()
    const updatedEnergy = recoveryTime < 0 ? 5 : item.energy
    return {
      ...item,
      energy: updatedEnergy,
    }
  })
}
export const getRewardsPoolSelector = (state: State) => state.gamedb.rewardsPool

export const { setGameState } = gameSlice.actions
export default gameSlice.reducer
