import styled from 'styled-components'

import CloseButtonSVG from 'components2/svgIcon/CloseButtonSVG'
import WhiteBGWithNotch from './WhiteBGWithNotch'

const BaseModal = ({
  header,
  onClose = null,
  children,
  background = undefined,
  styleInside = {},
  style = undefined,
  bgSVG = 'white',
}) => {
  return (
    <DailyMissionModalStyled background={background} style={style}>
      <div className="modal-background">
        {header}
        {onClose && <CloseButtonSVG className="close-btn" onClick={onClose} />}
        <WhiteBGWithNotch bgSVG={bgSVG} style={{ width: '100%', height: '100%' }} styleInside={styleInside}>
          {children}
        </WhiteBGWithNotch>
      </div>
    </DailyMissionModalStyled>
  )
}

const borderWidth = 20
const borderRadius = 30

const DailyMissionModalStyled = styled.div<any>`
  padding-top: 40px;
  width: 730px;
  height: 580px;
  --background-style: ${({ background = 'linear-gradient(#78bcfc, #4f96da)' }) => background};

  .button__log--short {
    cursor: pointer;
    .beam-btn-display {
      &:hover {
        opacity: 0.5;
        transition: 0.5s ease-in-out;
      }
    }
  }

  .fixed-on-top.active {
    padding: 5px;
  }
  .modal-background {
    width: auto;
    height: 100%;
    border-radius: ${borderRadius + borderWidth}px;
    position: relative;
    background: var(--background-style);
    padding: ${borderWidth}px;
    .bean-button {
      button:nth-child(1) {
        position: absolute;
        top: 0;
        left: 35%;
        transform: translate(-50%, -50%);
        width: 220px;
      }
      button:nth-child(2) {
        position: absolute;
        top: 0;
        left: 65%;
        transform: translate(-50%, -50%);
        width: 220px;
      }
    }
  }

  .fixed-on-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 420px;
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    transform: translate(30%, -30%);
    width: 70px;
    height: 70px;
    cursor: pointer;
  }
`
export default BaseModal
