import BigNumber from 'bignumber.js'
import { DPT_PRICE } from 'config/constants/prediction'
import useRefresh from 'hooks/useRefresh'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { fetchPrices } from './prices'
import { State } from './types'
import { fetchUserTokenData } from './user/actions'
import { getUserReboundSelector, setUserState } from './user/reducer'

export const useFetchUser = (account: string) => {
  const isFirstRender = useRef(true)
  const dispatch = useAppDispatch()
  const rebound = useSelector(getUserReboundSelector)

  useEffect(() => {
    if (account) {
      dispatch(fetchUserTokenData(account))
      dispatch(setUserState({ rebound: false }))
    } else
      dispatch(
        setUserState({
          userTokenData: {
            dpt: {
              balance: '0',
            },
            busd: {
              balance: '0',
            },
            bnb: {
              balance: '0',
            },
          },
        }),
      )
  }, [account])
  useEffect(() => {
    if (rebound && !isFirstRender.current) {
      if (account) {
        dispatch(fetchUserTokenData(account))
        dispatch(setUserState({ rebound: false }))
      }
    } else isFirstRender.current = false
  }, [account, rebound])
}

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPrices() as any)
  }, [dispatch, slowRefresh])
}

export const usePriceDptBusd = (): BigNumber => {
  return new BigNumber(DPT_PRICE) // TODO: this one will call api , current set price default
}

// export const usePriceCakeBusd = (): BigNumber => {
//   return new BigNumber(CAKE_PRICE)
// }

// export const usePriceDptBnbToBusd = (): BigNumber => {
//   return new BigNumber(DPT_BNB_PRICE)
// }

// export const usePriceCakeBnbToBusd = (): BigNumber => {
//   return new BigNumber(CAKE_BNB_PRICE)
// }

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}
export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}
