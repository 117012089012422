import {Button} from '@diviner-protocol/uikit'
import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

function ButtonStaking({bgStyled, nameButton = 'Stake', onClick = null, disabled = false}) {
  return (
    <ButtonStakingStyled>
      <Button onClick={onClick} disabled={disabled} style={bgStyled}>
        <span>{nameButton}</span>
      </Button>
    </ButtonStakingStyled>
  )
}

export function ButtonViewWorkingNFT({
  bgStyled,
  nameButton = 'Stake',
  onClick = null,
  poolRating,
  poolRarity,
  tokenId,
}) {
  return (
    <ButtonStakingStyled style={bgStyled}>
      {/* <Button onClick={onClick}> */}
      <Link to={`/staking-nft?rating=${parseInt(poolRating) + 1}&rarity=${parseInt(poolRarity) + 1}`}>
        {nameButton}
      </Link>
      {/* </Button> */}
    </ButtonStakingStyled>
  )
}

const ButtonStakingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
  button {
    height: 42px;
    border-radius: 55px;
    width: 100%;
    border: unset;
    background: unset;
    text-shadow: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    box-shadow: unset;
    transition: ease all 0.5s;
    &:hover {
      background: unset;
      color: #fff;
    }
  }
  button.pancake-button--disabled {
    opacity: 0.7 !important;
  }
  a {
    height: 42px;
    border-radius: 55px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }
`
export default ButtonStaking
