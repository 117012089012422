import React from 'react'
import 'antd/dist/antd.css'
import ReactDOM from 'react-dom'
import App from './App'
import Providers from './Providers'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
// test2
// import 'bootstrap/dist/css/bootstrap.min.css'

if ('ethereum' in window) (window.ethereum as any).autoRefreshOnNetworkChange = false

window.addEventListener('error', () => {
  localStorage?.removeItem('redux_localstorage_simple_lists')
})

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <>
        {/* <ListsUpdater /> */}
        {/* <ApplicationUpdater /> */}
        {/* <TransactionUpdater /> */}
        {/* <MulticallUpdater /> */}
      </>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
