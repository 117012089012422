import { BigNumber } from 'bignumber.js'
import axios from 'axios'
import predictionABI from 'config/abi/prediction.json'
import PredictionConfig, { pancakeApy, DPT_PRICE } from 'config/constants/prediction'
import {
  getAddress,
  getCakeAddress,
  getDptAddress,
  getPredictionAddressV1,
  getPredictionAddressV2,
  getWbnbAddress,
} from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import { getPoolApy } from 'utils/apy'
import { getPredictionContractsV1, getPredictionContractsV2 } from 'utils/contractHelpers'
import tokens from 'config/constants/tokens'

export const fetchPriceToken = async (address: string) => {
  try {
    const { data } = await axios.get(`https://api.pancakeswap.info/api/v2/tokens/${address}`)
    return data.data.price
  } catch (error) {
    console.log('error price : ', error)
    return DPT_PRICE
  }
}

const fetchPredictionsV2 = async () => {
  const predictionAddress = getPredictionAddressV2()
  const predictionContracts = getPredictionContractsV2()
  const predictions = []
  try {
    for (let i = 0; i < predictionAddress.length; i++) {
      const fetchStatus = predictionContracts[i].status()
      const fetchDptPerBlock = predictionContracts[i].dptPerBlock()
      const fetTotalAllocPoint = predictionContracts[i].totalAllocPoint()
      const fetchWithdrawFee = predictionContracts[i].withdrawFee()

      const fetchData = Promise.all(
        PredictionConfig.map(async (predictionConfig) => {
          const calls = [
            // Total supply of LP tokens
            {
              address: predictionAddress[i],
              name: 'pools',
              params: [predictionConfig.pid],
            },
          ]

          const result = await multicall(predictionABI, calls, { requireSuccess: false })
          return {
            ...predictionConfig,
            allocPoint: result[0].allocPoint.toString(),
            totalLongSupply: result[0].totalLongSupply.toString(),
            totalShortSupply: result[0].totalShortSupply.toString(),
          }
        }),
      )
      const [rawStatus, dptPerBlock, totalAllocPoint, withdrawFee, poolsInfo] = await Promise.all([
        fetchStatus,
        fetchDptPerBlock,
        fetTotalAllocPoint,
        fetchWithdrawFee,
        fetchData,
      ])
      const cakePrice = +(await fetchPriceToken(getCakeAddress()))
      const dptPrice = +(await fetchPriceToken(getDptAddress()))
      const bnbPrice = await fetchPriceToken(getWbnbAddress())
      const dptBnbPrice = (bnbPrice * 2) / Math.sqrt(bnbPrice / dptPrice)

      const price = [cakePrice, 2, dptPrice, dptBnbPrice]

      const bigDptPerBlock = new BigNumber(dptPerBlock.toString())

      const longShortApy = poolsInfo.map((p, index) => {
        const { totalLongSupply, totalShortSupply, allocPoint } = p
        const bigTotalLongSupply = new BigNumber(totalLongSupply)
        const bigTotalShortSupply = new BigNumber(totalShortSupply)

        const rate = bigTotalLongSupply.div(bigTotalShortSupply.toNumber()).toNumber() // ty le apy pancake x rate (cong them ben thua)
        const longPancakeApy =
          pancakeApy[index] +
          (bigTotalLongSupply.toNumber() === 0
            ? pancakeApy[index]
            : bigTotalShortSupply.toNumber() !== 0
            ? pancakeApy[index] * (1 / rate)
            : 0)
        const shortPancakeApy =
          pancakeApy[index] +
          (bigTotalShortSupply.toNumber() === 0
            ? pancakeApy[index]
            : bigTotalLongSupply.toNumber() !== 0
            ? pancakeApy[index] * rate
            : 0)

        let dptPoolApy = getPoolApy(
          price[index],
          dptPrice,
          bigTotalLongSupply.div(1e18).plus(bigTotalShortSupply.div(1e18)).toNumber() !== 0
            ? bigTotalLongSupply.div(1e18).plus(bigTotalShortSupply.div(1e18)).toNumber()
            : 1,
          (bigDptPerBlock.div(1e18).toNumber() * allocPoint) / totalAllocPoint.toNumber(),
        )

        if (i === 1) dptPoolApy = 300
        // const longApy = longPancakeApy
        const longApy = dptPoolApy + longPancakeApy
        // const shortApy = shortPancakeApy
        const shortApy = dptPoolApy + shortPancakeApy

        return { longApy, shortApy }
      })
      const totalLongShortApy = longShortApy.reduce(
        (pre, cur) => {
          return { totalLongApy: pre.totalLongApy + cur.longApy, totalShortApy: pre.totalShortApy + cur.shortApy }
        },
        { totalLongApy: 0, totalShortApy: 0 },
      )

      const longApy = totalLongShortApy.totalLongApy / poolsInfo.length
      const shortApy = totalLongShortApy.totalShortApy / poolsInfo.length

      const status = {
        initialPrice: rawStatus.initialPrice?.toString(),
        isLocking: rawStatus.isLocking,
        lastUpdatedAt: parseInt(rawStatus.lastUpdatedAt?.toString()) * 1000,
      }
      predictions.push({
        status,
        longApy,
        shortApy,
        withdrawFee: withdrawFee.toString(),
        poolsInfo,
      })
    }
    return predictions
  } catch (error) {
    console.log('fetch pool info error : ', error)
    return [
      {
        status: {},
        poolsInfo: [],
      },
    ]
  }
}

export default fetchPredictionsV2
