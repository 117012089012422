import React, { useState } from 'react'
import styled from 'styled-components'
import PlayNow from 'components2/Modal/PlayNowModal'
import { useSelector } from 'react-redux'
import { getUserGameDataSelector } from 'state/gameDb/reducer'
import GlobalModal from 'components2/static/GlobalModal'
import ButtonPlayNow from '../ButtonPlayNow'
import ContentsJob from './ContentsJob'

function JobTitle({ name, img, subUrl, level, minReward, maxReward, priceToFight }) {
  // const [items, setItems] = useState(1)
  const { dptAmount, dgtAmount } = useSelector(getUserGameDataSelector)

  const ClickShowModal = () => {
    GlobalModal.show(<PlayNow subUrl={subUrl} onClose={GlobalModal.hide} />)
  }
  return (
    <JobTitleStyled>
      <div className="title">
        <span>{name}</span>
        {/* <img src="./images/civilian-fusion/question.svg" alt="" /> */}
      </div>
      <div className="job-title__bg__img" style={{ background: `url(./images/play-now/${img})` }}>
        <div className="job-title__bg__price">
          <img src="./images/play-now/token-dgt-new.png" alt="" />
          <span>{priceToFight}</span>
        </div>
      </div>
      <ContentsJob minReward={minReward} maxReward={maxReward} level={level} />
      <ButtonPlayNow buttonProps={{ disabled: dgtAmount < priceToFight }} onClick={ClickShowModal} />
    </JobTitleStyled>
  )
}

const JobTitleStyled = styled.div`
  margin: 0 auto;
  padding: 0px 20px 30px 20px;
  border-radius: 12px;
  border: solid 5px #6eb1f2;
  background: linear-gradient(180deg, #0f3072 0%, rgba(0, 0, 2, 0.6) 100%);
  .title {
    display: flex;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;

    font-size: 31px;
    font-weight: 700;
    padding: 10px;
    background: url('./images/play-now/bg-title.svg') no-repeat;
    background-size: 100% 100%;
    width: 326px;
    margin: 0 auto;
    margin-bottom: 30px;
    height: 100%;
    margin-top: -1px;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      margin-top: -5px;
    }
  }
  .job-title__bg__img {
    width: 445px;
    margin: 0 auto;
    height: 338px;
    border-radius: 16px;
    background: linear-gradient(180deg, #cfe1fd 0%, #98bef8 100%);
    position: relative;
    margin-bottom: 45px;
    .job-title__bg__price {
      position: absolute;
      right: 0;
      top: 0;
      padding: 15px 25px;
      background: #a5a5a592;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 70px;

      span {
        text-shadow: 2px 0 0 #000000, -2px 0 0 #000000, 0 2px 0 #000000, 0 -2px 0 #000000, 1px 1px #000000,
          -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000;
        font-weight: 700;
        font-size: 35px;
        color: #fff;
        padding-left: 10px;
      }
      img {
        width: 39px;
      }
    }
  }
`
export default JobTitle
