import BeanButton from 'components2/Common/BeanButton'
import BaseModal from '../BaseModal'

import MissionIcons from './MissionItem/icons'
import MissionItem from './MissionItem'

const DailyMissionModal = ({onClose}) => {
  return (
    <BaseModal
      header={<BeanButton className="fixed-on-top">Daily Mission</BeanButton>}
      onClose={onClose}
      styleInside={{padding: '0 10px', margin: '15px'}}
    >
      <MissionItem title="Sign in" value={1} totalRequire={1} icon={MissionIcons.icon1} rewardValue={1} />
      <MissionItem title="Claim reward" value={100} totalRequire={100} icon={MissionIcons.icon3} rewardValue={6} />
      <MissionItem title="Play prediction game" value={2} totalRequire={3} icon={MissionIcons.icon3} rewardValue={3} />
      <MissionItem title="Win prediction game" value={1} totalRequire={4} icon={MissionIcons.icon2} rewardValue={2} />
      <MissionItem title="Play mini game" value={3} totalRequire={4} icon={MissionIcons.icon1} rewardValue={5} />
    </BaseModal>
  )
}
export default DailyMissionModal
