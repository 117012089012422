import { useWeb3React } from '@web3-react/core'
import CardValue from 'components/CardValue'
import GlobalModal from 'components2/static/GlobalModal'
import { ethers } from 'ethers'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { fetchGameSetting, fetchUserTokenData } from 'state/gameDb/actions'
import { getGameContract, getRewardGameContract } from 'utils/contractHelpers'

import { getUserGameDataSelector, getUserTokenDataSelector } from 'state/gameDb/reducer'
import styled from 'styled-components'
import ConvertModal from '../Modal/Convert'

function HeaderToken(props) {
  const { account, chainId } = useWeb3React()
  const dispatch = useAppDispatch()
  const userTokenData = useSelector(getUserTokenDataSelector)
  const { dptAmount, dgtAmount } = useSelector(getUserGameDataSelector)
  const balanceDpt = ethers.utils.formatEther(userTokenData?.dptBalance)
  const balanceDgt = ethers.utils.formatEther(userTokenData?.dgtBalance)

  useEffect(() => {
    dispatch(fetchGameSetting())
  }, [])

  useEffect(() => {
    const rewardGameContract = getRewardGameContract()
    const listenEvent = async () => {
      rewardGameContract.on('ShardSold', async () => {
        dispatch(fetchUserTokenData(account))
      })

      rewardGameContract.on('ExchangeTokenSecured', async () => {
        dispatch(fetchUserTokenData(account))
      })
    }
    if (account) {
      listenEvent()
      dispatch(fetchUserTokenData(account))
    }

    return () => {
      rewardGameContract.removeAllListeners('ShardSold')
      rewardGameContract.removeAllListeners('ExchangeTokenSecured')
    }
  }, [account])

  const handleClickConvert = () => {
    GlobalModal.show(<ConvertModal onClose={GlobalModal.hide} />)
  }

  return (
    <HeaderTokenStyled>
      <div className="widget__token-item">
        <img src="./images/homepage-game/icon-d-coin.png" alt="" />
        <span>
          <CardValue value={+balanceDgt || 0} decimals={2}></CardValue>
        </span>
      </div>

      <div className="widget__token-item">
        <img src="./images/play-now/token-dgt-new.png" alt="" />
        <span>
          <CardValue value={dgtAmount || 0} decimals={2}></CardValue>
        </span>
      </div>

      <div className="widget__token-item">
        <img src="./images/homepage-game/icon-token-dpt.png" alt="" />
        <span>
          <CardValue value={+balanceDpt || 0} decimals={2}></CardValue>
        </span>
      </div>

      <div className="widget__token-item">
        <img src="./images/play-now/token-dpt.png" alt="" />
        <span>
          <CardValue value={dptAmount || 0} decimals={2}></CardValue>
        </span>
      </div>

      {/* <div className="widget__token-item">
        <img src="./images/homepage-game/icon-key.png" alt="" />
        <span>0</span>
      </div> */}
      <img
        className="convert-icon"
        src="./images/homepage-game/convert/convert-icon.png"
        onClick={handleClickConvert}
        alt=""
      />
    </HeaderTokenStyled>
  )
}

const HeaderTokenStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  .widget__token-item {
    background: #0d5fabdb;
    border-radius: 40px;
    font-size: 1.125rem;
    color: #ffffff;
    padding: 5px 13px 5px 7px;

    span {
      text-align: right;
      padding-left: 16px;
      text-shadow: 2px 0 0 #000000, -2px 0 0 #000000, 0 2px 0 #000000, 0 -2px 0 #000000, 1px 1px #000000,
        -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000;
    }
    :nth-child(even) {
      span {
        filter: grayscale(1);
        color: #c9c9c9;
        text-shadow: 2px 0 0 #505050, -2px 0 0 #505050, 0 2px 0 #505050, 0 -2px 0 #505050, 1px 1px #505050,
          -1px -1px 0 #505050, 1px -1px 0 #505050, -1px 1px 0 #505050;
      }
    }
    img {
      width: 32px;
    }
  }

  .convert-icon {
    cursor: pointer;
    transition: ease all 0.5s;
    :active {
      transform: scale(0.8);
      transition: all 1s ease-out;
    }
    &:hover {
      filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
        drop-shadow(2px -2px 2px #ffffff);
    }
  }
`
export default HeaderToken
