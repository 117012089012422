import API from 'api'
import config from 'api/config'

export const login = async (data) => {
  const response = await API({
    url: `${config.API.AUTH_SERVICE}/sign`,
    data,
    method: 'POST',
  })
  return response
}
