import styled from 'styled-components'
import { AutoRenewIcon } from '@diviner-protocol/uikit'
import { usePredictionV2 } from 'state/predictionV2/selector'
import { ButtonDepositStyled } from './Styled'

const spinnerIcon = <AutoRenewIcon spin color="currentColor" />

function ButtonDepositV2({ title, onDeposit, isLoading }) {
  const { status } = usePredictionV2()
  return (
    <ButtonDepositStyled>
      <div
        className={`deposit ${
          isLoading || (status.isLocking && (title === 'Deposit' || title === 'Approve')) ? 'disabled' : ''
        }`} // code ngu hoc from Long
        onClick={isLoading || (status.isLocking && title === 'Deposit') ? null : onDeposit}
      >
        <span></span>
        <p>
          {title} {isLoading && spinnerIcon}
        </p>
        <span></span>
      </div>
    </ButtonDepositStyled>
  )
}

export default ButtonDepositV2
