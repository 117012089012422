import {createAsyncThunk} from '@reduxjs/toolkit'
import multicall, {Call} from 'utils/multicall'
import {BigNumber} from 'ethers'
import erc20ABI from 'config/abi/erc20.json'
import {getAddress, getTokenTransferProxyAddress, getDptAddress} from 'utils/addressHelpers'

export const fetchUserToken = createAsyncThunk('marketplace/fetchUserToken', async (account: string) => {
  try {
    const calls: Call[] = []

    calls.push({address: getDptAddress(), name: 'balanceOf', params: [account]})
    calls.push({address: getDptAddress(), name: 'allowance', params: [account, getTokenTransferProxyAddress()]})

    const [dptBalance, dptAllowance] = await multicall(erc20ABI, calls, {
      requireSuccess: false,
    })

    return {
      dpt: {
        balance: dptBalance.toString(),
        allowance: dptAllowance.toString(),
        isAllowance: BigNumber.from(dptAllowance.toString()).gt(BigNumber.from(0)),
      },
    }
  } catch (e) {
    console.log(e)
  }
})
