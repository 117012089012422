import React from 'react'
import styled from 'styled-components'

function ContentsJob({ level, maxReward, minReward }) {
  return (
    <ContentsJobStyled>
      <div className="content__job__style">
        <span>Estimate Reward</span>
        <span className="job__price">
          {parseFloat(minReward).toFixed(2)}{' '}
          <span style={{ fontFamily: 'sans-serif', padding: '0px 5px' }}>&#126;</span>{' '}
          {parseFloat(maxReward).toFixed(2)} DGT
        </span>
      </div>
      <div className="content__job__style">
        <span>Level</span>
        <span className="job__level">{level + 1}</span>
      </div>
    </ContentsJobStyled>
  )
}

const ContentsJobStyled = styled.div`
  .content__job__style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    &:nth-child(2) {
      padding-bottom: 0px;
    }
    span {
      font-size: 18px;
      color: #6a85a5;
      font-weight: 400;
    }
    .job__price {
      color: #55b3f8;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .job__level {
      color: #cf10ff;
      font-weight: 700;
    }
  }
`
export default ContentsJob
