export const AUTH_SLICE_NAME = 'auth'

interface ACTION {
  [key: string]: string
}
export const AUTH_ACTION: ACTION = {
  LOGIN: 'LOGIN',
}
Object.keys(AUTH_ACTION).forEach((key) => {
  AUTH_ACTION[key] = `${AUTH_SLICE_NAME}/${AUTH_ACTION[key]}`
})
