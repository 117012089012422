import chroma from 'chroma-js'
import styled from 'styled-components'

const BeanSVG = ({ className, backgroundColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="426"
    height="90"
    viewBox="0 0 426 90"
    fill="none"
    className={className}
    preserveAspectRatio="none"
  >
    <path
      d="M213 90H379.417C387.113 90 390.961 90 394.255 89.2989C405.144 86.9812 414.046 79.171 417.76 68.6762C418.883 65.5013 419.384 61.6861 420.385 54.0557C421.54 45.2466 422.118 40.842 421.713 37.0531C420.377 24.5721 411.616 13.8174 399.663 9.9856C396.034 8.82236 391.691 8.50401 383.005 7.86733C337.807 4.55418 266.032 -3.88731e-07 213 0C159.968 3.88731e-07 88.1933 4.55418 42.9946 7.86733C34.3088 8.50401 29.9659 8.82236 26.3372 9.9856C14.3841 13.8174 5.62302 24.5721 4.28735 37.0531C3.88187 40.842 4.45965 45.2466 5.61523 54.0557C6.61618 61.6861 7.11666 65.5013 8.24027 68.6762C11.9545 79.171 20.8562 86.9812 31.7449 89.2989C35.039 90 38.8869 90 46.5827 90H213Z"
      fill={chroma(backgroundColor).darken(0.6).desaturate(0.3)}
    />
    <path
      d="M213 85.5H381.534C387.419 85.5 390.361 85.5 392.904 85.0879C404.927 83.1395 414.801 74.535 418.375 62.8918C419.132 60.4291 419.534 57.5146 420.339 51.6857C421.279 44.8794 421.749 41.4762 421.612 38.5338C420.967 24.6297 411.146 12.5304 397.672 9.03967C394.82 8.30094 391.472 8.06646 384.774 7.59748C339.697 4.44098 266.73 -3.7415e-07 213 0C159.27 3.7415e-07 86.3027 4.44098 41.2257 7.59748C34.5284 8.06646 31.1798 8.30094 28.3283 9.03967C14.8542 12.5304 5.03312 24.6298 4.38795 38.5338C4.25142 41.4762 4.72134 44.8794 5.66118 51.6857C6.46606 57.5146 6.8685 60.4291 7.62459 62.8918C11.1992 74.535 21.0735 83.1395 33.0962 85.0879C35.6392 85.5 38.5813 85.5 44.4655 85.5H213Z"
      fill={backgroundColor}
    />
    <path
      d="M26.2527 42.7166C27.4055 41.0435 29.2246 39.984 31.2509 39.8343C50.4335 38.4165 152.057 31.0653 213 29.843C274.292 28.6137 365.995 33.7224 395.888 35.5268C401.626 35.8731 406.763 32.1396 408.254 26.5883C410.204 19.3233 405.15 12.0811 397.65 11.5141C363.296 8.91732 274.61 2.69995 213 2.69995C157.378 2.69995 79.6899 7.76734 39.834 10.6632C25.2182 11.7252 14 24.0972 14 38.7515C14 45.3468 22.5107 48.1476 26.2527 42.7166Z"
      fill={chroma(backgroundColor).brighten(0.6)}
    />
  </svg>
)

const BeanButton = ({ children, styledBT = null, backgroundColor = '#479FF2', ...otherProps }) => {
  return (
    <BeanButtonStyled {...otherProps} style={styledBT}>
      <BeanSVG className="bean-bg" backgroundColor={backgroundColor} />
      <span className="beam-btn-display">{children}</span>
    </BeanButtonStyled>
  )
}

export default BeanButton

const BeanButtonStyled = styled.button`
  background: none;
  outline: none;
  border: none;
  position: relative;

  font-family: Chalkboard SE;
  font-weight: bold;
  font-size: 36px;
  line-height: 51px;
  text-align: center;
  letter-spacing: -0.06em;
  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .bean-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .beam-btn-display {
    position: relative;
    z-index: 2;
    padding: 10px 15px;
    display: inline-block;
    transition: 0.5s ease-in-out;
  }
`
