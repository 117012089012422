import { createAsyncThunk } from '@reduxjs/toolkit'
import { mapKeys } from 'lodash'
import { SUBGRAPH_URL } from 'config/constants/migrate'
import { queryForTokenIds } from 'state/migrate/utils'
import { multicallContract } from 'state/migrate/utils/contractUtils'
import { fetchingInventory } from 'state/migrate/actions/actions'
import ERC721_ABI from 'config/abi/erc721.json'
import CharacterABI from 'config/abi/character.json'
import { civiliansSpec } from 'config/constants/civilians'
import { getDataByUrl } from '../utils/api'

export const fetchInventoryCharacter = createAsyncThunk(
  'migrate/fetchInventoryCharacter',
  async ({ account, chainId, boxContract, characterContract }: any, { dispatch }) => {
    try {
      const inventoryItems = []

      const tokenIds = await queryForTokenIds(account, SUBGRAPH_URL.BINANCE[chainId])

      const loopDatas = Array.from(tokenIds).map((item) => [item])

      const results = await multicallContract(boxContract[chainId], ERC721_ABI, 'tokenURI', loopDatas)

      const requests = []
      results.forEach((item) => {
        requests.push(getDataByUrl(item[0]))
      })

      const mappedCilivianSpecs = mapKeys(civiliansSpec, (key) => {
        return key.name.toLowerCase()
      })
      await Promise.all(requests).then(async (responses) => {
        const loopParamsMigrateStatus = Array.from(tokenIds).map((item) => [boxContract[chainId], item])
        const loopParamsOwnerOf = Array.from(tokenIds).map((item) => [item])

        const areAbleToMigrate = await multicallContract(
          characterContract[chainId],
          CharacterABI,
          'migrateStatus',
          loopParamsMigrateStatus,
        )

        const owners = await multicallContract(boxContract[chainId], CharacterABI, 'ownerOf', loopParamsOwnerOf)

        responses.forEach(({ data }, index) => {
          console.log('data : ', data)
          inventoryItems.push({
            image: mappedCilivianSpecs[data.name.toLowerCase()].img,
            name: mappedCilivianSpecs[data.name.toLowerCase()].name,
            nameInDecimal: mappedCilivianSpecs[data.name.toLowerCase()].nameInDecimal,
            rarity: mappedCilivianSpecs[data.name.toLowerCase()].rarity,
            tokenId: Array.from(tokenIds)[index],
            isAbleToMigrate: areAbleToMigrate[index][0],
            isMigrated: owners[index][0] === characterContract[chainId],
          })
        })
      })

      dispatch(fetchingInventory(false))
      return inventoryItems
    } catch (e) {
      console.log(e)
    }
  },
)
