import GlobalModal from 'components2/static/GlobalModal'
import {Contract} from 'ethers'
import {useCallback} from 'react'
import {useAppDispatch} from 'state'
import {calculateGasMargin} from 'utils'

export function useCallTransaction(
  contract: Contract,
  functionName: string,
  _before: any,
  _after: any,
  _errorHandler: any,
) {
  const onCall = useCallback(
    async (callParams: any[]) => {
      // const callData = getCallData.purchaseListing({tokenId, nftAddress: characterAddress})
      // dispatch(addTransaction({callData}))
      _before()

      const estimatedGas = await contract.estimateGas[functionName](...callParams).catch(() => {
        return contract.estimateGas[functionName](...callParams)
      })

      try {
        const tx = await contract[functionName](...callParams, {
          gasLimit: calculateGasMargin(estimatedGas),
        })
        // dispatch(updateTxHash({callData, txHash: tx.hash}))

        const wait = await tx.wait()

        // GlobalModal.show(
        //   <TransactionPopup message="Purchase listing successfully" onClose={GlobalModal.hide} txReceipt={wait} />,
        //   {position: 'upperRight', dimmerOpacity: '0'},
        // )

        // dispatch(clearTransaction({callData}))
        // dispatch(
        //   purchaseListing({
        //     ...civilian,
        //     seller: '',
        //     paymentToken: '',
        //     listingNft: '',
        //     id: '',
        //     price: '',
        //   }),
        // )

        _after()
      } catch (error) {
        console.error('Tx error : ', error)
        // if ((error as any).message === DENY_TX_MESSAGE) {
        //   dispatch(clearTransactionNotConfirmed({callData}))
        // } else {
        //   dispatch(errorTransaction({callData, message: 'Purchase listing fail'}))
        //   dispatch(clearTransaction({callData}))
        // }
        _errorHandler()
        throw error
      }
      /* eslint-disable react-hooks/exhaustive-deps */
    },
    [contract, functionName],
  )

  return onCall
}

export function useCallTransactionV2(
  contract: Contract,
  functionName: string,
  callParams: any[],
  _before: any,
  _after: any,
  _errorHandler: any,
) {
  const onCall = useCallback(async () => {
    // const callData = getCallData.purchaseListing({tokenId, nftAddress: characterAddress})
    // dispatch(addTransaction({callData}))
    _before()

    const estimatedGas = await contract.estimateGas[functionName](...callParams).catch(() => {
      return contract.estimateGas[functionName](...callParams)
    })

    try {
      const tx = await contract[functionName](...callParams, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      // dispatch(updateTxHash({callData, txHash: tx.hash}))

      const wait = await tx.wait()

      // GlobalModal.show(
      //   <TransactionPopup message="Purchase listing successfully" onClose={GlobalModal.hide} txReceipt={wait} />,
      //   {position: 'upperRight', dimmerOpacity: '0'},
      // )

      // dispatch(clearTransaction({callData}))
      // dispatch(
      //   purchaseListing({
      //     ...civilian,
      //     seller: '',
      //     paymentToken: '',
      //     listingNft: '',
      //     id: '',
      //     price: '',
      //   }),
      // )

      _after()
    } catch (error) {
      console.error('Tx error : ', error)
      // if ((error as any).message === DENY_TX_MESSAGE) {
      //   dispatch(clearTransactionNotConfirmed({callData}))
      // } else {
      //   dispatch(errorTransaction({callData, message: 'Purchase listing fail'}))
      //   dispatch(clearTransaction({callData}))
      // }
      _errorHandler()
      throw error
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [contract, functionName, callParams])

  return onCall
}
