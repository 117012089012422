import React from 'react'
import styled from 'styled-components'

function InventoryParameter({ id, civilian }) {
  return (
    <InventoryParameterStyled>
      <div className="parameter__id-star">
        <p>ID: {id}</p>
        <div className="star">
          {[...Array(Number(+civilian?.heroRarity + 1) || 0)].map(() => (
            <img src="./images/games/star.svg" alt="star" />
          ))}
        </div>
      </div>
      <div className="parameter__content">
        <div className="parameter__content-left">
          <PodiumStandItems>
            <p>Level:</p>
            <p>{civilian?.level}</p>
          </PodiumStandItems>
          <PodiumStandItems>
            <p>Rating:</p>
            <p>{+civilian?.heroRating + 1 || ''}</p>
          </PodiumStandItems>
          <PodiumStandItems>
            <p>EXP:</p>
            <p>0/80</p>
          </PodiumStandItems>
        </div>
        <div className="parameter__content-right">
          <PodiumStandItems>
            <div className="items">
              <img src="./images/games/hp.svg" alt="hp" />

              <span>Health:</span>
            </div>
            <span>{civilian?.attack}</span>
          </PodiumStandItems>
          <PodiumStandItems>
            <div className="items">
              <img src="./images/games/power.svg" alt="power" />

              <span>Strength:</span>
            </div>
            <span>{civilian?.defense}</span>
          </PodiumStandItems>
          <PodiumStandItems>
            <div className="items">
              <img src="./images/games/lucky.svg" alt="lucky" />

              <span>Lucky:</span>
            </div>
            <span>{civilian?.lucky}</span>
          </PodiumStandItems>
        </div>
      </div>
    </InventoryParameterStyled>
  )
}

const InventoryParameterStyled = styled.div`
  width: 500px;
  padding: 0px 32px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  p {
    margin: 0px;
  }
  .parameter__id-star {
    background: #152866b2;
    padding: 16px 32px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    p {
      margin-bottom: 0px;
      font-size: 20px;
    }
    .star {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        margin: 2px;
      }
    }
  }

  .parameter__content {
    background: #3159c2;
    padding: 10px 32px;
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`
const PodiumStandItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  padding: 5px 0px;
  .items {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 140px;
    span {
      padding-left: 5px;
    }
  }
`

export default InventoryParameter
