import { useAppDispatch } from 'state'
import { fetchGameUserData } from 'state/game'
import { setIsApprovalForAll, setIsFetchingApproval, stakeNft, withdrawNft } from '../state/stakingNft/actions'
import { useCallTransaction, useCallTransactionV2 } from './useCallFunction'
import { useCharacterContract, useStakingNftContract } from './useContract'

export const useApproveForAll = () => {
  const characterContract = useCharacterContract()
  const dispatch = useAppDispatch()

  const _before = () => {
    dispatch(setIsFetchingApproval(true))
  }

  const _after = () => {
    dispatch(setIsFetchingApproval(false))
    dispatch(setIsApprovalForAll(true))
  }

  const _errorHandler = () => {
    dispatch(setIsFetchingApproval(false))
  }

  return useCallTransaction(characterContract, 'setApprovalForAll', _before, _after, _errorHandler)
}

export const useStakeNft = ({ poolRating, poolRarity, tokenId, account }) => {
  const stakingNftContract = useStakingNftContract()
  const dispatch = useAppDispatch()

  const _after = () => {
    dispatch(stakeNft({ tokenId, poolRating: poolRating - 1, poolRarity: poolRarity - 1 }))
    dispatch(fetchGameUserData(account))
  }

  return useCallTransactionV2(
    stakingNftContract,
    'deposit',
    [poolRating - 1, poolRarity - 1, tokenId],
    () => {
      console.log('useCallTransactionV2')
    },
    _after,
    () => {
      console.log('useCallTransactionV2')
    },
  )
}

export const useWithdrawNft = (tokenId, account) => {
  const stakingNftContract = useStakingNftContract()
  const dispatch = useAppDispatch()

  const _after = () => {
    dispatch(withdrawNft(tokenId))
    dispatch(fetchGameUserData(account))
  }

  return useCallTransactionV2(
    stakingNftContract,
    'withdraw',
    [tokenId],
    () => {},
    _after,
    () => {},
  )
}

export const useClaimStakeNft = () => {
  const stakingNftContract = useStakingNftContract()

  return useCallTransaction(
    stakingNftContract,
    'claim',
    () => {},
    () => {},
    null,
  )
}
