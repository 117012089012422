import React from 'react'
import styled from 'styled-components'

function LeftParameter({ civilian }) {
  return (
    <LeftParameterStyled>
      <div className="parameter__id-star">
        <p>ID: {civilian?.tokenId}</p>
        <div className="star">
          {/* {!!civilian?.heroRarity === false
            ? ''
            : [...Array(civilian?.heroRarity + 1)].map((arr, index) => (
                <img key={index} src="./images/games/star.svg" alt="star" />
              ))} */}
        </div>
      </div>
      <div className="parameter__content">
        <div className="parameter__content-left">
          <PodiumStandItemsStyled>
            <p>Level:</p>
            <p>{civilian?.level}</p>
          </PodiumStandItemsStyled>
          <PodiumStandItemsStyled>
            <p>Rating:</p>
            <p>{civilian?.heroRating + 1 || 0}</p>
          </PodiumStandItemsStyled>
          <PodiumStandItemsStyled>
            <p>EXP:</p>
            <p>0/80</p>
          </PodiumStandItemsStyled>
        </div>
        <div className="parameter__content-right">
          <PodiumStandItemsStyled>
            <div className="items">
              <img src="./images/games/hp.svg" alt="hp" />

              <span>Health:</span>
            </div>
            <span>{civilian?.attack}</span>
          </PodiumStandItemsStyled>
          <PodiumStandItemsStyled>
            <div className="items">
              <img src="./images/games/power.svg" alt="power" />

              <span>Strength:</span>
            </div>
            <span>{civilian?.defense}</span>
          </PodiumStandItemsStyled>
          <PodiumStandItemsStyled>
            <div className="items">
              <img src="./images/games/lucky.svg" alt="lucky" />

              <span>Lucky:</span>
            </div>
            <span>{civilian?.lucky}</span>
          </PodiumStandItemsStyled>
        </div>
      </div>
    </LeftParameterStyled>
  )
}

const LeftParameterStyled = styled.div`
  width: 500px;
  padding: 0px 32px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  p {
    margin: 0px;
  }
  .parameter__id-star {
    background: #152866b2;
    padding: 0px 25px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    p {
      margin-bottom: 0px;
      font-size: 20px;
    }
    .star {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        margin: 2px;
      }
    }
  }

  .parameter__content {
    background: #3159c2;
    padding: 5px 25px;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`
const PodiumStandItemsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  padding: 5px 0px;
  .items {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 140px;
    span {
      padding-left: 5px;
    }
  }
`

export default LeftParameter
