import BeanButton from 'components2/Common/BeanButton'
import { calculateWinRate } from 'config'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getUserCiviliansSelector } from 'state/gameDb/reducer'
import styled from 'styled-components'
import ButtonPlayNow from 'views2/PlayNow/ButtonPlayNow'
import BaseModal from '../BaseModal'
import CivilianPlayNow from './Components/CivilianPlayNow'
import Energy from './Components/Energy'
import LeftParameter from './Components/LeftParameter'
import PlayNowPodiumStand from './Components/PlayNowPodiumStand'

function PlayNow({ onClose, subUrl }) {
  const { push } = useHistory()
  const [item, setItem] = useState(0)
  const onClickGetItem = (index) => {
    setItem(index)
  }
  const mapType = (url) => {
    switch (url) {
      case '/flip-cards':
        return 1
      case '/wheel':
        return 2
      case '/spinner':
        return 3
      default:
        return 1
    }
  }
  const civilians = useSelector(getUserCiviliansSelector)
  const selectedCivilians = civilians[item]
  const handlePlay = () => {
    onClose()
    push({
      pathname: subUrl,
      state: {
        tokenId: selectedCivilians?.tokenId,
      },
    })
  }
  return (
    <BaseModal
      header={
        <BeanButton styledBT={{ display: 'none' }} className="fixed-on-top">
          Select Civilian
        </BeanButton>
      }
      onClose={onClose}
      styleInside={{ top: '0%' }}
      style={{ height: 850, width: 1430 }}
    >
      <PlayNowStyled>
        <div className="play__now__left">
          <div className="play__now__left-energy">
            <PlayNowPodiumStand
              img={selectedCivilians?.img}
              bonusWinRate={selectedCivilians?.bonusWinRate}
              winRate={calculateWinRate(selectedCivilians, mapType(subUrl))}
            />
            {selectedCivilians && (
              <Energy lastFightTime={selectedCivilians?.lastFightTime} energy={selectedCivilians?.energy} />
            )}{' '}
          </div>

          <LeftParameter civilian={selectedCivilians} />
          <div>
            <ButtonPlayNow
              onClick={handlePlay}
              nameButton="Select"
              buttonProps={{ disabled: !selectedCivilians || selectedCivilians?.energy === 0 }}
            />
          </div>
        </div>
        <div className="play__now__right">
          {civilians.map((cvl, index) => (
            <CivilianPlayNow
              key={index}
              img={cvl.img}
              rating={cvl.heroRating}
              star={cvl.heroRarity}
              lv={cvl.level}
              className={item === index ? 'active ' : ''}
              onClick={() => onClickGetItem(index)}
            />
          ))}
        </div>
      </PlayNowStyled>
    </BaseModal>
  )
}

const PlayNowStyled = styled.div`
  background: url('./images/play-now/bg-modal.png') no-repeat;
  background-size: cover;
  height: 100%;
  padding: 63px 30px 16px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  .button__play-now {
    button {
      /* background: linear-gradient(180deg, #55b3f8 0%, #0094ff 56.04%, #007bd5 100%); */
      /* &:hover {
        background: linear-gradient(180deg, #55b3f8 0%, #0094ff 56.04%, #007bd5 100%);
        opacity: 0.8;
      } */
    }
  }
  .play__now__left {
    text-align: center;

    .play__now__left-energy {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .podium-stand {
      width: 390px;
      margin: 0 auto;
    }
    .button__play-now {
      padding: 0px;
      padding-top: 30px;
      button {
        width: 75%;
      }
    }
  }

  .play__now__right {
    width: 730px;
    overflow-y: scroll;
    border: solid 4px #054885;
    height: 700px;
    margin-top: 17px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    background: #0058a9;
    border-radius: 30px;
    padding: 40px 0px 40px 12px;
    grid-auto-rows: max-content;
    &::-webkit-scrollbar {
      width: 12px;
      background: unset;
      border-radius: 27px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 27px;
      background: #94bfff;
    }
  }
`
export default PlayNow
