import styled from 'styled-components'

export const MusicButtonStyled = styled.div`
  z-index: 100;
  img {
    transition: 0.5s ease all;
    cursor: pointer;
    max-width: 51px;

    &:hover {
      filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
        drop-shadow(2px -2px 2px #ffffff);
    }
  }
`
