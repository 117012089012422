import { IAPIEndpoint, IConfig } from './api.d'

const REACT_APP_API_URL = process.env.REACT_APP_RESTFUL_ENDPOINT || 'http://localhost:8080/v1'

const config: IConfig = {
  API: {
    AUTH_SERVICE: '/auth',
    USER_SERVICE: '/users',
    CIVILIAN_SERVICE: '/civilians',
    GAME_SERVICE: '/game',
    TYPES_SERVICE: '/game/gameTypesInfo',
  },
}

Object.keys(config.API).forEach((item) => {
  config.API[item as keyof IAPIEndpoint] = REACT_APP_API_URL + config.API[item as keyof IAPIEndpoint]
})

export default config
