import React from 'react'
import styled from 'styled-components'

function InventoryPodiumStand({ img, name }) {
  return (
    <InventoryPodiumStandStyled>
      <div className="inventory__name">
        <span>{name}</span>
      </div>
      <PodiumStandImgStyled>{img && <img src={img} alt="civilian" />}</PodiumStandImgStyled>
      <img className="launchers" src="./images/inventory-game/bg-podium-stand.png" alt="podium-stand." />
    </InventoryPodiumStandStyled>
  )
}

const InventoryPodiumStandStyled = styled.div`
  position: relative;

  .inventory__name {
    width: 100%;
    max-width: 400px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 20.83%,
      rgba(0, 0, 0, 0.6) 77.6%,
      rgba(0, 0, 0, 0) 100%
    );
    font-size: 28px;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
    position: relative;
  }

  .launchers {
    padding-left: 18px;
  }
`

const PodiumStandImgStyled = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  img {
    width: 600px;
    height: 600px;
  }
`
export default InventoryPodiumStand
