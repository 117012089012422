import styled from 'styled-components'
import { usePredictionV1 } from 'state/predictionV1/selector'
import { useEffect, useState } from 'react'
import { LOCK_DURATION } from 'config/constants/prediction'
import { getTimeRemainDraw } from 'utils/time'
import CardValue from 'components/CardValue'

JoinBottomModal.propTypes = {}

function JoinBottomModal({ value }) {
  const { status, withdrawFee } = usePredictionV1()
  const [timeCountdown, setTimeCountdown] = useState<number>(null)
  const [isSettling, setIsSettling] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<string>(null)

  useEffect(() => {
    const { lastUpdatedAt, isLocking } = status
    const now = Date.now()

    if (isLocking) {
      setTimeCountdown(lastUpdatedAt + LOCK_DURATION)
      if (now > lastUpdatedAt + LOCK_DURATION) {
        setIsSettling(true)
      }
    }
  })
  useEffect(() => {
    let interval
    if (timeCountdown !== null) {
      interval = setInterval(() => {
        setCountdown(getTimeRemainDraw(timeCountdown))
      }, 1000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [timeCountdown])

  return (
    <JoinTablesBottomStyled>
      <div className="total-effective">
        <p>Fee/Countdown</p>

        <p>{status.isLocking ? `${parseInt(withdrawFee) / 100}% / ${countdown ?? ''}` : '0%'}</p>
      </div>
      <div className="total-effective">
        <p>You&apos;ll Receive</p>
        <p>
          <CardValue
            value={status.isLocking ? (value * (100 - parseInt(withdrawFee) / 100)) / 100 : value}
            decimals={4}
          ></CardValue>
          {/* <CardValue value={status.isLocking ? value * 0.95 : value} decimals={4}></CardValue> */}
        </p>
      </div>
    </JoinTablesBottomStyled>
  )
}

const JoinTablesBottomStyled = styled.div`
  .total-effective {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p:nth-child(2) {
      padding: 5px 0px;
    }
  }
`

export default JoinBottomModal
