import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import predictionAbi from 'config/abi/prediction.json'
import multicall from 'utils/multicall'
import predictionConfig from 'config/constants/prediction'
import { getAddress, getPredictionAddressV1 } from 'utils/addressHelpers'
import { getChainId } from 'utils/web3React'

export const fetchPredictionUserAllowances = async (account: string) => {
  const predictionAddresses = getPredictionAddressV1()
  const allowances = []
  let calls = []

  for (let i = 0; i < predictionAddresses.length; i++) {
    calls = [
      ...calls,
      ...predictionConfig.map((prediction) => {
        const token = getAddress(prediction.lpAddresses)
        return { address: token, name: 'allowance', params: [account, predictionAddresses[i]] }
      }),
    ]
  }

  try {
    const rawLpAllowances = await multicall(erc20ABI, calls, { requireSuccess: false })
    const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
      return new BigNumber(lpBalance).toJSON()
    })
    for (let i = 0; i < predictionAddresses.length; i++) {
      allowances.push(
        parsedLpAllowances.slice(i * predictionConfig.length, i * predictionConfig.length + predictionConfig.length),
      )
    }
    return allowances
  } catch (error) {
    console.log('error allowances', error)
    return allowances
  }
}

export const fetchPredictionUserTokenBalances = async (account: string) => {
  const calls = predictionConfig.map((prediction) => {
    const lpContractAddress = getAddress(prediction.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })
  try {
    const rawTokenBalances = await multicall(erc20ABI, calls)
    const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
      return new BigNumber(tokenBalance).toJSON()
    })
    return parsedTokenBalances
  } catch (error) {
    console.log('fetch balance prediction error : ', error)
  }
}

export const fetchPredictionUserStakedLongBalances = async (account: string) => {
  const predictionAddresses = getPredictionAddressV1()
  const longBalances = []

  let calls = []

  for (let i = 0; i < predictionAddresses.length; i++) {
    calls = [
      ...calls,
      ...predictionConfig.map((prediction) => {
        return { address: predictionAddresses[i], name: 'userInfo', params: [prediction.pid, account] }
      }),
    ]
  }

  try {
    const rawStakedBalances = await multicall(predictionAbi, calls, { requireSuccess: false })
    const parsedLpAllowances = rawStakedBalances.map((stakedBalance) => {
      return new BigNumber(stakedBalance.longAmount._hex).toJSON()
    })
    for (let i = 0; i < predictionAddresses.length; i++) {
      longBalances.push(
        parsedLpAllowances.slice(i * predictionConfig.length, i * predictionConfig.length + predictionConfig.length),
      )
    }
    return longBalances
  } catch (error) {
    console.log('error long', error)
    return longBalances
  }
}

export const fetchPredictionUserStakedShortBalances = async (account: string) => {
  const predictionAddresses = getPredictionAddressV1()
  const shortBalances = []

  let calls = []

  for (let i = 0; i < predictionAddresses.length; i++) {
    calls = [
      ...calls,
      ...predictionConfig.map((prediction) => {
        return { address: predictionAddresses[i], name: 'userInfo', params: [prediction.pid, account] }
      }),
    ]
  }

  try {
    const rawStakedBalances = await multicall(predictionAbi, calls, { requireSuccess: false })
    const parsedLpAllowances = rawStakedBalances.map((stakedBalance) => {
      return new BigNumber(stakedBalance.shortAmount._hex).toJSON()
    })
    for (let i = 0; i < predictionAddresses.length; i++) {
      shortBalances.push(
        parsedLpAllowances.slice(i * predictionConfig.length, i * predictionConfig.length + predictionConfig.length),
      )
    }
    return shortBalances
  } catch (error) {
    console.log('error short', error)
    return shortBalances
  }
}

export const fetchPredictionUserEarnings = async (account: string) => {
  const chainId = await getChainId()
  const predictionAddresses = getPredictionAddressV1(chainId)

  let calls = []
  const userEarnings = []
  for (let i = 0; i < predictionAddresses.length; i++) {
    calls = [
      ...calls,
      ...predictionConfig.map((prediction) => {
        return { address: predictionAddresses[i], name: 'pendingAllReward', params: [account] }
      }),
    ]
  }

  try {
    const rawEarnings = await multicall(predictionAbi, calls)
    const parsedEarnings = rawEarnings.map((earnings) => {
      return {
        pendingDPT: earnings[0].toString(),
        pendingCake: earnings[1].toString(),
      }
    })
    for (let i = 0; i < predictionAddresses.length; i++) {
      userEarnings.push(
        parsedEarnings.slice(i * predictionConfig.length, i * predictionConfig.length + predictionConfig.length),
      )
    }
    return userEarnings
  } catch (error) {
    console.log('fetch pending reward error : ', error)
  }
}
