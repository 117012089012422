import axios from 'axios'
import { sortBy, concat } from 'lodash'

export const addressEqual = (a: string, b: string) => {
  return a.toLowerCase() === b.toLowerCase()
}

export const queryForTokenIds = async (account: string, subgraphUrl: string) => {
  const query = `
        query transfers {
            transfersTo : transfers (first: 1000,where: {
              to: "${account.toLowerCase()}"
            }) {
                id
                from
                to
                tokenId
            }
            transfersFrom : transfers (first: 1000,where: {
              from: "${account.toLowerCase()}"
            }) {
                id
                from
                to
                tokenId
            }
        }
    `
  const {
    data: {
      data: { transfersFrom, transfersTo },
    },
  } = await axios.post(subgraphUrl, { query })

  const tokenIds = new Set()

  const allTransfers = [...transfersTo, ...transfersFrom]

  allTransfers.forEach((element: { id: string; to: string; tokenId: any; from: string }) => {
    // console.log('element : ', element)
    if (addressEqual(element.to, account)) {
      tokenIds.add(element.tokenId)
    } else if (addressEqual(element.from, account)) {
      tokenIds.delete(element.tokenId)
    }
  })

  return tokenIds
}
