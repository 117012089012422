import {createSelector} from '@reduxjs/toolkit'
import {State} from 'state/types'

export const fetchingInventorySelector = (state: State) => state.migrate.fetchingInventory
export const inventoryCharactersSelector = (state: State) => state.migrate.characters
export const fetchingApprovalStatusSelector = (state: State) => state.migrate.approvalStatus

export const filteredInventoryChractersSelector = createSelector(
  inventoryCharactersSelector,
  (inventoryCharacters: any) => {
    return inventoryCharacters
  },
)
