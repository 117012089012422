import styled from 'styled-components'

const SVGNotchBG = ({ className, bgSVG }) => (
  <svg
    width="688"
    height="500"
    viewBox="0 0 688 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={className}
  >
    <path
      d="M646 500C669.196 500 688 481.196 688 458V41.9995C688 18.8038 669.196 1.43787e-05 646.001 6.57347e-07C626.878 -1.0655e-05 607.755 0.000127785 588.631 0.000127785C580.973 0.000127785 575.363 1.12707 570.527 8.0415C562.583 18.4163 549.274 33.2758 534.69 33.2758H344.201H153.713C132.583 33.2758 127.468 17.9559 119.955 8.0415C114.715 1.12707 109.508 0.000127785 101.447 0.000127785C81.6314 0.000127785 61.8156 0.00010724 41.9999 0.000109322C18.804 0.00011176 0 18.8041 0 42V458C0 481.196 18.804 500 42 500H646Z"
      fill={bgSVG}
    />
  </svg>
)

const WhiteBGWithNotch = ({ style, styleInside, bgSVG, children, ...props }) => {
  return (
    <BGWithNotchStyled style={style}>
      <SVGNotchBG className="notch-bg" bgSVG={bgSVG} />

      <div className="display-area" style={styleInside}>
        {children}
      </div>
    </BGWithNotchStyled>
  )
}

const BGWithNotchStyled = styled.div`
  position: relative;
  .notch-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .display-area {
    position: absolute;
    z-index: 2;
    top: 7%;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--background-style, linear-gradient(#78bcfc, #4f96da));
    }
  }
`
export default WhiteBGWithNotch
