import styled from 'styled-components'
import BeanButton from 'components2/Common/BeanButton'
import { connectorLocalStorageKey, ConnectorNames } from '@diviner-protocol/uikit'
import { connectors } from 'config/constants/wallets'
import useAuth from 'hooks/useAuth'
import BaseModal from '../BaseModal'

const ConnectWalletModal = ({ onClose = null }) => {
  const { login, logout } = useAuth()

  const handleLogin = (connectorId: ConnectorNames) => {
    onClose?.()
    login(connectorId)
    window.localStorage.setItem(connectorLocalStorageKey, connectorId)
  }

  return (
    <BaseModal
      header={<BeanButton className="fixed-on-top">Connect to a wallet</BeanButton>}
      onClose={onClose}
      styleInside={{ padding: '0 10px', margin: '15px' }}
      style={{ height: 355 }}
    >
      <BlockConnect>
        <div className="list-icon">
          {connectors.map((config) => (
            <div className="option" onClick={() => handleLogin(config.connectorId)}>
              <img src={`./images/connect/${config.icon}`} alt="" className="icon" />
              <p className="name">{config.title}</p>
            </div>
          ))}
        </div>

        <a href="/" className="link">
          <img src="./images/connect/icon-learn.png" alt="" className="icon" />
          <p>Learn how to connect</p>
        </a>
      </BlockConnect>
    </BaseModal>
  )
}

const BlockConnect = styled.div`
  height: 100%;
  width: 100%;
  background: #e7f8ff;
  border: 1.88462px solid rgba(162, 157, 154, 0.5);
  border-radius: 25px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  .link {
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #333333;
    p:hover {
      text-decoration: underline;
    }
    .icon {
      height: fit-content;
      margin-right: 10px;
      text-align: center;
    }
  }
  .list-icon {
    margin-top: 30px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    justify-items: center;
    .option {
      :hover {
        opacity: 0.8;
        transform: scale(0.9);
      }
      cursor: pointer;
      transition: ease all 0.5s;
      img {
        // width: 100%;
      }
      .name {
        text-align: center;
      }
    }
  }
`
export default ConnectWalletModal
