import styled from 'styled-components'
import ButtonClaim from './ButtonClaim'
import ProgressBar from './ProgressBar'

const MissionItem = ({title, icon, value, totalRequire, rewardValue}) => {
  return (
    <MissionItemStyled>
      <img className="icon" alt="mission-icon" src={icon} />
      <div className="block-center">
        <div className="title">{title}</div>
        <ProgressBar value={value} total={totalRequire} />
      </div>

      <ButtonClaim className="claim-btn" disabled={value < totalRequire} rewardValue={rewardValue}>
        Claim
      </ButtonClaim>
    </MissionItemStyled>
  )
}

const MissionItemStyled = styled.div`
  background: #e7f8ff;
  border: 4px solid rgba(162, 157, 154, 0.5);
  border-radius: 24px;
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;

  display: flex;

  .icon {
    border-radius: 12px;
    width: 68px;
    height: 68px;
  }

  .block-center {
    flex: 1;
    padding: 0 15px;
  }
  .title {
    font-family: Chalkboard SE;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -0.06em;
    padding-bottom: 11px;
    margin-top: 4px;

    color: #333333;
  }
  .progress-bar {
  }
`

export default MissionItem
