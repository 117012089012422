import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ModalGamePlayNow from 'components2/Modal/ModalGamePlayNow'
import GlobalModal from 'components2/static/GlobalModal'
import { useHistory, useLocation } from 'react-router-dom'

import { playGame } from 'api/actions/game'
import Back from 'views2/Games/components/Back'
import CardItem from './Components/CardItem'
function FlipCards() {
  const [activeCard, setActiveCard] = useState(null)
  const { state }: any = useLocation()
  const { push } = useHistory()
  const [rewards, setRewards] = useState([0, 0, 0])
  const [wasClicked, setWasClicked] = useState(false)
  const [flipRemainCards, setFlipRemainCards] = useState(false)

  const ClickCardItem = async (card) => {
    setWasClicked(true)
    const response = await playGame({
      tokenId: state?.tokenId?.toString(),
      type: 1,
    })
    setRewards([response?.userReward, response?.randomReward1, response?.randomReward2])
    setActiveCard(card)
    setTimeout(() => {
      setFlipRemainCards(true)
    }, 500)

    setTimeout(() => {
      GlobalModal.show(
        <ModalGamePlayNow userReward={response?.userReward} bonus={response?.bonus} onClose={handCloseModal} />,
        { onPressBackground: null },
      )
    }, 2000)
  }
  const handCloseModal = () => {
    GlobalModal.hide()
    push('/play-now')
  }
  useEffect(() => {
    if (!state?.tokenId) {
      push('/play-now')
    }
  }, [state])
  const updatedRewards = [...rewards]
  return (
    <FlipCardsStyled className={`${wasClicked ? 'clicked' : ''}`}>
      <Back to="/play-now" />
      {[...Array(3)].map((chest, index) => (
        <CardItem
          price={activeCard === index ? updatedRewards.shift() : updatedRewards.pop()}
          className={activeCard === index || flipRemainCards ? 'active' : 'none'}
          onClick={() => !wasClicked && ClickCardItem(index)}
          key={index}
          index={index}
        />
      ))}
    </FlipCardsStyled>
  )
}

const FlipCardsStyled = styled.div`
  background: url('./images/play-now/bg-flip-cards.svg') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  &.clicked {
    z-index: -1;
  }
  div {
  }
`
export default FlipCards
