import React from 'react'
import styled from 'styled-components'

function CustomButton({ name, onClick = null, isBuy = false, disabled = true }) {
  return (
    <ButtonChestStyled disabled={disabled} className="ButtonChest" onClick={onClick}>
      <div className="buttonChest__content">
        {isBuy && <img src="./images/fileDiviner/frameprice.png" alt="PriceDPT.png" />}
        <p>{name}</p>
      </div>
    </ButtonChestStyled>
  )
}

export const ButtonChestStyled = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: ease all 0.5s;
  background: #2f93f0;
  width: 210px;
  height: 56px;
  margin: 0 auto;
  border-radius: 55px;
  position: relative;
  filter: ${(props) => (props.disabled ? 'grayscale(1)' : 'grayscale(0)')};

  &::before {
    position: absolute;
    content: '';
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background: #7ed5ff;
    width: 170px;
    height: 19px;
    top: 4px;
    border-radius: 162px;
    text-align: center;
    z-index: 2;
  }
  p {
    margin: 0;
    padding-left: 10px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
  }

  &::after {
    position: absolute;
    content: '';
    background: #68c9ff;
    width: 200px;
    height: 50px;
    top: 0;
    border-radius: 162px;
    z-index: 1;
  }

  &:hover {
    transform: scale(1.1);
  }

  .buttonChest__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    padding-bottom: 8px;
  }
`
export default CustomButton
