import React from 'react'
import { HistoryOutlined } from '@ant-design/icons'
import { HistoryStyled } from './styled'

function History({ onClick }) {
  return (
    <HistoryStyled>
      <img src="./images/home/history-icon.png" alt="history.png" onClick={onClick} />
    </HistoryStyled>
  )
}

export default History
