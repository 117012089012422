import styled from 'styled-components'
import { AutoRenewIcon } from '@diviner-protocol/uikit'
import { usePredictionV1 } from 'state/predictionV1/selector'
import { ButtonDepositStyled } from './Styled'

const spinnerIcon = <AutoRenewIcon spin color="currentColor" />

function ButtonDeposit({ title, onDeposit, isLoading }) {
  const { status } = usePredictionV1()
  return (
    <ButtonDepositStyled>
      <div
        className={`deposit ${
          isLoading || (status.isLocking && (title === 'Deposit' || title === 'Approve')) ? 'disabled' : ''
        }`}
        onClick={isLoading || (status.isLocking && title === 'Deposit') ? null : onDeposit}
      >
        <span></span>
        <p>
          {title} {isLoading && spinnerIcon}
        </p>
        <span></span>
      </div>
    </ButtonDepositStyled>
  )
}

export default ButtonDeposit
