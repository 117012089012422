import React from 'react'
import { Tooltip } from 'antd'
import styled from 'styled-components'

function PlayNowPodiumStand({ img, bonusWinRate = 0, winRate = 0 }) {
  return (
    <PlayNowPodiumStandStyled>
      <div className="play-now__name">
        <p>
          {img && (
            <>
              Win rate: <span>{winRate}%</span>{' '}
              <span className="play-now__name__right">
                {`${bonusWinRate !== 0 ? `+${bonusWinRate}%` : ''}`}{' '}
                <Tooltip
                  placement="bottomLeft"
                  title={
                    <div style={{ display: 'block' }}>
                      <p style={{ width: '100%' }}>Civilians NFT get bonus 1% win rate after each lose game.</p>
                      <p style={{ width: '100%' }}>When Civilians NFT win a game, this bonus reset to 0.</p>
                    </div>
                  }
                  overlayClassName="civilian__tooltip__hover"
                >
                  <img className="tooltip__open" src="./images/fileDiviner/group2.png" alt="Group2.png" />
                </Tooltip>
              </span>{' '}
            </>
          )}
        </p>
      </div>
      <PodiumStandImgStyled>{img && <img src={img} alt="civilian" />}</PodiumStandImgStyled>
      <img className="launchers" src="./images/inventory-game/bg-podium-stand.png" alt="podium-stand." />
    </PlayNowPodiumStandStyled>
  )
}

const PlayNowPodiumStandStyled = styled.div`
  position: relative;

  .play-now__name {
    width: 100%;
    max-width: 400px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 20.83%,
      rgba(0, 0, 0, 0.6) 77.6%,
      rgba(0, 0, 0, 0) 100%
    ); */
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
    position: relative;
    text-shadow: 2px 0 0 #000000, -2px 0 0 #000000, 0 2px 0 #000000, 0 -2px 0 #000000, 1px 1px #000000,
      -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000;
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;

      span {
        padding: 0px 5px;
      }
    }
    span {
      font-size: 30px;
    }
    .play-now__name__right {
      font-size: 26px;
      color: #a0fca8;
      .tooltip__open {
        cursor: pointer;
      }
    }
  }

  .launchers {
    width: 370px;
  }
`

const PodiumStandImgStyled = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0%);
  img {
    width: 300px;
  }
`
export default PlayNowPodiumStand
