import { createAsyncThunk } from '@reduxjs/toolkit'
import { PREDICTION_ACTION } from './constants'
import fetchPredictionsV2 from './fetchPrediction'
import {
  fetchPredictionUserAllowances,
  fetchPredictionUserEarnings,
  fetchPredictionUserStakedLongBalances,
  fetchPredictionUserStakedShortBalances,
  fetchPredictionUserTokenBalances,
} from './fetchPredictionUser'

const { FETCH_PREDICTION_USER_DATA, FETCH_PREDICTION_POOL } = PREDICTION_ACTION

export const fetchPredictionUserDataV2 = createAsyncThunk<any, any>(FETCH_PREDICTION_USER_DATA, async (account) => {
  const fetchUserPredictionAllowances = fetchPredictionUserAllowances(account)
  const fetchUserPredictionTokenBalances = fetchPredictionUserTokenBalances(account)
  const fetchUserPredictionStakedLongBalances = fetchPredictionUserStakedLongBalances(account)
  const fetchUserPredictionStakedShortBalances = fetchPredictionUserStakedShortBalances(account)
  const fetchUserPredictionPendingReward = fetchPredictionUserEarnings(account)
  const [
    userPredictionAllowances,
    userPredictionTokenBalances,
    userPredictionStakedLongBalances,
    userPredictionStakedShortBalances,
    userPredictionPendingReward,
  ] = await Promise.all([
    fetchUserPredictionAllowances,
    fetchUserPredictionTokenBalances,
    fetchUserPredictionStakedLongBalances,
    fetchUserPredictionStakedShortBalances,
    fetchUserPredictionPendingReward,
  ])
  if (userPredictionAllowances?.length > 0) {
    const listArrayOfUserData = []
    for (let i = 0; i < userPredictionAllowances.length; i++) {
      const arrayOfUserData = userPredictionAllowances[i].map((item, index) => {
        return {
          index,
          allowance: userPredictionAllowances[i][index],
          balances: userPredictionTokenBalances[index],
          longAmount: userPredictionStakedLongBalances[i][index],
          shortAmount: userPredictionStakedShortBalances[i][index],
          pendingDPT: userPredictionPendingReward[i][index].pendingDPT,
          pendingCake: userPredictionPendingReward[i][index].pendingCake,
        }
      })
      listArrayOfUserData.push(arrayOfUserData)
    }
    return listArrayOfUserData
  }
})

export const fetchPredictionPoolV2 = createAsyncThunk(FETCH_PREDICTION_POOL, async () => {
  const data = await fetchPredictionsV2()

  return data
})
