import 'antd/dist/antd.css'
// import SpinnerMachine from 'components2/SpinnerMachine/SpinnerMachine'
import HomeDiviner from 'views2/Home'
// import ChatBoard from './components2/ChatBoard'

const Pages = (props) => {
  return (
    <>
      {/* <ChatBoard /> */}
      <HomeDiviner />
      {/* <SpinnerMachine /> */}
    </>
  )
}

export default Pages
