import styled from 'styled-components'

export const PredictionRowInfoStyled = styled.div`
    .LearnMore{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom:10px;
      a{
        font-size:20px;
        color:#18AFF7;
        padding-left:5px;
        font-weight:400;
        transition: ease all 0.5s;
        &:hover{
          opacity:0.8;
        }
      }
    }



    p {
      margin: 0;
    }

    .ExperienceIconHome{
      position: absolute;
      top: -80px;
      left: 37%;
          img{
          border: solid 15px #79befe;
          width: 80%;
          border-radius:50%;
        }
      }
    }

    .experience-table{
      padding:1em;
      p {
        font-size: 25px;
        font-weight: 400;
      }
      span{
        font-weight: 700;
        color: #00948a;
      }

    

    .jointables-top{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        color: #111111;
        font-size: 26px;
        font-weight: 700;
      }

    }
      .market-trade{
        font-size: 20px;
        display: flex;
        justify-content: center;

        .market-trade-content{
          position: relative;
          background: #f4f4f4;
          border-radius: 15px;
          width: 240px;
          margin: 13px;
          padding: 10px 0px;

         
          p {
            font-weight: 700;
          }

          p:nth-child(1) {
            font-size: 16px;
            color: #555555;
          }
          p:nth-child(2) {
            font-size: 24px;
          }
          img {
            position: absolute;
            right: 0%;
            top: 0%;
            width:25px;
          }

        }
      }
    
`

export const ExperiencePriceStyled = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;

  .ExperiencePriceTitle {
    P {
      text-transform: uppercase;
      color: #333333;
      font-size: 32px;
      font-weight: 700;
      padding-right: 5px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ImgPrice img {
    width: 50px;
    height: 50px;
  }
`

export const ClaimRewardStyled = styled.button`
  padding: 10px 0px;
  transition: ease all 0.5s;
  background: #68c9ff;
  padding: 10px 20px;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  border-radius: 24px;
  border-bottom: #18aff7 solid 5px;
  border-left: #18aff7 2px solid;
  border-right: #18aff7 2px solid;
  border-color: #18aff7;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

export const APYStyled = styled.div`
  .price-apy {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      padding: 0px 3px;
      color: #333333;
      font-weight: 700;
      font-size: 20px;
    }
    img {
      width: 20px;
    }
  }

  .pricel-apy {
    span {
      color: #0dca41;
    }
  }

  .apy {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
  }

  .experience-apy {
    position: relative;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      border-radius: 10px;
      height: 10px;
    }
    span:nth-child(1) {
      position: absolute;
      background: #0dca41;
      left: 0;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    span:nth-child(2) {
      position: absolute;
      background: #fc584f;
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .pricer-apy {
    padding: 0px 15px;
    padding-left: 0px;
    span {
      color: #fc584f;
    }
  }
`
