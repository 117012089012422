import React from 'react'
import styled from 'styled-components'
import CivilianStar from './CivilianStar'

function CivilianPodiumStand({selectedItems, star, img, name}) {
  return (
    <CivilianPodiumStandStyled>
      {selectedItems === null ? (
        <p className="select__title">Select Civilian</p>
      ) : (
        <div className="podium-stand__civilian">
          <p className="civilian__name">{name}</p>
          <CivilianStar star={star} />
          <img src={img} alt="civilian" className="civilian__img" />
        </div>
      )}

      <img src="./images/civilian-fusion/podium-stand.png" alt="podium-stand.png" />
    </CivilianPodiumStandStyled>
  )
}

const CivilianPodiumStandStyled = styled.div`
  width: 560px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .podium-stand__civilian {
    width: 500px;
    height: 545px;
    position: absolute;
    top: -62px;
    transform: translate(0px, -80%);
    .civilian__img {
      width: 500px;
      height: 500px;
    }
    .item__star {
      img {
        width: 30px;
      }
    }
    .civilian__name {
      color: #fff;
      font-size: 26px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      text-transform: capitalize;
    }
  }
  .select__title {
    color: #94bfff;
    font-weight: 400;
    font-size: 28px;
    position: absolute;
    top: -36%;
  }
`

export default CivilianPodiumStand
