import { Row, Col } from 'antd'
import contractAddress from 'config/constants/contracts'
import { useActiveWeb3React } from 'hooks'
import { useCharacterContract, useExternalNFTContract } from 'hooks/useContract'
import { isEmpty } from 'lodash'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import {
  doMigrate as doMigrateAction,
  fetchingApproveStatus,
  setApproveForAll,
} from 'state/migrate/actions/characterActions'
import { fetchInventoryCharacter } from 'state/migrate/actions/inventoryAction'
import { fetchingApprovalStatusSelector, filteredInventoryChractersSelector } from 'state/migrate/selectors'
import { CharacterSpec } from 'state/migrate/types'
import styled from 'styled-components'
// import HeaderToken from 'views2/HomepageGame/Components/HeaderToken'
import ButtonGame from 'views2/Games/ButtonGame'
import Back from '../components/Back'
import CivilianItem from '../components/CivilianItem'
import TitleCivilian from '../components/TitleCivilian'

function Migrate() {
  const [arrItemSelected, setArrayItemSelected] = useState([])
  const dispatch = useAppDispatch()
  const { account, chainId } = useActiveWeb3React()

  const characterContract = useCharacterContract()
  const externalNFTContract = useExternalNFTContract(contractAddress.binanceMigrateBox)

  const filteredInventoryCharacters = useSelector(filteredInventoryChractersSelector) as CharacterSpec[]
  const approvalStatus = useSelector(fetchingApprovalStatusSelector)

  const CheckActiveItem = (value) => {
    if (arrItemSelected.includes(value)) {
      const newArrItemSelected = arrItemSelected.filter((item) => item !== value)
      setArrayItemSelected(newArrItemSelected)
    } else setArrayItemSelected(arrItemSelected.concat(value))
  }

  const doMigrate = async () => {
    if (account) {
      const selectedItems = arrItemSelected.map((el) => {
        return filteredInventoryCharacters[el]
      })

      const externalContractAddresses = []
      selectedItems.forEach((el) => externalContractAddresses.push(contractAddress.binanceMigrateBox[chainId]))

      dispatch(
        doMigrateAction({
          contract: characterContract,
          externalContractAddresses,
          selectedItems,
        }),
      )
    }
  }

  const doApprove = async () => {
    if (account) {
      dispatch(
        setApproveForAll({
          chainId,
          contract: externalNFTContract,
        }),
      )
    }
  }

  useEffect(() => {
    if (account) {
      dispatch(
        fetchingApproveStatus({
          account,
          chainId,
          contract: externalNFTContract,
        }),
      )
    }
  }, [account, externalNFTContract])

  useEffect(() => {
    const fetchingInventory = () => {
      dispatch(
        fetchInventoryCharacter({
          account,
          chainId,
          boxContract: contractAddress.binanceMigrateBox,
          characterContract: contractAddress.character,
        }),
      )
    }
    if (account) {
      const timerId = setTimeout(() => fetchingInventory, 10000)
      fetchingInventory()
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [account])

  return (
    <InventoryStyled>
      <Back />
      <TitleCivilian title="Convert Binance NFT" />

      <div className="inventory__content">
        <div className="inventory__content__main">
          <InventoryTabStyled>
            <div className="inventory__tab--item">
              <Row>
                {Object.values(filteredInventoryCharacters).length > 0
                  ? Object.values(filteredInventoryCharacters).map((civilian, index) => (
                      <Col xl={3}>
                        <CivilianItem
                          isDisabled={!civilian.isAbleToMigrate || civilian.isMigrated}
                          name={civilian.name}
                          star={null}
                          img={civilian.image}
                          lv="1"
                          key={index}
                          rating={0}
                          onClick={() => {
                            if (civilian.isAbleToMigrate && !civilian.isMigrated) {
                              CheckActiveItem(civilian.tokenId)
                            }
                          }}
                          className={
                            arrItemSelected.includes(civilian.tokenId)
                              ? 'civilianItem__style active'
                              : 'civilianItem__style'
                          }
                        />
                      </Col>
                    ))
                  : null}
              </Row>
            </div>
          </InventoryTabStyled>

          {chainId &&
          !isEmpty(approvalStatus) &&
          approvalStatus[contractAddress.binanceMigrateBox[chainId]].isApproveForAll ? (
            <ButtonGame onClick={doMigrate} className="button__fusion" name="Convert" />
          ) : (
            <ButtonGame onClick={doApprove} className="button__fusion" name="Approve for all" />
          )}
        </div>
      </div>
    </InventoryStyled>
  )
}

const InventoryStyled = styled.div`
  background: url('./images/games/bg-inventory.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 1080px;
  width: 1920px;
  padding: 0 15px;

  .header__token__styled {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .inventory__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__main {
      width: 100%;
      margin: 0 auto;

      .button__fusion {
        filter: drop-shadow(2px 4px 6px black);
      }
    }
  }
`

const InventoryTabStyled = styled.div`
  width: 1568px;
  height: 782px;
  padding: 15px;
  margin: 0 auto;
  background: url('./images/games/bg-migrate.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 130px;
  margin-bottom: 30px;

  .inventory__tab--item {
    overflow-y: scroll;
    width: 100%;
    padding: 40px;
    height: 740px;
    &::-webkit-scrollbar {
      width: 12px;
      background: unset;
      border-radius: 27px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 27px;
      background: #94bfff;
    }
  }
  .item__star {
    display: none !important;
  }
`

export default Migrate
