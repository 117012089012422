import multicall, {Call} from 'utils/multicall'
import gameConfig from 'config/constants/game'
import {ethers, BigNumber} from 'ethers'
import tokens from 'config/constants/tokens'

export const _getCiviliansByIds = async (
  contractAddress: string,
  contractAbi: any,
  functionCall: string,
  civilianIds: any,
  activated: boolean,
) => {
  try {
    if (civilianIds.length <= 0) return []

    const calls: Call[] = civilianIds.map((token) => {
      const call: Call = {
        address: contractAddress,
        name: functionCall,
        params: [BigNumber.from(token)],
      }
      return call
    })

    const results = await multicall([...contractAbi], calls, {requireSuccess: false})

    return results
      .filter((result) => result[0].tokenId && parseInt(result[0].tokenId) !== 0)
      .map((result) => ({
        _id: `${contractAddress}_${result[0].tokenId.toString()}`,
        nftAddress: contractAddress,
        tokenId: result[0].tokenId?.toString(),
        heroRarity: result[0].heroRarity?.toString(),
        heroName: result[0].heroName?.toString(),
        attack: result[0].attack?.toString(),
        defense: result[0].defense?.toString(),
        speed: result[0].speed?.toString(),
        energy: result[0].energy?.toString(),
        level: result[0].level?.toString(),
        ...gameConfig[result[0].heroRarity?.toNumber()][result[0].heroName?.toNumber()],
        activated,
      }))
  } catch (e) {
    return []
  }
}

export const _getListingDataByIdentifiers = async (
  contractAddress: string,
  contractAbi: any,
  functionCall: string,
  identifiers: any,
) => {
  try {
    if (identifiers.length <= 0) return []

    const calls: Call[] = identifiers.map((token) => {
      const call: Call = {
        address: contractAddress,
        name: functionCall,
        params: [token],
      }
      return call
    })

    const results = await multicall([...contractAbi], calls, {requireSuccess: false})

    return results.map((result) => ({
      _id: `${result[0].listingNft}_${result[0].id?.toString()}`,
      seller: result[0].seller?.toString(),
      paymentToken: result[0].paymentToken?.toString(),
      listingNft: result[0].listingNft?.toString(),
      id: result[0].id?.toString(),
      price: ethers.utils.formatEther(result[0].price?.toString()),
    }))
  } catch (e) {
    return []
  }
}
