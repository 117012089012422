import {useWeb3React} from '@web3-react/core'
import React, {useState} from 'react'
import styled from 'styled-components'
import {MAX_ENERGY} from '../../../../../config/constants/stakingNft'
import {useClaimStakeNft, useWithdrawNft} from '../../../../../hooks/useStakingNft'
import CivilianItem from '../../Components/CivilianItem'
import ButtonStaking from '../ButtonStaking'

function StakingNftItem({civilian}) {
  const {account} = useWeb3React()
  const onClaim = useClaimStakeNft()
  const onWithdraw = useWithdrawNft(civilian.tokenId, account)
  const [isDisabled, setIsDisabled] = useState(false)

  const percentEnergy1 = civilian.stamina !== 0 && Math.floor((civilian.stamina / MAX_ENERGY) * 100)

  return (
    <StakingNftItemStyled>
      <CivilianItem civilian={civilian} percentEnergy={percentEnergy1} />
      <div className="staking__nft__character top">
        <span>Working power</span>
        <span className="right">{percentEnergy1}%</span>
      </div>
      <div className="staking__nft__character">
        <span>DPT earning</span>
        <span className="right" style={{color: '#55B3F8'}}>
          {civilian.pendingReward}
        </span>
      </div>
      <div className="button__staking__styled">
        <div className="button__staking-nft">
          <ButtonStaking
            nameButton="Claim"
            onClick={async () => {
              try {
                setIsDisabled(true)
                await onClaim([civilian.tokenId])
              } finally {
                setIsDisabled(false)
              }
            }}
            bgStyled={{background: '#55B3F8'}}
            disabled={isDisabled}
          />
        </div>
        <ButtonStaking
          nameButton="Cancel"
          onClick={async () => {
            try {
              setIsDisabled(true)
              await onWithdraw()
            } finally {
              setIsDisabled(false)
            }
          }}
          bgStyled={{background: '#C73737'}}
          disabled={isDisabled}
        />
      </div>
    </StakingNftItemStyled>
  )
}

const StakingNftItemStyled = styled.div`
  position: relative;
  width: 288px;
  height: 584px;
  padding: 20px;
  background: linear-gradient(180deg, #0f3072 0%, rgba(0, 0, 2, 0.6) 100%);
  border-radius: 12px;
  margin: 32px 16px 0px 16px;
  .button__staking-nft {
    margin-bottom: 16px;
    margin-top: 12px;
  }
  .civilian__job {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
  }

  .civilian__img {
    width: 220px;
    height: 220px;
  }
  .staking__nft__character {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #999999;
    font-weight: 400;
    margin-bottom: 8px;
    .right {
      font-weight: 700;
      color: #fff;
    }
  }
  .staking__nft__character.top {
    margin-top: 15px;
  }

  .staking__full-energy {
    padding: 41px 0px 84px 0px;
    p {
      margin: 0;
      color: #ffff;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
    }
  }

  .button__staking__styled {
    position: absolute;
    bottom: 20px;
    width: 248px;
  }
`

export default StakingNftItem
