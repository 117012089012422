const correctPositions = [1, 3, 5, 7, 9]
export const wrongPositions = [0, 2, 4, 6, 8]

function spinList(arrPrice) {
  const listSpin = [
    {
      BgWheel: 'linear-gradient(91.23deg, #F01111 18.05%, #FF9254 97.97%)',
      price: null,
      iconPrice: null,
      isWinList: false,
    },
    {
      BgWheel: 'linear-gradient(180deg, #79BEFE 0%, #437DC1 100%)',
      price: arrPrice ? arrPrice[0] : '?',
      iconPrice: './images/play-now/token.svg',
      isWinList: true,
    },
    {
      BgWheel: 'linear-gradient(91.23deg, #F01111 18.05%, #FF9254 97.97%)',
      price: null,
      iconPrice: null,
      isWinList: false,
    },
    {
      BgWheel: 'linear-gradient(180deg, #79BEFE 0%, #437DC1 100%)',
      price: arrPrice ? arrPrice[1] : '?',
      iconPrice: './images/play-now/token.svg',
      isWinList: true,
    },
    {
      BgWheel: 'linear-gradient(91.23deg, #F01111 18.05%, #FF9254 97.97%)',
      price: null,
      iconPrice: null,
      isWinList: false,
    },
    {
      BgWheel: 'linear-gradient(180deg, #79BEFE 0%, #437DC1 100%)',
      price: arrPrice ? arrPrice[2] : '?',
      iconPrice: './images/play-now/token.svg',
      isWinList: true,
    },
    {
      BgWheel: 'linear-gradient(91.23deg, #F01111 18.05%, #FF9254 97.97%)',
      price: null,
      iconPrice: null,
      isWinList: false,
    },
    {
      BgWheel: 'linear-gradient(180deg, #79BEFE 0%, #437DC1 100%)',
      price: arrPrice ? arrPrice[3] : '?',
      iconPrice: './images/play-now/token.svg',
      isWinList: true,
    },
    {
      BgWheel: 'linear-gradient(91.23deg, #F01111 18.05%, #FF9254 97.97%)',
      price: null,
      iconPrice: null,
      isWinList: false,
    },
    {
      BgWheel: 'linear-gradient(180deg, #79BEFE 0%, #437DC1 100%)',
      price: arrPrice ? arrPrice[4] : '?',
      iconPrice: './images/play-now/token.svg',
      isWinList: true,
    },
  ]

  if (arrPrice) {
    const rand = Math.floor(Math.random() * correctPositions.length)
    const correctPosition = correctPositions[rand]
    console.log('correctPosition', correctPosition)
    listSpin[correctPosition].price = arrPrice[0]
    return { listSpin, correctPosition }
  }

  return { listSpin }
}

export default spinList
