import chestMarketAbi from 'config/abi/chestMarket.json'
import chestsConfig from 'config/constants/buyChest'
import {getChestMarketAddress} from 'utils/addressHelpers'
import multicall from 'utils/multicall'

export const fetchChestData = async () => {
  try {
    const calls = []
    for (let i = 0; i < chestsConfig.length; i++)
      calls.push({address: getChestMarketAddress(), name: 'chests', params: [i]})
    const rawChests = await multicall(chestMarketAbi, calls, {
      requireSuccess: false,
    })
    const chests = rawChests.map((chest, index) => {
      return {
        dptToBuy: chest.dptToBuy.toString(),
        remainChest: chest.remainChest.toString(),
      }
    })
    return chests
  } catch (error) {
    console.log('error', error)
  }
}
