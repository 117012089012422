/* eslint-disable */
import { simpleRpcProvider } from 'utils/providers'
import ethers from 'ethers'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AUTH_ACTION } from './constants'
import { login } from 'api/actions/auth'

const { LOGIN } = AUTH_ACTION

interface ILoginActionData {
  publicAddress
}

export const loginAction = createAsyncThunk<any, any>(LOGIN, async ({ callback, ...data }) => {
  try {
    const response = await login(data)
    callback?.()
    console.log('response', response)
    return {
      ...response.tokens,
    }
  } catch (error) {
    return error
  }
})
