import {createAsyncThunk} from '@reduxjs/toolkit'
import contractAddress from 'config/constants/contracts'

export const fetchingApproveStatus = createAsyncThunk(
  'migrate/fetchingApprovalStatus',
  async ({account, chainId, contract}: any) => {
    try {
      const isApproveForAll = await contract.isApprovedForAll(account, contractAddress.character[chainId])
      return {
        externalContract: contract.address,
        isApproveForAll,
      }
    } catch (e) {
      console.log(e)
    }
  },
)

export const setApproveForAll = createAsyncThunk('migrate/setApproveForAll', async ({chainId, contract}: any) => {
  try {
    const approveForAllTx = await contract.setApprovalForAll(contractAddress.character[chainId], true)
    await approveForAllTx.wait()
    return {
      externalContract: contract.address,
      isApproveForAll: true,
    }
  } catch (e) {
    console.log(e)
  }
})

export const doMigrate = createAsyncThunk(
  'migrate/doMigrate',
  async ({contract, externalContractAddresses, selectedItems}: any, {dispatch, getState}) => {
    try {
      if (selectedItems.length > 0 && selectedItems.length === externalContractAddresses.length) {
        const tokenIds = []
        selectedItems.forEach((element) => {
          tokenIds.push(element.tokenId)
        })

        const doMigrateTx = await contract.migrateBatch(externalContractAddresses, tokenIds)
        await doMigrateTx.wait()

        return tokenIds
      }
      return []
    } catch (e) {
      console.log(e)
    }
  },
)
