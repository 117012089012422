import styled from 'styled-components'

export const HorizontalIslandStyled = styled.div`
  width: 100%;
  /* height: 0; */
  padding-top: 47%;
  position: relative;

  .background {
    position: absolute;
    top: -18%;
    left: 0;
    width: 100%;
    transform: scale(0.8);
  }
`

export const VerticalIslandStyled = styled.div`
  width: 100%;
  /* aspect-ratio: 1/2; */
  position: relative;
  margin: auto;

  /* @supports not (aspect-ratio: 1 / 2) { */
  ::before {
    float: left;
    padding-top: 200%;
    content: '';
  }

  ::after {
    display: block;
    content: '';
    clear: both;
  }
  /* } */

  .background {
    position: absolute;
    top: -28%;
    left: -97%;
    width: 305%;
  }
`

export const PopupStyle = styled.div`
  width: 797px;
  max-width: 95vw;
  position: relative;
  font-size: 22px;

  .popup-background {
    width: 100%;
  }

  .absolute-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 6%;
    height: 9%;
    cursor: pointer;
  }

  .title {
    font-family: ${(props: any) => props.theme.fontPrimary};
    font-size: 2em;
    font-weight: 600;
    background: -webkit-linear-gradient(45deg, #faff00, #ef9200 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 1%;
    left: 5%;
  }

  .text-area {
    font-family: ${(props: any) => props.theme.fontPrimary};
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1.2;
    text-align: justify;

    position: absolute;
    top: 23%;
    left: 10%;
    right: 10%;
    bottom: 20%;
    overflow: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f7da92;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f7da92;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a8611a;
      border: 2px solid #9b5415;
    }
  }
`

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const HarbourBlock = styled.div`
  width: 100%;
  color: ${(props: any) => props.theme.colors.white};
  position: relative;
  height: 100%;
  background-image: url('./images/home/bg.jpg');
  background-size: cover;
  background-position: 100% 100%;
`

export const Container = styled.div`
  /* width: 100%;
  height: 100vh;
  max-width: 1440px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; */
`

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`

export const Title = styled.h2`
  text-align: center;
  font-size: 3.5em;
  font-family: ${(props: any) => props.theme.fontPrimary};
  margin-bottom: 10px;
  font-weight: 600;
  background: -webkit-linear-gradient(45deg, #faff00, #ef9200 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  position: relative;
`

export const Desc = styled.p`
  font-size: 1.1rem;
  text-align: center;
  font-family: ${(props: any) => props.theme.fontSecond};
`

export const IsLand = styled.div`
  position: relative;
  max-height: calc(100vh - 350px);
  margin-top: -50px;
  background: red;
`
