import { useWeb3React } from '@web3-react/core'
import LoadingFullScreen from 'components2/Common/LoadingFullScreen'
import ConnectWalletModal from 'components2/Modal/ConnectWalletModal'
import GlobalModal from 'components2/static/GlobalModal'
import { useActiveWeb3React } from 'hooks'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { getAuthSelector, logoutAction } from 'state/auth/reducer'
import SignModal from 'views2/HomepageGame/Components/Modal/Sign'

const MemberGuard = ({ children }) => {
  const authSelector = useSelector(getAuthSelector)
  const { account } = useWeb3React()
  const isFirstRender = useRef(true)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useAppDispatch()
  setTimeout(() => {
    setIsLoading(false)
  }, 1000)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isLoading) return
    if (!authSelector?.access?.token && !account) {
      GlobalModal.show(<ConnectWalletModal />, { onPressBackground: null })
      return
    }
    if (!authSelector?.isLoading && !authSelector?.access?.token)
      setTimeout(() => {
        GlobalModal.show(<SignModal onClose={GlobalModal.hide} />, { onPressBackground: null })
      }, 500)
  }, [authSelector, account, isLoading])

  const accountChangedHandler = (newAccount) => {
    dispatch(logoutAction())
  }

  useEffect(() => {
    window?.ethereum?.on('accountsChanged', accountChangedHandler)
  }, [])

  return <div>{isLoading ? <LoadingFullScreen /> : children}</div>
}

export default MemberGuard
