import API from 'api'
import config from 'api/config'

interface IPlayGameAction {
  type: number
  tokenId: string
}

export const playGame = async (data: IPlayGameAction) => {
  try {
    const response = await API({
      url: config.API.GAME_SERVICE,
      method: 'POST',
      data,
      hasToken: true,
    })
    return response
  } catch (error) {
    return error
  }
}

export const typesGame = async () => {
  try {
    const response = await API({
      url: config.API.TYPES_SERVICE,
      method: 'GET',
      hasToken: true,
    })
    return response
  } catch (error) {
    return error
  }
}

export const getGameSetting = async () => {
  try {
    const response = await API({
      url: `${config.API.GAME_SERVICE}/game-setting`,
      method: 'GET',
    })

    return response
  } catch (error) {
    return error
  }
}
