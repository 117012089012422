import React from 'react'

const CivilianList = [
  {
    id: 1,
    lv: '1',
    img: './images/civilian-fusion/civilian/farm.png',
    star: 3,
    rating: 3,
    name: 'farm',
  },
  {
    id: 2,
    lv: '3',
    img: './images/civilian-fusion/civilian/doctor.png',
    star: 2,
    rating: 2,
    name: 'doctor',
  },
  {
    id: 3,
    lv: '2',
    img: './images/civilian-fusion/civilian/police.png',
    star: 1,
    rating: 1,
    name: 'police',
  },
  {
    id: 4,
    lv: '6',
    img: './images/civilian-fusion/civilian/fireman.png',
    star: 5,
    rating: 5,
    name: 'fireman',
  },
  {
    id: 5,
    lv: '1',
    img: './images/civilian-fusion/civilian/football.png',
    star: 4,
    rating: 4,
    name: 'football',
  },
  {
    id: 6,
    lv: '2',
    img: './images/civilian-fusion/civilian/farm.png',
    star: 3,
    rating: 3,
    name: 'farm',
  },
  {
    id: 7,
    lv: '1',
    img: './images/civilian-fusion/civilian/doctor.png',
    star: 2,
    rating: 3,
    name: 'doctor',
  },
  {
    id: 8,
    lv: '5',
    img: './images/civilian-fusion/civilian/police.png',
    star: 1,
    rating: 4,
    name: 'police',
  },
  {
    id: 9,
    lv: '3',
    img: './images/civilian-fusion/civilian/fireman.png',
    star: 5,
    rating: 1,
    name: 'fireman',
  },
  {
    id: 10,
    lv: '9',
    img: './images/civilian-fusion/civilian/football.png',
    star: 4,
    rating: 5,
    name: 'football',
  },
  {
    id: 11,
    lv: '6',
    img: './images/civilian-fusion/civilian/farm.png',
    star: 3,
    rating: 2,
    name: 'farm',
  },
  {
    id: 12,
    lv: '1',
    img: './images/civilian-fusion/civilian/doctor.png',
    star: 2,
    rating: 1,
    name: 'doctor',
  },
  {
    id: 13,
    lv: '7',
    img: './images/civilian-fusion/civilian/police.png',
    star: 1,
    rating: 1,
    name: 'police',
  },
  {
    id: 14,
    lv: '4',
    img: './images/civilian-fusion/civilian/fireman.png',
    star: 5,
    rating: 5,
    name: 'fireman',
  },
  {
    id: 15,
    lv: '6',
    img: './images/civilian-fusion/civilian/football.png',
    star: 4,
    rating: 2,
    name: 'football',
  },
  {
    id: 16,
    lv: '1',
    img: './images/civilian-fusion/civilian/farm.png',
    star: 3,
    rating: 3,
    name: 'farm',
  },
  {
    id: 17,
    lv: '1',
    img: './images/civilian-fusion/civilian/doctor.png',
    star: 2,
    rating: 3,
    name: 'doctor',
  },
  {
    id: 18,
    lv: '3',
    img: './images/civilian-fusion/civilian/police.png',
    star: 1,
    rating: 2,
    name: 'police',
  },
  {
    id: 19,
    lv: '2',
    img: './images/civilian-fusion/civilian/fireman.png',
    star: 5,
    rating: 4,
    name: 'fireman',
  },
  {
    id: 20,
    lv: '1',
    img: './images/civilian-fusion/civilian/football.png',
    star: 4,
    rating: 3,
    name: 'football',
  },
  {
    id: 21,
    lv: '1',
    img: './images/civilian-fusion/civilian/football.png',
    star: 4,
    rating: 3,
    name: 'football',
  },
  {
    id: 22,
    lv: '1',
    img: './images/civilian-fusion/civilian/football.png',
    star: 4,
    rating: 3,
    name: 'football',
  },
  {
    id: 23,
    lv: '3',
    img: './images/civilian-fusion/civilian/police.png',
    star: 1,
    rating: 2,
    name: 'police',
  },
  {
    id: 24,
    lv: '3',
    img: './images/civilian-fusion/civilian/police.png',
    star: 1,
    rating: 2,
    name: 'police',
  },
  {
    id: 25,
    lv: '3',
    img: './images/civilian-fusion/civilian/police.png',
    star: 1,
    rating: 2,
    name: 'police',
  },
  {
    id: 26,
    lv: '3',
    img: './images/civilian-fusion/civilian/police.png',
    star: 1,
    rating: 2,
    name: 'police',
  },
  {
    id: 27,
    lv: '3',
    img: './images/civilian-fusion/civilian/police.png',
    star: 1,
    rating: 2,
    name: 'police',
  },
]

CivilianList.sort((a, b) => {
  if (a.rating > b.rating) {
    return -1
  }
  if (a.rating === b.rating) {
    if (a.star > b.star) {
      return -1
    }
    if (a.star === b.star) {
      if (a.lv > b.lv) {
        return -1
      }
      return 1
    }
    return 1
  }

  return 1
})

export default CivilianList
