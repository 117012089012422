import { createSlice } from '@reduxjs/toolkit'
import predictionConfig from 'config/constants/prediction'
import _ from 'lodash'
import { PredictionState } from '../types'
import { fetchPredictionPoolV2, fetchPredictionUserDataV2 } from './actions'
import { PREDICTION_SLICE_NAME } from './constants'

const initialState = {
  activeRoundId: 0,
  round: [
    {
      roundId: 0,
      status: {
        lastUpdatedAt: null,
      },
      predictionPools: predictionConfig,
      longApy: 0,
      shortApy: 0,
      withdrawFee: '0',
    },
    {
      roundId: 1,
      status: {
        lastUpdatedAt: null,
      },
      predictionPools: predictionConfig,
      longApy: 0,
      shortApy: 0,
      withdrawFee: '0',
    },
    {
      roundId: 2,
      status: {
        lastUpdatedAt: null,
      },
      predictionPools: predictionConfig,
      longApy: 0,
      shortApy: 0,
      withdrawFee: '0',
    },
  ],
  isLoading: false,
  rebound: false,
} as PredictionState

// actions
export const predictionSlice = createSlice({
  name: PREDICTION_SLICE_NAME,
  initialState,
  reducers: {
    setPredictionState: (state: any, action) => {
      Object.keys(action?.payload || {}).forEach((key) => {
        state[key as keyof PredictionState] = action.payload[key]
      })
    },

    setPredictionUserData: (state, action) => {
      const arrayOfUserDataWithRound = action.payload
      arrayOfUserDataWithRound.forEach((userData, i) => {
        userData.forEach((userPool) => {
          const { index } = userPool
          state.round[i].predictionPools[index] = { ...state.round[i].predictionPools[index], userData: userPool }
        })
      })
    },

    setPredictions: (state, action) => {
      const rounds = action.payload
      rounds.forEach((r, index) => {
        state.round[index].status = r.status
        state.round[index].longApy = r.longApy
        state.round[index].shortApy = r.shortApy
        state.round[index].withdrawFee = r.withdrawFee
        r.poolsInfo.forEach((pool, i) => {
          state.round[index].predictionPools[i] = {
            ...state.round[index].predictionPools[i],
            ..._.pickBy(pool, (prop) => prop !== null),
          }
        })
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPredictionUserDataV2.fulfilled, (state, action) => {
      const arrayOfUserDataWithRound = action.payload
      arrayOfUserDataWithRound.forEach((userData, i) => {
        userData.forEach((userPool) => {
          const { index } = userPool
          state.round[i].predictionPools[index] = { ...state.round[i].predictionPools[index], userData: userPool }
        })
      })
    })
    builder.addCase(fetchPredictionPoolV2.fulfilled, (state, action) => {
      const rounds = action.payload
      rounds.forEach((r, index) => {
        state.round[index].status = r.status
        state.round[index].longApy = r.longApy
        state.round[index].shortApy = r.shortApy
        state.round[index].withdrawFee = r.withdrawFee
        r.poolsInfo.forEach((pool, i) => {
          state.round[index].predictionPools[i] = {
            ...state.round[index].predictionPools[i],
            ..._.pickBy(pool, (prop) => prop !== null),
          }
        })
      })
    })
    // builder
    //   .addMatcher(isFulfilledAction(`${PREDICTION_SLICE_NAME}/`), (state: any, action) => {
    //     state.isLoading = false
    //     Object.keys(action?.payload || {}).forEach((key) => {
    //       state[key as keyof PredictionState] = action.payload[key]
    //     })
    //     state.rebound = false
    //   })
    //   .addMatcher(isPendingAction(`${PREDICTION_SLICE_NAME}/`), (state) => {
    //     state.isLoading = true
    //   })
    //   .addMatcher(isRejectedAction(`${PREDICTION_SLICE_NAME}/`), (state) => {
    //     state.isLoading = false
    //     state.rebound = false
    //   })
  },
})

export const { setPredictionUserData, setPredictions, setPredictionState } = predictionSlice.actions

export default predictionSlice.reducer
