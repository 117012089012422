import styled from 'styled-components'

export const HeaderMain = styled.div`
  width: 100%;
  padding: 0 15px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`

export const BoxHeader = styled.div`
  /* width: 100%;
  height: 100vh;
  position: absolute; */
`

export const Left = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 45px;
  margin-left: 30px;

  .avar {
    margin-bottom: 30px;
    position: relative;

    .name {
      position: absolute;
      top: 32%;
      left: 56%;
      font-size: 1em;
      font-weight: 600;
      white-space: nowrap;
      color: #fff;
    }

    .id-number {
      position: absolute;
      font-size: 1em;
      top: 54%;
      left: 56%;
      color: #fff;

      span {
        display: inline-block;
        vertical-align: bottom;
        white-space: nowrap;
        width: 100%;
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        cursor: pointer;
        transition: 0.5s ease-in-out;
        margin-top: -3px;
        margin-left: 4px;

        &:hover {
          transition: 0.5s ease-in-out;
          opacity: 0.3;
        }
      }
    }
  }

  .daily {
    img {
      transition: 0.5s ease all;
      cursor: pointer;

      &:hover {
        filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
          drop-shadow(2px -2px 2px #ffffff);
      }
    }
  }
`

export const Right = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 45px;
  margin-right: 64px;

  .box-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      transition: 0.5s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
        transition: 0.5s ease-in-out;
        filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
          drop-shadow(2px -2px 2px #ffffff);
      }
    }
  }
`

export const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 64px;
  margin-bottom: 60px;

  .box-icon {
    display: grid;
    grid-template-columns: 1fr 100px 100px;
    grid-gap: 20px;
    width: 678px;

    img {
      transition: 0.5s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
        transition: 0.5s ease-in-out;
      }
    }
  }
`
