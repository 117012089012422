import { useWeb3React } from '@web3-react/core'
import { Tabs } from 'antd'
import { MAX_ENERGY } from 'config/constants/stakingNft'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import fetchPoolsByRarity, { fetchCiviliansWorking } from 'state/stakingNft/fetchPool'
import styled from 'styled-components'
import HeaderToken from 'views2/HomepageGame/Components/HeaderToken'
import { useAppDispatch } from '../../../state/index'
import { fetchIsApprovedForAll, fetchMyCivilian } from '../../../state/stakingNft/fetchPool'
import { State } from '../../../state/types'
import Back from '../components/Back'
import PageLoading from '../components/PageLoadding'
import ReViewIcons from '../components/ReViewIcons'
import PageReview from '../PageReview'
import HistoryStaking from './Components/HistoryStaking'
import RarityItem from './Components/RarityItem'
const { TabPane } = Tabs
function Staking(props) {
  const dispatch = useAppDispatch()
  const [activeKey, setActiveKey] = useState('1')
  const { account } = useWeb3React()
  const [tutorial, setTutorial] = useState(true)
  const [bgReview, setBgReview] = useState(true)

  const civiliansWorking = useSelector((state: State) => state.stakingNft.civiliansWorking)

  useEffect(() => {
    if (parseInt(activeKey) < 6) {
      dispatch(fetchPoolsByRarity(parseInt(activeKey) - 1))
    }
  }, [activeKey])

  useEffect(() => {
    if (account) {
      dispatch(fetchCiviliansWorking(account))
      dispatch(fetchMyCivilian(account))
      dispatch(fetchIsApprovedForAll(account))
    }
  }, [account])

  const fetchingPool = useSelector((state: State) => state.stakingNft.fetching.pools)
  const fetchingCiviliansWorking = useSelector((state: State) => state.stakingNft.fetching.civiliansWorking)
  const fetchingMyCivilans = useSelector((state: State) => state.stakingNft.fetching.myCivilians)

  const poolsByRarity = useSelector((state: State) => state.stakingNft.pools[parseInt(activeKey) - 1])

  const onTabClick = (key) => {
    setActiveKey(key)
    if (key === '6') {
      setBgReview(false)
    } else {
      setBgReview(true)
    }
  }

  return (
    <StakingStyled>
      <Back />
      <div className="header__token__styled">
        <HeaderToken />
      </div>
      <ReViewIcons
        styled={tutorial ? { display: 'block' } : { display: 'none' }}
        onClick={() => setTutorial(!tutorial)}
      />
      <PageReview
        Styled={tutorial ? 'none' : 'flex'}
        Background={
          bgReview
            ? "url('./images/civilian-fusion/review-rarity-tab.png')"
            : "url('./images/civilian-fusion/review-working.png')"
        }
        onClose={() => setTutorial(!tutorial)}
      />
      <StakingTabs>
        <Tabs activeKey={activeKey} onTabClick={onTabClick}>
          {['1', '2', '3', '4', '5'].map((item) => (
            <TabPane tab={`RARITY ${item}`} key={item}>
              <div className="staking__styled">
                {fetchingMyCivilans || fetchingCiviliansWorking ? (
                  <PageLoading />
                ) : (
                  <StakingContent>
                    {!fetchingPool &&
                      poolsByRarity &&
                      poolsByRarity.length > 0 &&
                      poolsByRarity.map((pool, index) => (
                        <RarityItem
                          key={index}
                          limit={pool.limit}
                          amount={pool.amount}
                          job={pool.jobStaking}
                          img={pool.imgStaking}
                          interestPerDay={pool.interestPerDay}
                          rarity={parseInt(item)}
                          rating={index + 1}
                        />
                      ))}
                  </StakingContent>
                )}
              </div>
            </TabPane>
          ))}

          <TabPane tab="WORKING" key="6">
            <div className="staking__styled history">
              {fetchingPool ? (
                <PageLoading />
              ) : (
                <StakingContent
                  style={
                    bgReview
                      ? { display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', overflowY: 'scroll' }
                      : { display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', overflowY: 'scroll' }
                  }
                >
                  {civiliansWorking.map((civilian, index) => (
                    <HistoryStaking
                      key={index}
                      civilian={civilian}
                      percentStamina={Math.floor((civilian.stamina / MAX_ENERGY) * 100)}
                    />
                  ))}
                </StakingContent>
              )}
            </div>
          </TabPane>
        </Tabs>
      </StakingTabs>
    </StakingStyled>
  )
}

const StakingStyled = styled.div`
  background: url('./images/staking-game/bg-staking.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 1080px;
  width: 1920px;
  .header__token__styled {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .staking__styled {
    background: #3264af52;
  }
`

const StakingContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1800px;
  margin: 0 auto;
  height: 840px;
  padding-bottom: 15px;
  &::-webkit-scrollbar {
    width: 12px;
    background: unset;
    border-radius: 27px;
    margin-right: -40px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 27px;
    background: #94bfff;
  }
`

const StakingTabs = styled.div`
  position: absolute;
  top: 180px;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0%);
  z-index: 100;
  .ant-tabs.ant-tabs-top {
    overflow: unset !important;
  }
  .ant-tabs-nav-wrap {
    overflow: unset !important;
  }
  .ant-tabs-nav {
    width: 1775px;
    margin: 0 auto;
    &::before {
      border-bottom: unset;
    }
    .ant-tabs-tab-btn {
      color: #d0d6e3;
      font-size: 20px;
      font-weight: 400;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      background: url('./images/staking-game/tabs-active.svg');
      transition: ease all 0.5s;
      margin-top: -4px;
      height: 60px;
      .ant-tabs-tab-btn {
        color: #fff;
      }
      &::before {
        opacity: 1;
      }
    }
    .ant-tabs-tab {
      background: url('./images/staking-game/tabs.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 26px 26px 0px 0px;
      width: 280px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: ease all 0.5s;
      margin-left: 16px;
      &::after {
        content: '';
        background: url('./images/games/star.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 28px;
        height: 28px;
        opacity: 0.8;
        margin-left: 12px;
      }
      &:nth-child(1) {
        margin-left: 0px;
      }
      &:nth-child(6) {
        &::after {
          content: '';
          width: 32px;
          height: 32px;
          background: url('./images/staking-game/history.svg');
          background-size: cover;
        }
      }
    }
  }
`
export default Staking
