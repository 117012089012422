import BeanButton from 'components2/Common/BeanButton'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import BaseModal from '../BaseModal'
import ButtonDeposit from '../ButtonDeposit'

function TransferToken({ onClose, callUsePredictionV1 }) {
  console.log('totalShortSupply', callUsePredictionV1)
  const tokenImg = {
    0: {
      name: 'CAKE',
      img: './images/games/pancakeswap.svg',
      token: callUsePredictionV1.predictionPools[0]?.userData?.pendingCake,
    },
    1: {
      name: 'USDT - BUSD',
      img: './images/games/usdt-busd.png',
      token: '0',
    },
    2: {
      name: 'DPT',
      img: './images/games/dpt.svg',
      token: callUsePredictionV1.predictionPools[0]?.userData?.pendingDPT,
    },
    3: {
      name: 'DPT - BNB',
      img: './images/games/dpt-bnb.png',
      token: '0',
    },
  }
  return (
    <TransferTokenStyled>
      <BaseModal
        header={<BeanButton className="fixed-on-top">Contract upgrade</BeanButton>}
        styleInside={{ padding: '0 10px', margin: '15px' }}
        onClose={onClose}
        style={{ width: '850px' }}
        bgSVG=" unset "
      >
        <div className="transfer__token">
          <p className="content__transfer">
            Hey Diviner, we are glad to announce that the latest updated version of Lossless Prediction is available!
            <br /> Please withdraw all your token from old contract and move to new contract.
          </p>
          <p className="content__transfer-bottom">Just click on the button below to withdraw your token right now.</p>
          <div className="transfer__token__token">
            {callUsePredictionV1?.predictionPools.map((token, index) => (
              <div
                className={
                  parseInt(token?.userData?.longAmount) !== 0 || parseInt(token?.userData?.shortAmount) !== 0
                    ? 'transfer__token__token-content'
                    : 'transfer__token__token-content none'
                }
                key={index}
              >
                <div className="name__token">
                  {/* {token.symbol} */}
                  <img src={tokenImg[index].img} alt="" />
                </div>
                <div className="isLong">
                  <p>
                    <span>
                      {parseInt(formatEther(token?.userData?.shortAmount)) +
                        parseInt(formatEther(token?.userData?.longAmount)) +
                        parseInt(formatEther(tokenImg[index].token))}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="button__transfer">
            <Link to="/prediction-v1" onClick={onClose}>
              <ButtonDeposit title="Go Now" onDeposit={null} isLoading={null} />
            </Link>
          </div>
        </div>
      </BaseModal>
    </TransferTokenStyled>
  )
}

const TransferTokenStyled = styled.div`
  .notch-bg {
    background: url('./images/games/bg-transfer.png') no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
  }
  .transfer__token {
    padding: 0px 20px;
    height: 100%;
    position: relative;
    p {
      text-align: center;
    }
    .content__transfer {
      font-size: 26px;
      font-weight: 700;
      color: #fff;
      padding-bottom: 20px;
      padding-top: 10px;
    }
    .content__transfer-bottom {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
    }
    .button__transfer {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
    .transfer__token__token {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;

      .transfer__token__token-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-right: 20px;
        &:last-child {
          padding-right: 0px;
        }
        &.none {
          display: none;
        }
        .name__token {
          padding-right: 15px;
          display: flex;
          justify-content: left;
          align-items: center;
          img {
            width: 35px;
          }
        }
        p {
          color: #fff;
          margin: 0px;
          font-size: 18px;
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
  .fixed-on-top {
    height: 85px;
  }
`

export default TransferToken
