import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const InputStyled = styled.input`
  font-size: 26px;
  font-weight: 700;
  margin: 10px 0px;
  background: #ffffff;
  border: 2px solid rgba(153, 153, 153, 0.5);
  box-shadow: inset 0px -4px 12px rgba(90, 90, 90, 0.2);
  transition: all 0.1s;
  border-radius: 12px;
  padding: 0 10px;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: text;

  :focus-within {
    outline: 2px solid #4082cc;
    box-shadow: inset -2px -4px 8px rgba(255, 255, 255, 0.4);
  }
`
export default InputStyled
