import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 1440px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
`

export const CustomeBack = styled.div`
  div {
    position: unset;
  }
`

export const Left = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 45px;
  margin-left: 30px;

  .avar {
    margin-bottom: 30px;
    position: relative;

    .name {
      position: absolute;
      top: 32%;
      left: 56%;
      font-size: 1em;
      font-weight: 600;
      white-space: nowrap;
      color: #fff;
    }

    .id-number {
      position: absolute;
      font-size: 1em;
      top: 54%;
      left: 56%;
      color: #fff;

      span {
        display: inline-block;
        vertical-align: bottom;
        white-space: nowrap;
        width: 100%;
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        cursor: pointer;
        transition: 0.5s ease-in-out;
        margin-top: -3px;
        margin-left: 4px;

        &:hover {
          transition: 0.5s ease-in-out;
          opacity: 0.3;
        }
      }
    }
  }

  .daily {
    img {
      transition: 0.5s ease all;
      cursor: pointer;

      &:hover {
        filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
          drop-shadow(2px -2px 2px #ffffff);
      }
    }
  }
`

export const Right = styled.div`
  position: absolute;
  top: -30px;
  right: 0;
  padding-top: 45px;
  margin-right: 64px;
  z-index: 99;

  .box-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;

    img {
      transition: 0.5s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
        transition: 0.5s ease-in-out;
        filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
          drop-shadow(2px -2px 2px #ffffff);
      }
    }
  }
`

export const BackgroundPredictionGame = styled.div`
  background: url('../images/fileDiviner/dxrhes.webp') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  height: 100%;
  width: 100%;

  @media (max-width: 767px) {
    position: relative;
    z-index: -1;
  }

  .container__icon {
    position: absolute;
    top: 43px;
    width: 40px;
    cursor: pointer;
    z-index: 100;
    img {
      width: 100%;
    }
  }

  .music__button {
    right: 55px;
  }

  .history__button {
    right: 0;
  }

  .calculators__button {
    right: 110px;
  }

  a {
    text-decoration: unset;
  }

  .jointable-none {
    opacity: 0;
  }

  .jointable-block {
    opacity: 1;
  }
`

export const DivinerContent = styled.div`
  z-index: 30;
  margin: 0px 20px;
  color: #fff;
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  span {
    border-radius: 10px;
  }

  .Experience {
    position: relative;
    display: flex;
    align-items: center;

    .ExperiencePC {
    }

    .ExperienceBT {
      p {
        margin: 0px;
        z-index: 15;
      }
      position: absolute;
      width: 135%;
      margin-right: -18%;
      bottom: 99px;
      right: 0;

      span:nth-child(1) {
        position: absolute;
        z-index: 9;
        left: 0;
        height: 60px;
        background: linear-gradient(0deg, #33d760, #33d760);
        transition: all 0.7s ease 0s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-weight: 700;
        text-shadow: rgb(0 0 0) 2px 0px 0px, rgb(0 0 0) 1.75517px 0.958851px 0px, rgb(0 0 0) 1.0806px 1.68294px 0px,
          rgb(0 0 0) 0.141474px 1.99499px 0px, rgb(0 0 0) -0.832294px 1.81859px 0px, rgb(0 0 0) -1.60229px 1.19694px 0px,
          rgb(0 0 0) -1.97998px 0.28224px 0px, rgb(0 0 0) -1.87291px -0.701566px 0px,
          rgb(0 0 0) -1.30729px -1.5136px 0px, rgb(0 0 0) -0.421592px -1.95506px 0px,
          rgb(0 0 0) 0.567324px -1.91785px 0px, rgb(0 0 0) 1.41734px -1.41108px 0px,
          rgb(0 0 0) 1.92034px -0.558831px 0px, 1.5px 2.598px 4px rgb(0 0 0);
        animation: linear example 2s;

        span:nth-child(1) {
          background: #a0ffbb;
          height: 0px;
          padding: 8.5px 0px;
          border-radius: 0px;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          top: 8%;
          position: absolute;
          z-index: 11;
          width: 100%;
          left: 0;
          animation: linear example3;
        }
        span:nth-child(3) {
          background: #28ca41;
          padding: 4px 0px;
          border-radius: 0px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          bottom: 0%;
          position: absolute;
          z-index: 11;
          width: 100%;
          left: 0;
        }
        img {
          position: absolute;
          right: -3%;
          height: 100%;
          z-index: 12;
        }
      }
      span:nth-child(2) {
        z-index: 8;
        position: absolute;
        right: 0;
        height: 60px;
        background: linear-gradient(0deg, #fc584f, #fc584f);
        transition: all 0.7s ease 0s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-weight: 700;
        text-shadow: rgb(0 0 0) 2px 0px 0px, rgb(0 0 0) 1.75517px 0.958851px 0px, rgb(0 0 0) 1.0806px 1.68294px 0px,
          rgb(0 0 0) 0.141474px 1.99499px 0px, rgb(0 0 0) -0.832294px 1.81859px 0px, rgb(0 0 0) -1.60229px 1.19694px 0px,
          rgb(0 0 0) -1.97998px 0.28224px 0px, rgb(0 0 0) -1.87291px -0.701566px 0px,
          rgb(0 0 0) -1.30729px -1.5136px 0px, rgb(0 0 0) -0.421592px -1.95506px 0px,
          rgb(0 0 0) 0.567324px -1.91785px 0px, rgb(0 0 0) 1.41734px -1.41108px 0px,
          rgb(0 0 0) 1.92034px -0.558831px 0px, 1.5px 2.598px 4px rgb(0 0 0);

        span:nth-child(1) {
          padding: 8.5px 0px;
          background: #fe7c75;
          height: 0px;
          top: 8%;
          position: absolute;
          z-index: 10;
          width: 100%;
          right: 0;
        }
        span:nth-child(2) {
          background: #db1a1a;
          padding: 4px 0px;
          height: 0px;
          border-radius: 0px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          bottom: 0%;
          position: absolute;
          z-index: 11;
          width: 100%;
          right: 0;
        }
      }
    }
  }
`

export const DivinerCha = styled.div`
  position: absolute;
  top: 300px;
  left: -200px;
  z-index: 1;

  .ExperienceMobile {
    display: none;
  }
`
export const GreenCha = styled.div`
  margin: 0 auto;
  position: relative;

  .GreenLong {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      bottom: -15px;
      width: 130px;
      cursor: pointer;
      transition: ease all 0.5s;
      animation-name: scale;
      animation-duration: 1s;
      animation-iteration-count: infinite;

      @keyframes scale {
        50% {
          transform: scale(1.05);
        }
      }

      :hover {
        transform: scale(1.1);
        animation-name: none;
      }
    }
  }
  .GreenSD {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: absolute;
      width: 550px;
      bottom: -9%;
      cursor: pointer;
      z-index: -1;

      :hover {
        transform: scale(1.1);
      }
    }
  }
  .RedchaMain {
    position: relative;
  }

  //spot light
  @keyframes light-animate {
    0% {
      opacity: 20;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 1;
    }
  }

  .RedchaMain {
    --background-style: radial-gradient(
      circle at 50% 100%,
      rgba(255, 89, 79, 0.7) 1.66%,
      rgba(255, 255, 255, 0) 89.36%
    );
    --background-style-overlay: radial-gradient(
      circle at 50% 100%,
      rgba(255, 155, 150, 0.219) 1.66%,
      rgba(255, 255, 255, 0) 89.36%
    );
  }
  .GreenchaMain {
    --background-style: radial-gradient(
      circle at 50% 100%,
      rgba(114, 255, 79, 0.7) 1.66%,
      rgba(255, 255, 255, 0) 89.36%
    );
    --background-style-overlay: radial-gradient(
      circle at 50% 100%,
      rgba(169, 255, 147, 0.2) 1.66%,
      rgba(255, 255, 255, 0) 89.36%
    );
  }

  .RedchaMain,
  .GreenchaMain {
    :before,
    :after {
      content: '';
      background: var(--background-style);
      position: absolute;
      top: 18%;
      left: 3%;
      right: 3%;
      bottom: 10%;
      clip-path: polygon(50% 0%, 63% 0, 85% 4%, 100% 10%, 85% 100%, 15% 100%, 0 10%, 16% 3%, 36% 0);
      z-index: -1;
      animation-name: light-animate;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    :after {
      z-index: 1;
      background: var(--background-style-overlay);
    }
  }
`
