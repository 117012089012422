import { Contract } from '@ethersproject/contracts'
import { useCallback, useState } from 'react'

export default function useCallContract(contract: Contract, funcName: string) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCallContract = useCallback(
    async (funcParams: Array<any>) => {
      try {
        setIsLoading(true)
        const txHash = await contract[funcName](...funcParams)
        await txHash.wait()
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [contract],
  )

  return { isLoading, onCall: handleCallContract }
}
