import BeanButton from 'components2/Common/BeanButton'
import predictionConfig from 'config/constants/prediction'
import { PredictionConfig } from 'config/constants/types'
import { useState } from 'react'

import BaseModal from 'components2/Modal/BaseModal'
import { BaseModalStyled, LongModalStyled } from '../styled'
import JoinTable from './Join'
import LeaveTable from './Leave'

const dropList = predictionConfig
const DepositWithdrawHOC =
  (colorPatten) =>
  ({ onClose }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [drop, setDrop] = useState(true)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [poolData, setPoolData] = useState<PredictionConfig>(dropList[0])

    const handleSelectPool = (pid) => {
      const lpData = dropList.find((d) => d.pid === pid)
      setPoolData(lpData)
    }

    return (
      <BaseModalStyled>
        <BaseModal
          background={`linear-gradient(180deg, ${colorPatten.gradient1} 0%, ${colorPatten.gradient2} 100%)`}
          header={
            <div className="bean-button">
              <BeanButton
                onClick={() => setDrop(true)}
                className={drop ? 'fixed-on-top active ' : 'fixed-on-top button__log--short'}
                backgroundColor={drop ? colorPatten.button : colorPatten.buttonDisabled}
              >
                Join
              </BeanButton>
              <BeanButton
                onClick={() => setDrop(false)}
                className={!drop ? 'fixed-on-top active' : 'fixed-on-top button__log--short'}
                backgroundColor={drop ? colorPatten.buttonDisabled : colorPatten.button}
              >
                Leave
              </BeanButton>
            </div>
          }
          onClose={onClose}
        >
          <LongModalStyled>
            {!drop ? (
              <LeaveTable poolData={poolData} onSelectPool={handleSelectPool} isLong={colorPatten.title === 'long'} />
            ) : (
              <JoinTable poolData={poolData} onSelectPool={handleSelectPool} isLong={colorPatten.title === 'long'} />
            )}
          </LongModalStyled>
        </BaseModal>
      </BaseModalStyled>
    )
  }

const greenColors = {
  title: 'long',
  gradient1: '#6be793',
  gradient2: '#17c650',
  button: '#1DCB43',
  buttonDisabled: '#59C170',
}
export const DepositWithdrawGreenModal = DepositWithdrawHOC(greenColors)

const redColors = {
  title: 'short',
  gradient1: '#E76969',
  gradient2: '#C61717',
  button: '#D43232',
  buttonDisabled: '#C15959',
}
export const DepositWithdrawRedModal = DepositWithdrawHOC(redColors)
