import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

function Back({ to = '/metacity' }) {
  return (
    <BackStyled>
      <Link to={to}>Back</Link>
    </BackStyled>
  )
}

const BackStyled = styled.div`
  a {
    padding-bottom: 7px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    top: 40px;
    left: 40px;
    background: url('./images/civilian-fusion/back.svg');
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 118px;
    max-height: 82px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease all 0.5s;
    &:hover {
      filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
        drop-shadow(2px -2px 2px #ffffff);
    }
  }
`
export default Back
