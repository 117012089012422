const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },

  cake: {
    symbol: 'CAKE',
    address: {
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      97: '0xF61e1c34386ADD959937a99117C1fa3fE7DFf2F7',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },

  dpt: {
    symbol: 'DPT',
    address: {
      56: '0xE69cAef10A488D7AF31Da46c89154d025546e990',
      97: '0x5219BEd1C25202DA1B6fcAB9CA1d46C35d0ffD37',
    },
    decimals: 18,
  },
  dgt: {
    symbol: 'DGT',
    address: {
      56: '0xFFCc52848b15C6ABd12183e70d96Df25b2498C28',
      97: '0x5c51B547889D19A411f3aC248765058929D4aFC9',
    },
    decimals: 18,
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '0x35606cA16E901E32Fe52cbD172317141bDAB8afb',
    },
    decimals: 18,
  },

  usdtBusdLp: {
    symbol: 'Cake-LP',
    address: {
      56: '0x2C7Ab52226EDCA513C6FDa19263f2F444747BC84',
      97: '0x2C7Ab52226EDCA513C6FDa19263f2F444747BC84',
    },
    decimals: 18,
  },
}

export default tokens
