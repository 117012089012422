import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import characterAbi from 'config/abi/character.json'
import stakingNftAbi from 'config/abi/StakingNft.json'
import gameCharacter from 'config/constants/game'
import {stakingNftPoolsConfig, stakingNftJob, stakingNftImg} from 'config/constants/stakingNft'
import {ethers} from 'ethers'
import {fetchUserCharacter} from 'state/game/fetchGameUserData'
import multicall from 'utils/multicall'
import {QUERY_USER_BY_ID} from 'gql/user'
import {State} from '../types'
// eslint-disable-next-line
import {client} from '../../Providers'
import {getCharacterContract, getStakingNftContract} from '../../utils/contractHelpers'
import {
  setIsFetchingApproval,
  setIsFetchingCiviliansWorking,
  setIsFetchingPools,
  setIsFetchingMyCivilians,
} from './actions'
import {getCharacterAddress, getStakingNftAddress} from '../../utils/addressHelpers'

export const fetchPoolsByRarity = createAsyncThunk('stakingNft/fetchPoolsByRarity', async (rarity: any, {dispatch}) => {
  const stakingNft = getStakingNftContract()
  dispatch(setIsFetchingPools(true))
  try {
    const poolsByRarity = (await stakingNft.getPoolsByRarity(rarity)).map((pool, index) => ({
      ...pool,
      amount: pool.amount.toString(),
      limit: pool.limit.toString(),
      jobStaking: stakingNftJob[rarity][index],
      imgStaking: stakingNftImg[rarity][index],
      interestPerDay: parseFloat(
        ethers.utils.formatEther(pool.interestPerSecond.mul(ethers.BigNumber.from('86400'))),
      ).toFixed(2),
    }))

    return {rarity, poolsByRarity}
  } catch (error) {
    console.log('fetch pools by rarity error : ', error)
    return []
  } finally {
    dispatch(setIsFetchingPools(false))
  }
})

export const fetchIsApprovedForAll = createAsyncThunk(
  'stakingNft/fetchIsApprovedForAll',
  async (account: string, {getState, dispatch}) => {
    const stakingNftAddress = getStakingNftAddress()
    const character = getCharacterContract()

    dispatch(setIsFetchingApproval(true))

    try {
      const isApprovedForAll = await character.isApprovedForAll(
        ethers.utils.getAddress(account),
        ethers.utils.getAddress(stakingNftAddress),
      )
      return isApprovedForAll
    } catch (error) {
      console.log('fetch approval error : ', error)

      return false
    } finally {
      dispatch(setIsFetchingApproval(false))
    }
  },
)

export const fetchCiviliansWorking = createAsyncThunk<any, any>(
  'stakingNft/fetchCiviliansWorking',
  async (account, {getState, dispatch}) => {
    const stakingNftContract = getStakingNftContract()
    const tokenIds = await stakingNftContract.getCiviliansOfUser(account)

    const calls = []

    const callsForStamina = []
    const callsForPendingReward = []
    const callsForNFTInfo = []
    tokenIds.forEach((tokenId) => {
      calls.push({address: getCharacterAddress(), name: 'getHero', params: [tokenId.toString()]})
      callsForStamina.push({address: getStakingNftAddress(), name: 'getStamina', params: [tokenId.toString()]})
      callsForPendingReward.push({
        address: getStakingNftAddress(),
        name: 'pendingReward',
        params: [tokenId.toString()],
      })
      callsForNFTInfo.push({
        address: getStakingNftAddress(),
        name: 'nfts',
        params: [tokenId.toString()],
      })
    })

    dispatch(setIsFetchingCiviliansWorking(true))

    try {
      const [civilians, staminas, pendingRewards, poolsInfo] = await Promise.all([
        multicall(characterAbi, calls, {requireSuccess: false}),
        multicall(stakingNftAbi, callsForStamina, {requireSuccess: false}),
        multicall(stakingNftAbi, callsForPendingReward, {requireSuccess: false}),
        multicall(stakingNftAbi, callsForNFTInfo, {requireSuccess: false}),
      ])

      return civilians
        .map((result, index) => {
          return {
            heroRating: result[0].heroRating.toString(),
            heroRarity: result[0].heroRarity.toString(),
            heroName: result[0].heroName.toString(),
            attack: result[0].attack.toString(),
            defense: result[0].defense.toString(),
            lucky: result[0].lucky.toString(),
            energy: result[0].energy.toString(),
            tokenId: result[0].tokenId.toString(),
            level: result[0].level.toString(),
            lastFightTime: (parseInt(result[0].lastFightTime.toString()) * 1000).toString(),
            poolRating: poolsInfo[index].poolRating.toString(),
            poolRarity: poolsInfo[index].poolRarity.toString(),
            stamina: parseInt(staminas[index].toString()),
            pendingReward: parseInt(ethers.utils.formatEther(pendingRewards[index][0])),

            ...gameCharacter[result[0].heroRarity.toNumber()][result[0].heroName.toNumber()],
          }
        })
        .sort((a, b) => b.heroRarity - a.heroRarity)
    } catch (error) {
      console.log('fetch civilians working error : ', error)

      return false
    } finally {
      dispatch(setIsFetchingCiviliansWorking(false))
    }
  },
)

export const fetchMyCivilian = createAsyncThunk(
  'stakingNft/fetchMyCivilian',
  async (account: string, {getState, dispatch}) => {
    const characterContract = getCharacterContract()
    const tokenIds = await characterContract?.tokensOfOwner(account)

    const calls = []
    const callsForStamina = []
    tokenIds.forEach((tokenId) => {
      calls.push({address: getCharacterAddress(), name: 'getHero', params: [tokenId.toString()]})
      callsForStamina.push({address: getStakingNftAddress(), name: 'getStamina', params: [tokenId.toString()]})
    })
    dispatch(setIsFetchingMyCivilians(true))

    try {
      const [civilians, staminas] = await Promise.all([
        multicall(characterAbi, calls, {requireSuccess: false}),
        multicall(stakingNftAbi, callsForStamina, {requireSuccess: false}),
      ])

      return civilians
        .map((result, index) => {
          return {
            heroRating: result[0].heroRating.toString(),
            heroRarity: result[0].heroRarity.toString(),
            heroName: result[0].heroName.toString(),
            attack: result[0].attack.toString(),
            defense: result[0].defense.toString(),
            lucky: result[0].lucky.toString(),
            energy: result[0].energy.toString(),
            tokenId: result[0].tokenId.toString(),
            level: result[0].level.toString(),
            lastFightTime: (parseInt(result[0].lastFightTime.toString()) * 1000).toString(),
            stamina: parseInt(staminas[index].toString()),
            pendingReward: 0,
            ...gameCharacter[result[0].heroRarity.toNumber()][result[0].heroName.toNumber()],
          }
        })
        .sort((a, b) => b.heroRarity - a.heroRarity)
    } catch (error) {
      console.log('error fetch my civilians', error)
      return []
    } finally {
      dispatch(setIsFetchingMyCivilians(false))
    }
  },
)

export const fetchCiviliansStamina = createAsyncThunk(
  'stakingNft/fetchCiviliansStamina',
  async (_, {getState, dispatch}) => {
    const {
      stakingNft: {civiliansWorking, myCivilians},
    } = getState() as State

    const tokenIds = []
    const amountOfCiviliansWorking = civiliansWorking.length

    civiliansWorking.map((c) => tokenIds.push(c.tokenId))
    myCivilians.map((c) => tokenIds.push(c.tokenId))

    const stakingNftAddress = getStakingNftAddress()

    const calls = []

    tokenIds.forEach((tokenId) =>
      calls.push({address: stakingNftAddress, name: 'getStamina', params: [parseInt(tokenId)]}),
    )

    try {
      const result = await multicall(stakingNftAbi, calls, {
        requireSuccess: false,
      })

      const staminaCiviliansWorking = result
        .slice(0, amountOfCiviliansWorking)
        .map((energy) => parseInt(energy.toString()))
      const staminaMyCivilians = result.slice(amountOfCiviliansWorking).map((energy) => parseInt(energy.toString()))
      return {staminaCiviliansWorking, staminaMyCivilians}
    } catch (error) {
      console.log('error fetch stamina', error)
    }
  },
)

export const fetchCiviliansPendingReward = createAsyncThunk(
  'stakingNft/fetchCiviliansPendingReward',
  async (_, {getState, dispatch}) => {
    const {
      stakingNft: {civiliansWorking, myCivilians},
    } = getState() as State

    const tokenIds = []
    const amountOfCiviliansWorking = civiliansWorking.length
    const amountOfMyCivilians = myCivilians.length

    civiliansWorking.map((c) => tokenIds.push(c.tokenId))
    myCivilians.map((c) => tokenIds.push(c.tokenId))

    const stakingNftAddress = getStakingNftAddress()

    const calls = []

    tokenIds.forEach((tokenId) =>
      calls.push({address: stakingNftAddress, name: 'pendingReward', params: [parseInt(tokenId)]}),
    )

    try {
      const result = await multicall(stakingNftAbi, calls, {
        requireSuccess: false,
      })

      const pendingRewardCiviliansWorking = result.slice(0, amountOfCiviliansWorking).map((reward) => {
        return parseInt(ethers.utils.formatEther(reward[0]))
      })
      const pendingRewardMyCivilians = [...Array(amountOfMyCivilians)].map(() => 0)
      return {pendingRewardCiviliansWorking, pendingRewardMyCivilians}
    } catch (error) {
      console.log('error fetch stamina', error)
    }
  },
)

export default fetchPoolsByRarity
