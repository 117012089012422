import React from 'react'
import styled from 'styled-components'
import CardValue from 'components/CardValue'

interface JoinBottomModalProps {
  totalUserDeposit: string
  tokenSymbol: string
}

const JoinBottomModal: React.FunctionComponent<JoinBottomModalProps> = ({totalUserDeposit, tokenSymbol}) => {
  return (
    <JoinTablesBottomStyled>
      <div className="total-effective">
        <p>Total Deposit</p>
        <p>
          <CardValue value={parseFloat(totalUserDeposit)} text={tokenSymbol} decimals={2}></CardValue>
          {/* {tokenSymbol} */}
        </p>
      </div>
      {/* <div className="total-effective">
        <p>Effective APY Short</p>
        <p>13.8X</p>
      </div> */}
    </JoinTablesBottomStyled>
  )
}

const JoinTablesBottomStyled = styled.div`
  .total-effective {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p:nth-child(2) {
      padding: 5px 0px;
    }
  }
`

export default JoinBottomModal
