import {createAction} from '@reduxjs/toolkit'

export const setIsFetchingApproval = createAction<boolean | undefined>('stakingNft/setIsFetchingApproval')

export const setIsFetchingPools = createAction<boolean | undefined>('stakingNft/setIsFetchingPools')

export const setIsFetchingCiviliansWorking = createAction<boolean | undefined>(
  'stakingNft/setIsFetchingCiviliansWorking',
)

export const setIsFetchingMyCivilians = createAction<boolean | undefined>('stakingNft/setIsFetchingCivilian')

export const setIsApprovalForAll = createAction<boolean | undefined>('stakingNft/setIsApprovalForAll')

export const stakeNft = createAction<any>('stakingNft/stakeNft')

export const withdrawNft = createAction<any>('stakingNft/withdrawNft')
