import {Link} from 'react-router-dom'
import React from 'react'
import {HeadBackHome} from './styled'

BackHome.propTypes = {}

function BackHome({onClick}) {
  return (
    <div>
      <HeadBackHome>
        {!onClick ? (
          <Link to="/">
            <img src="./images/fileDiviner/frame.png" alt="logo" />
          </Link>
        ) : (
          <Link to="/">
            <img src="./images/fileDiviner/frame.png" alt="frame.png" />
          </Link>
        )}
      </HeadBackHome>
    </div>
  )
}

export default BackHome
