import React from 'react'
import styled from 'styled-components'

function ReViewIcons({ styled: style = null, onClick, img = './images/civilian-fusion/question.svg' }) {
  return (
    <ReViewIconsStyled onClick={onClick} style={style}>
      <img src={img} alt="question.svg" />
    </ReViewIconsStyled>
  )
}

const ReViewIconsStyled = styled.div`
  position: absolute;
  top: 40px;
  right: 80px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: ease all 0.5s;
  &:hover {
    filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
      drop-shadow(2px -2px 2px #ffffff);
  }
`
export default ReViewIcons
