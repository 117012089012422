import styled from 'styled-components'

export const LongModalStyled = styled.div`
  background: transparent;
  padding: 0px 4em;

  p {
    color: #111111;
    font-size: 26px;
    font-weight: 700;
    margin: 0;
  }
`
export const BaseModalStyled = styled.div`
  position: relative;

  .jointables-price {
    .price {
      img {
        transition: ease all 0.5s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
`
