import {createSlice} from '@reduxjs/toolkit'
import {isEmpty, remove} from 'lodash'
import {
  setIsFetchingMyCivilians,
  setIsApprovalForAll,
  setIsFetchingApproval,
  setIsFetchingPools,
  stakeNft,
  withdrawNft,
  setIsFetchingCiviliansWorking,
} from './actions'
// eslint-disable-next-line
import fetchPoolsByRarity, {
  fetchIsApprovedForAll,
  fetchCiviliansWorking,
  fetchMyCivilian,
  fetchCiviliansStamina,
  fetchCiviliansPendingReward,
} from './fetchPool'

const initialState: any = {
  pools: {},
  isApprovalForAll: false,
  civiliansWorking: [],
  myCivilians: [],
  fetching: {
    pools: false,
    approval: false,
    civiliansWorking: false,
    myCivilians: false,
  },
}

export const stakingNftSlice = createSlice({
  name: 'StakingNft',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setIsApprovalForAll, (state, action) => {
        state.isApprovalForAll = action.payload
      })
      .addCase(setIsFetchingApproval, (state, action) => {
        state.fetching.approval = action.payload
      })
      .addCase(setIsFetchingPools, (state, action) => {
        state.fetching.pools = action.payload
      })
      .addCase(fetchPoolsByRarity.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        const {rarity, poolsByRarity} = action.payload as any
        // console.log('state.pools : ', state.pools[rarity])
        state.pools[rarity] = poolsByRarity
      })
      .addCase(fetchIsApprovedForAll.fulfilled, (state, action) => {
        state.isApprovalForAll = action.payload
      })
      .addCase(fetchCiviliansWorking.fulfilled, (state, action) => {
        state.civiliansWorking = action.payload
      })
      .addCase(fetchMyCivilian.fulfilled, (state, action) => {
        state.myCivilians = action.payload
      })
      .addCase(stakeNft, (state, action) => {
        const {tokenId, poolRating, poolRarity} = action.payload
        const index = state.myCivilians.findIndex((civilian) => civilian.tokenId === tokenId)
        if (index !== -1) {
          const civilianMoved = state.myCivilians[index]
          state.myCivilians.splice(index, 1)
          state.civiliansWorking.unshift({...civilianMoved, poolRating, poolRarity})
        }
      })
      .addCase(withdrawNft, (state, action) => {
        const index = state.civiliansWorking.findIndex((civilian) => civilian.tokenId === action.payload)
        if (index !== -1) {
          const civilianMoved = state.civiliansWorking[index]
          state.civiliansWorking.splice(index, 1)
          state.myCivilians.unshift(civilianMoved)
        }
      })
      .addCase(fetchCiviliansStamina.fulfilled, (state, action) => {
        const {staminaCiviliansWorking, staminaMyCivilians} = action.payload

        state.myCivilians = state.myCivilians.map((civilian, index) => ({
          ...civilian,
          stamina: staminaMyCivilians[index],
        }))

        state.civiliansWorking = state.civiliansWorking.map((civilian, index) => ({
          ...civilian,
          stamina: staminaCiviliansWorking[index],
        }))
      })
      .addCase(fetchCiviliansPendingReward.fulfilled, (state, action) => {
        const {pendingRewardCiviliansWorking, pendingRewardMyCivilians} = action.payload

        state.myCivilians = state.myCivilians.map((civilian, index) => ({
          ...civilian,
          pendingReward: pendingRewardMyCivilians[index],
        }))

        state.civiliansWorking = state.civiliansWorking.map((civilian, index) => ({
          ...civilian,
          pendingReward: pendingRewardCiviliansWorking[index],
        }))
      })
      .addCase(setIsFetchingCiviliansWorking, (state, action) => {
        state.fetching.civiliansWorking = action.payload
      })
      .addCase(setIsFetchingMyCivilians, (state, action) => {
        state.fetching.myCivilians = action.payload
      })
  },
})

export default stakingNftSlice.reducer
