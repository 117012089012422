export const PREDICTION_SLICE_NAME = 'prediction'

interface ACTION {
  [key: string]: string
}
export const PREDICTION_ACTION: ACTION = {
  FETCH_PREDICTION_USER_DATA: 'FETCH_PREDICTION_USER_DATA',
  FETCH_PREDICTION_POOL: 'FETCH_PREDICTION_POOL',
}
Object.keys(PREDICTION_ACTION).forEach((key) => {
  PREDICTION_ACTION[key] = `${PREDICTION_SLICE_NAME}/${PREDICTION_ACTION[key]}`
})
