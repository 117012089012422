import styled from 'styled-components'

import CloseButtonSVG from 'components2/svgIcon/CloseButtonSVG'
import WhiteBGWithNotch from './WhiteBGWithNotch'

const BaseModal = ({onClose, children, background = undefined, styleInside = {}, style = undefined}) => {
  return (
    <DailyMissionModalStyled background={background} style={style}>
      <div className="modal-background">
        <CloseButtonSVG className="close-btn" onClick={onClose} />
        <WhiteBGWithNotch style={{width: '100%', height: '100%'}} styleInside={styleInside}>
          {children}
        </WhiteBGWithNotch>
      </div>
    </DailyMissionModalStyled>
  )
}

const borderWidth = 20
const borderRadius = 30

const DailyMissionModalStyled = styled.div<any>`
  padding-top: 40px;
  width: 430px;
  height: 200px;
  --background-style: ${({background = 'linear-gradient(#78bcfc, #4f96da)'}) => background};

  .button__log--short {
    cursor: pointer;
    .beam-btn-display {
      &:hover {
        opacity: 0.5;
        transition: 0.5s ease-in-out;
      }
    }
  }

  .fixed-on-top.active {
    padding: 5px;
  }
  .modal-background {
    width: 430px;
    height: 100%;
    border-radius: ${borderRadius + borderWidth}px;
    position: relative;
    background: var(--background-style);
    padding: ${borderWidth}px;

    .close-btn {
      transform: translate(24%, -30%);
      width: 40px;
      height: 40px;
    }

    .bean-button {
      button:nth-child(1) {
        position: absolute;
        top: 0;
        left: 35%;
        transform: translate(-50%, -50%);
        width: 220px;
      }
      button:nth-child(2) {
        position: absolute;
        top: 0;
        left: 65%;
        transform: translate(-50%, -50%);
        width: 220px;
      }
    }
  }

  .fixed-on-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 420px;
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    transform: translate(30%, -30%);
    width: 70px;
    height: 70px;
    cursor: pointer;
  }
`
export default BaseModal
