export const GAME_SLICE_NAME = 'gamedb'

interface ACTION {
  [key: string]: string
}
export const GAME_ACTION: ACTION = {
  FETCH_USER_DATA: 'FETCH_USER_DATA',
  FETCH_USER_CIVILIANS: 'FETCH_USER_CIVILIANS',
  FETCH_GAME_SETTING: 'FETCH__GAME_SETTING',
  FETCH_REWARDS_POOL: 'FETCH_REWARDS_POOL',
}
Object.keys(GAME_ACTION).forEach((key) => {
  GAME_ACTION[key] = `${GAME_SLICE_NAME}/${GAME_ACTION[key]}`
})
