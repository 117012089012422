export const stakingNftPoolsConfig = {
  0: [1, 1.5, 2.4, 12, 60],
  1: [1.5, 2.25, 3.6, 18, 90],
  2: [4, 6, 9.6, 48, 240],
  3: [18, 27, 43.2, 216, 1080],
  4: [85, 127.5, 204, 1020, 5100],
}

export const stakingNftJob = {
  0: ['over time', 'finish task', 'sign contract', 'magic hat', 'make money'],
  1: ['catch criminals', 'draw a picture', 'digital paint', 'painting', 'solve the case'],
  2: ['tour', 'filming', 'read fan mail', 'win a trophy', 'compose music'],
  3: ['go to market', 'crop', 'repair', 'fire extinguisher', 'fire fight'],
  4: ['physical exam', 'emergency', 'vaccinate', 'cook dinner', 'cook dim sum'],
}

export const stakingNftImg = {
  0: [
    './images/staking-game/250x250/over-time.png',
    './images/staking-game/250x250/finish-task.png',
    './images/staking-game/250x250/sign-contract.png',
    './images/staking-game/250x250/magic-hat.png',
    './images/staking-game/250x250/make-money.png',
  ],
  1: [
    './images/staking-game/250x250/catch-criminals.png',
    './images/staking-game/250x250/draw-a-picture.png',
    './images/staking-game/250x250/digital-paint.png',
    './images/staking-game/250x250/painting.png',
    './images/staking-game/250x250/solve-the-case.png',
  ],
  2: [
    './images/staking-game/250x250/tour.png',
    './images/staking-game/250x250/filming.png',
    './images/staking-game/250x250/read-fan-mail.png',
    './images/staking-game/250x250/win-a-trophy.png',
    './images/staking-game/250x250/compose-music.png',
  ],
  3: [
    './images/staking-game/250x250/go-market.png',
    './images/staking-game/250x250/crop.png',
    './images/staking-game/250x250/repair.png',
    './images/staking-game/250x250/fire-extinguisher.png',
    './images/staking-game/250x250/fire-fight.png',
  ],
  4: [
    './images/staking-game/250x250/physical-exam.png',
    './images/staking-game/250x250/emergency.png',
    './images/staking-game/250x250/vaccinate.png',
    './images/staking-game/250x250/cook-dinner.png',
    './images/staking-game/250x250/cook-dimsum.png',
  ],
}

export const MAX_ENERGY = 864000
