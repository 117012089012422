// import JoinContentModal from '../components/JoinContentModal'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import CardValue from 'components/CardValue'
import ButtonDeposit from 'components2/Modal/ButtonDeposit'
import { priceList } from 'config/constants/prediction'
import { usePredictionWithdrawV2 } from 'hooks/usePrediction'
import { useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchPredictionPoolV2, fetchPredictionUserDataV2 } from 'state/predictionV2/actions'
import { usePredictionFromPid } from 'state/predictionV2/selector'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import JoinTopModal from 'components2/Modal/DepositWithdrawModal/components/JoinTopModal'
import Input from 'components2/Modal/DepositWithdrawModal/components/Input'
import LeaveBottomModal from './LeaveBottomModal'

function Leave({ onSelectPool, poolData, isLong }) {
  const [value, setValue] = useState('0')
  const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false)
  const { userData } = usePredictionFromPid(poolData.pid)
  const balances = new BigNumber(isLong ? userData?.longAmount : userData?.shortAmount)
  const formatBalance = getBalanceNumber(balances)
  const { onWithdraw } = usePredictionWithdrawV2(poolData.pid)
  const dispatch = useAppDispatch()
  const addTransaction = useTransactionAdder()
  const { account, chainId } = useWeb3React()

  const handleWithdraw = async () => {
    console.log('usePredictionWithdrawV2')
    if (value === '0' || value === '') return
    setIsWithdrawing(true)
    try {
      let tx
      if (isLong) tx = await onWithdraw(value, '0')
      else tx = await onWithdraw('0', value)

      tx.wait().then(() => {
        addTransaction(tx, {
          summary: 'Withdraw successfully!',
        })
        dispatch(fetchPredictionPoolV2())

        if (account) {
          dispatch(fetchPredictionUserDataV2(account))
        }
        setIsWithdrawing(false)
      })
    } catch (error) {
      console.log('error', error)
      setIsWithdrawing(false)
    }
  }

  const handleChangeValue = (e) => {
    if (
      parseFloat(e.target.value) >
      new BigNumber(isLong ? userData?.longAmount : userData?.shortAmount).div(1e18).toNumber()
    )
      return
    if (parseFloat(e.target.value) < 0) return

    setValue(e.target.value)
  }

  const handleChangeValueWithPercent = (percent: number) => {
    if (userData) {
      if (percent === 100)
        setValue(
          balances
            // .multipliedBy(percent / 100)
            .div(1e18)
            .toString(),
        )
      else
        setValue(
          balances
            .multipliedBy(percent / 100)
            .div(1e18)
            .toFixed(2)
            .toString(),
        )
    }
  }
  return (
    <>
      <JoinTopModal poolData={poolData} onSelectPool={onSelectPool} setValue={setValue} />
      <JoinTablesContent>
        <p>How much?</p>
        <Input value={value} type="number" onChange={handleChangeValue} />
        <p>
          Deposited:{' '}
          <span>
            <CardValue value={formatBalance || 0} decimals={2}></CardValue>
          </span>
        </p>

        <div className="jointables-price">
          <div className="price">
            {priceList.map((price) => (
              <img
                key={price.id}
                src={price.src}
                alt="Price10.png"
                onClick={() => handleChangeValueWithPercent(price.price)}
              />
            ))}
          </div>
        </div>
      </JoinTablesContent>
      <LeaveBottomModal value={value} />
      <ButtonDeposit title="Withdraw" onDeposit={handleWithdraw} isLoading={isWithdrawing} />
    </>
  )
}

const JoinTablesContent = styled.div`
  .price {
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
      width: 70px;
    }
  }
`
export default Leave
