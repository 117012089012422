import LoadingFullScreen from 'components2/Common/LoadingFullScreen'
import React from 'react'
import styled from 'styled-components'

function PageTutorial({ Styled, onClose, Background = "url('./images/civilian-fusion/review-fusion.png')" }) {
  return (
    <TutorialStyled style={{ display: Styled, background: Background }}>
      <img
        src="./images/civilian-fusion/close-tutorial.svg"
        alt="close-tutorial"
        className="close__tutorial"
        onClick={onClose}
      />
    </TutorialStyled>
  )
}

const TutorialStyled = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  animation: ease 0.5s opacity;
  z-index: 1000;
  justify-content: flex-end;
  .close__tutorial {
    width: 40px;
    height: 40px;
    margin-top: 40px;
    margin-right: 80px;
    z-index: 100;
    /* position: absolute; */
    cursor: pointer;
    transition: ease all 0.5s;
    &:hover {
      filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
        drop-shadow(2px -2px 2px #ffffff);
    }
  }

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
export default PageTutorial
