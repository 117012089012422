import {createAsyncThunk} from '@reduxjs/toolkit'
import {BigNumber, ethers, Overrides} from 'ethers'
import {groupBy, map, values as _values, merge, keyBy} from 'lodash'
import marketplaceApi from 'config/abi/DivinerMarketplace.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import characterAbi from 'config/abi/character.json'
import {
  getMarketplaceAddress,
  getFakeCharacterAddress,
  getCharacterAddress,
  getNFTsTransferProxyAddress,
} from 'utils/addressHelpers'
import {getMarketplaceContract} from 'utils/contractHelpers'
import {_getCiviliansByIds, _getListingDataByIdentifiers} from 'state/marketplace/utils'
import {fetchingMarket, updateMarketPage} from 'state/marketplace/actions/actions'
import {State} from 'state/types'
import {Listing} from 'state/marketplace/types/types'

export const fetchSellerFee = createAsyncThunk('marketplace/fetchSellerFee', async (_) => {
  const marketplaceContract = getMarketplaceContract()
  try {
    return (await marketplaceContract.sellerFee()).toString()
  } catch (e) {
    console.log(e)
  }
})

export const fetchBuyerFee = createAsyncThunk('marketplace/fetchBuyerFee', async (_) => {
  const marketplaceContract = getMarketplaceContract()
  try {
    return (await marketplaceContract.buyerFee()).toString()
  } catch (e) {
    console.log(e)
  }
})

const fetchingMarketDataByPage = async (counterPage, limit) => {
  const marketplaceContract = getMarketplaceContract()

  const tokenListed = await marketplaceContract?.getListedTokenIdByPage(
    BigNumber.from(counterPage),
    BigNumber.from(limit),
  )
  const listingCharacters = await _getListingDataByIdentifiers(
    getMarketplaceAddress(),
    marketplaceApi,
    'getListingByIdentifier',
    tokenListed,
  )
  let groupedListing: any = {
    [getCharacterAddress()]: [],
    [getFakeCharacterAddress()]: [],
  }

  groupedListing = {...groupedListing, ...(groupBy(listingCharacters, 'listingNft') as any)}
  const charIds = map(groupedListing[getCharacterAddress()], 'id')
  const fakeCharIds = map(groupedListing[getFakeCharacterAddress()], 'id')

  return {
    tokenListed,
    charIds,
    fakeCharIds,
    groupedListing,
  }
}

export const fetchListingCivilians = createAsyncThunk(
  'marketplace/fetchListingCivilians',
  async (_, {getState, dispatch}) => {
    try {
      let characters = []
      let characterIds = []
      let fakeCharacterIds = []
      let groupedListingCharacters = {
        [getCharacterAddress()]: [],
        [getFakeCharacterAddress()]: [],
      }

      const {
        marketplace: {
          pagination: {limit, marketPage},
        },
      } = getState() as State

      let counterPage = marketPage
      let {tokenListed, charIds, fakeCharIds, groupedListing}: any = await fetchingMarketDataByPage(counterPage, limit)

      characterIds = [...characterIds, ...charIds]
      fakeCharacterIds = [...fakeCharacterIds, ...fakeCharIds]
      groupedListingCharacters = {
        [getCharacterAddress()]: [
          ...groupedListingCharacters[getCharacterAddress()],
          ...groupedListing[getCharacterAddress()],
        ],
        [getFakeCharacterAddress()]: [
          ...groupedListingCharacters[getFakeCharacterAddress()],
          ...groupedListing[getFakeCharacterAddress()],
        ],
      }

      let validDataLength = characterIds.length + fakeCharacterIds.length

      while (tokenListed.length >= limit && validDataLength < limit) {
        const result = await fetchingMarketDataByPage(++counterPage, limit)
        tokenListed = result.tokenListed
        charIds = result.charIds
        fakeCharIds = result.fakeCharIds
        groupedListing = result.groupedListing

        characterIds = [...characterIds, ...charIds]
        fakeCharacterIds = [...fakeCharacterIds, ...fakeCharIds]
        groupedListingCharacters = {
          [getCharacterAddress()]: [
            ...groupedListingCharacters[getCharacterAddress()],
            ...groupedListing[getCharacterAddress()],
          ],
          [getFakeCharacterAddress()]: [
            ...groupedListingCharacters[getFakeCharacterAddress()],
            ...groupedListing[getFakeCharacterAddress()],
          ],
        }

        validDataLength = characterIds.length + fakeCharacterIds.length
      }

      await Promise.all([
        _getCiviliansByIds(getCharacterAddress(), characterAbi, 'getHero', characterIds, true),
        _getCiviliansByIds(getFakeCharacterAddress(), fakeCharacterAbi, 'getHero', fakeCharacterIds, false),
      ]).then((values) => {
        characters = [
          ..._values(merge(keyBy(values[0], '_id'), keyBy(groupedListingCharacters[getCharacterAddress()], '_id'))),
          ..._values(merge(keyBy(values[1], '_id'), keyBy(groupedListingCharacters[getFakeCharacterAddress()], '_id'))),
        ]
      })

      dispatch(fetchingMarket(false))
      dispatch(updateMarketPage(counterPage))

      return characters
    } catch (e) {
      return []
    }
  },
)

export const addListing = createAsyncThunk(
  'marketplace/addListing',
  async ({account, id, price, paymentToken, listingNft, options}: Listing, {getState}) => {
    try {
      const {
        marketplace: {
          fee: {sellerFee},
        },
      } = getState() as State
      let tx: any = null

      // Approve for transfer NFT
      if ((await options.approveNftContract?.getApproved(BigNumber.from(id))) !== getNFTsTransferProxyAddress()) {
        const approveNftTx = await options.approveNftContract.approve(getNFTsTransferProxyAddress(), BigNumber.from(id))
        const resultApproveNft = await _processingTransaction(
          approveNftTx,
          'Approve successfully',
          'Approve failure',
          options.showPopupTransaction,
        )
        if (!resultApproveNft) {
          options.updateProcessingStat()
          return {}
        }
      }

      // Add listing
      if (paymentToken === ethers.constants.AddressZero) {
        const feeRequired = ethers.utils
          .parseEther(price.toString())
          .mul(BigNumber.from(sellerFee))
          .div(BigNumber.from(100))

        const overrides = {
          value: feeRequired,
        }
        tx = await options.contract?.addListing(
          BigNumber.from(id),
          ethers.utils.parseEther(price.toString()),
          paymentToken,
          listingNft,
          overrides as Overrides,
        )
      } else {
        tx = await options.contract?.addListing(
          BigNumber.from(id),
          ethers.utils.parseEther(price.toString()),
          paymentToken,
          listingNft,
        )
      }

      const resultAddListing = await _processingTransaction(
        tx,
        'Add listing successfully',
        'Add listing failure',
        options.showPopupTransaction,
      )
      if (!resultAddListing) {
        options.updateProcessingStat()
        return {}
      }

      const civilian = (getState() as State).marketplace.inventoryCharacters[`${listingNft}_${id}`]
      options.updateProcessingStat()
      return {
        ...civilian,
        seller: account,
        paymentToken,
        listingNft,
        id,
        price,
      }
    } catch (e) {
      console.log(e)
      options.updateProcessingStat()
      return {}
    }
  },
)

export const cancelListing = createAsyncThunk(
  'marketplace/cancelListing',
  async ({listingNft, id, options}: any, {getState}) => {
    try {
      const tx = await options.contract?.cancelListing(listingNft, BigNumber.from(id))
      const resultCancel = await _processingTransaction(
        tx,
        'Cancel listing successfully',
        'Cancel listing failure',
        options.showPopupTransaction,
      )
      if (!resultCancel) {
        options.updateProcessingStat()
        return {}
      }

      const civilian = (getState() as State).marketplace.inventoryCharacters[`${listingNft}_${id}`]
      options.updateProcessingStat()
      return {
        ...civilian,
        seller: '',
        paymentToken: '',
        listingNft: '',
        id: '',
        price: '',
      }
    } catch (e) {
      options.updateProcessingStat()
      return {}
    }
  },
)

export const purchaseListing = createAsyncThunk(
  'marketplace/purchaseListing',
  async ({listingNft, id, price, paymentToken, options}: any, {getState}) => {
    try {
      const {
        marketplace: {
          fee: {buyerFee},
        },
      } = getState() as State
      let tx: any = null

      if (paymentToken === ethers.constants.AddressZero) {
        const feeRequired = ethers.utils
          .parseEther(price.toString())
          .mul(BigNumber.from(buyerFee).add(BigNumber.from(100)))
          .div(BigNumber.from(100))

        const overrides = {
          value: feeRequired,
        }
        tx = await options.contract?.purchaseListing(listingNft, BigNumber.from(id), overrides as Overrides)
      } else {
        tx = await options.contract?.purchaseListing(listingNft, BigNumber.from(id))
      }

      const resultPurchase = await _processingTransaction(
        tx,
        'Purchased successfully',
        'Purchased failure',
        options.showPopupTransaction,
      )
      if (!resultPurchase) {
        options.updateProcessingStat()
        return {}
      }

      const civilian = (getState() as State).marketplace.listing[`${listingNft}_${id}`]
      options.updateProcessingStat()
      return {
        ...civilian,
        seller: '',
        paymentToken: '',
        listingNft: '',
        id: '',
        price: '',
      }
    } catch (e) {
      options.updateProcessingStat()
      return {}
    }
  },
)

const _processingTransaction = async (tx: any, message: string, error: string, showPopupTransaction) => {
  try {
    await tx.wait()

    if (showPopupTransaction) {
      showPopupTransaction(tx, {
        summary: message,
      })
    }

    return true
  } catch (e) {
    if (showPopupTransaction) {
      showPopupTransaction(tx, {
        summary: error,
      })
    }

    return false
  }
}
