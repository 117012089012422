import { useWeb3React } from '@web3-react/core'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Back from 'views2/Games/components/Back'
import PageLoading from 'views2/Games/components/PageLoadding'
import ReViewIcons from 'views2/Games/components/ReViewIcons'
import TitleCivilian from 'views2/Games/components/TitleCivilian'
import PageReview from 'views2/Games/PageReview'
import { useAppDispatch } from '../../../../state/index'
import fetchPoolsByRarity, {
  fetchMyCivilian,
  fetchCiviliansWorking,
  fetchIsApprovedForAll,
} from '../../../../state/stakingNft/fetchPool'
import { State } from '../../../../state/types'
import CivilianIdle from './Components/CivilianIdle'
import StakingNftItem from './Components/StakingNftItem'

function StakingNft(props) {
  const [tutorial, setTutorial] = useState(true)
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const pools = useSelector((state: State) => state.stakingNft.pools)
  const rating = new URLSearchParams(search).get('rating')
  const rarity = new URLSearchParams(search).get('rarity')
  // const fetchCivilian1Time = useRef(false)

  const pool = !isEmpty(pools) && pools[parseInt(rarity) - 1][parseInt(rating) - 1]

  const myCivilians = useSelector((state: State) => state.stakingNft.myCivilians)
  const civiliansWorking = useSelector((state: State) => state.stakingNft.civiliansWorking)

  useEffect(() => {
    if (isEmpty(pools)) {
      dispatch(fetchPoolsByRarity(parseInt(rarity) - 1))
    }
  }, [pools])

  useEffect(() => {
    if (account && isEmpty(myCivilians) && isEmpty(civiliansWorking)) {
      dispatch(fetchMyCivilian(account))
      dispatch(fetchCiviliansWorking(account))
      dispatch(fetchIsApprovedForAll(account))
    }
  }, [account])

  const myCiviliansQualifiedForPool = myCivilians.filter(
    (civilian) =>
      parseInt(civilian.heroRating) + 1 >= parseInt(rating) && parseInt(civilian.heroRarity) + 1 >= parseInt(rarity),
  )

  const civiliansWorkingQualifiedForPool = civiliansWorking.filter(
    (civilian) =>
      parseInt(civilian.poolRating) + 1 === parseInt(rating) && parseInt(civilian.poolRarity) + 1 === parseInt(rarity),
  )

  return (
    <StakingNftStyled>
      <TitleCivilian title="Staking NFT" />
      <Back to="/staking" />
      <ReViewIcons onClick={() => setTutorial(!tutorial)} />
      <PageReview
        Styled={tutorial ? 'none' : 'block'}
        Background="url('./images/civilian-fusion/review-staking-nfts.png')"
        onClose={() => setTutorial(!tutorial)}
      />
      <StakingContentStyled>
        {isEmpty(pools) ? (
          <PageLoading />
        ) : (
          <div className="staking__nft__styled">
            <div className="staking__nft__left">
              <div className="staking__nft__left-title">
                <h3>{pool.jobStaking.toUpperCase()}</h3>
              </div>
              <div className="staking__nft__left-content">
                <div className="content__img">
                  <img src={pool.imgStaking} alt="enjoy-desk" />
                </div>
                <div className="staking__nft-border"></div>
                <StakingCharacter>
                  <span>ID</span>
                  <span className="staking__character-left">{(parseInt(rating) - 1) * 5 + parseInt(rarity)}</span>
                </StakingCharacter>
                <StakingCharacter>
                  <span>Remaining slot</span>
                  <span className="staking__character-left">
                    {parseInt(pool.limit) - parseInt(pool.amount)}/{parseInt(pool.limit)}
                  </span>
                </StakingCharacter>
                <StakingCharacter>
                  <span>Rarity</span>
                  <span className="staking__character-left">
                    <div className="left__img">
                      {[...Array(parseInt(rarity))].map(() => (
                        <img src="./images/games/star.svg" alt="star" />
                      ))}
                    </div>
                  </span>
                </StakingCharacter>
                <StakingCharacter>
                  <span>Rating</span>
                  <span className="staking__character-left">{rating}</span>
                </StakingCharacter>
                <StakingCharacter>
                  <span>Daily earn</span>
                  <span className="staking__character-left">{pool.interestPerDay} DPT/day</span>
                </StakingCharacter>
              </div>
            </div>
            <div className="staking__nft__right">
              <div className="staking__nft__right-bg">
                {myCiviliansQualifiedForPool.length === 0 && civiliansWorkingQualifiedForPool.length === 0 ? (
                  <h3 className="title__no_civilian">No Civilian meet requirement for this Stake Pool.</h3>
                ) : (
                  <>
                    {civiliansWorkingQualifiedForPool.map((civilian) => (
                      <StakingNftItem civilian={{ ...civilian, rating }} />
                    ))}

                    {myCiviliansQualifiedForPool.map((civilian) => (
                      <CivilianIdle civilian={civilian} poolRating={rating} poolRarity={rarity} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </StakingContentStyled>
    </StakingNftStyled>
  )
}

const StakingNftStyled = styled.div`
  background: url('./images/staking-nft-game/bg-staking-nft.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 1080px;
  width: 1920px;
  .staking__question {
    position: absolute;
    top: 40px;
    right: 80px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: ease all 0.5s;
    &:hover {
      filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
        drop-shadow(2px -2px 2px #ffffff);
    }
  }
`
const StakingContentStyled = styled.div`
  position: absolute;
  top: 160px;
  left: 90px;
  .staking__nft__styled {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1760px;
    .staking__nft__left {
      width: 666px;
      height: 838px;
      border-radius: 16px;
      background: linear-gradient(180deg, #cfe1fd77 0%, #98bff87c 100%);

      .staking__nft__left-title {
        background: #293162;
        width: 666px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        h3 {
          margin: 0;
          color: #fff;
          font-size: 28px;
          font-weight: 400;
        }
      }
      .staking__nft__left-content {
        padding: 0px 82px;
        text-align: center;
        .staking__nft-border {
          width: 100%;
          height: 1px;
          background: #b6b6b6;
          margin: 6px 0px 24px 0px;
        }
        .content__img {
          img {
            max-width: 450px;
            max-height: 450px;
            margin: 0 auto;
            padding-top: 24px;
            padding-bottom: 16px;
          }
        }
      }
    }
    .staking__nft__right {
      height: 838px;
      padding: 16px;
      width: 1050px;
      border-radius: 16px;
      background: linear-gradient(180deg, #78bcfc 0%, #4f96da 100%);

      .staking__nft__right-bg {
        background: linear-gradient(180deg, #447ed5 0%, #1956b3 100%);
        width: 100%;
        height: 100%;
        border-radius: 16px;
        display: grid;
        overflow-y: scroll;
        padding-bottom: 20px;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        position: relative;
        &::-webkit-scrollbar {
          width: 12px;
          background: unset;
          border-radius: 27px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 27px;
          background: #94bfff;
        }
        .title__no_civilian {
          margin: 0;
          color: #fff;
          font-size: 28px;
          font-weight: 400;
          position: absolute;
          top: 50%;
          left: 55%;
          transform: translate(-50%, -50%);
          width: 64%;
        }
      }
    }
  }
`

const StakingCharacter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 15px;
  .staking__character-left {
    .left__img {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`

export default StakingNft
