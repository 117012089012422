import {MAX_ENERGY} from 'config/constants/stakingNft'
import React from 'react'
import styled from 'styled-components'
import ButtonStaking, {ButtonViewWorkingNFT} from 'views2/Games/Staking/StakingNft/ButtonStaking'
import CivilianItem from './CivilianItem'

function HistoryStaking({civilian, percentStamina}) {
  return (
    <HistoryStakingStyled className="history__staking__styled">
      <CivilianItem civilian={civilian} percentEnergy={Math.floor((civilian.stamina / MAX_ENERGY) * 100)} />
      <CivilianBottomStyled>
        <CivilianCharacter>
          <span>Job ID</span>
          <span className="civilian__character-right">#01</span>
        </CivilianCharacter>
        <CivilianCharacter>
          <span>Working power</span>
          <span className="civilian__character-right">{percentStamina}%</span>
        </CivilianCharacter>
        <CivilianCharacter>
          <span>DPT earning</span>
          <span className="civilian__character-right" style={{color: '#55B3F8'}}>
            {civilian.pendingReward}
          </span>
        </CivilianCharacter>
      </CivilianBottomStyled>
      <ButtonViewWorkingNFT
        nameButton="View"
        bgStyled={
          percentStamina === 100
            ? {background: 'linear-gradient(91.23deg, #F01111 18.05%, #FF9254 97.97%)'}
            : {background: 'linear-gradient(90.93deg, #162ADB 12.33%, #65A3FF 97.08%)'}
        }
        poolRating={civilian.poolRating}
        poolRarity={civilian.poolRarity}
        tokenId={civilian.tokenId}
      ></ButtonViewWorkingNFT>
    </HistoryStakingStyled>
  )
}

const HistoryStakingStyled = styled.div`
  width: 330px;
  height: 606px;
  padding: 20px;
  background: linear-gradient(180deg, #0f3072 0%, rgba(0, 0, 2, 0.6) 100%);
  border-radius: 12px;
  margin: 60px 12px 0px 12px;
`
const CivilianCharacter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999999;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 16px;
  &:nth-child(1) {
    padding-top: 16px;
  }
  .civilian__character-right {
    /* font-weight: 700; */
    color: #fff;
  }
  img {
    width: 24px;
    height: 24px;
    margin-left: 3px;
  }
`
const CivilianBottomStyled = styled.div`
  .civilian__character-border {
    width: 100%;
    height: 1px;
    background: #b6b6b6;
    margin-bottom: 16px;
  }
`
export default HistoryStaking
