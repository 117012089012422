import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ChestMarketState} from '../types'
import {fetchChestData} from './fetchChestData'
import {fetchAmountChestUser, fetchGameUserTokenInfo, fetchUserCharacter} from './fetchGameUserData'

const initialState: ChestMarketState = {
  activeCharacterId: 0,
  chests: [
    {
      id: 0,
      dptToBuy: '0',
      remainChest: '0',
    },
    {
      id: 1,
      dptToBuy: '0',
      remainChest: '0',
    },
    {
      id: 2,
      dptToBuy: '0',
      remainChest: '0',
    },
  ],
  userData: {
    balance: '0',
    allowance: '0',
    amountChest: '0',
    amountChar: [],
    characters: [],
  },
  isLoading: true,
}

export const fetchGameUserData = createAsyncThunk('chest/FetchGameUserData', async (account: string) => {
  const fetchGameUserTokenInfoPromise = fetchGameUserTokenInfo(account)
  const fetchUserCharacterPromise = fetchUserCharacter(account)
  const fetchAmountChestUserPromise = fetchAmountChestUser(account)

  const [userTokenData, amountChest, characters] = await Promise.all([
    fetchGameUserTokenInfoPromise,
    fetchAmountChestUserPromise,
    fetchUserCharacterPromise,
  ])

  return {
    userTokenData,
    amountChest,
    characters,
  }
})

export const fetchChest = createAsyncThunk('chest/FetchChest', async () => {
  const chestData = await fetchChestData()

  return chestData
})

export const gameSlice = createSlice({
  name: 'ChestMarket',
  initialState,
  reducers: {
    setActiveRoundId: (state, action) => {
      state.activeCharacterId = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchChest.fulfilled, (state, action) => {
      state.chests = action.payload.map((chest, index) => {
        return {id: index, ...chest}
      })
    })
    builder.addCase(fetchGameUserData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchGameUserData.fulfilled, (state, action) => {
      const {userTokenData, amountChest, characters} = action.payload
      state.userData.balance = userTokenData.dptBalance
      state.userData.allowance = userTokenData.dptAllowance
      state.userData.amountChest = amountChest
      state.userData.characters = characters
      for (let i = 0; i < 5; i++) {
        state.userData.amountChar[i] = characters.reduce((prev, cur) => {
          if (cur.heroRarity === i.toString()) return ++prev
          return prev
        }, 0)
      }

      state.isLoading = false
    })
  },
})

// thunk

// actions
export const {setActiveRoundId} = gameSlice.actions

export default gameSlice.reducer
