import { LoadingOutlined } from '@ant-design/icons'
import { AutoRenewIcon } from '@diviner-protocol/uikit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import CardValue from 'components/CardValue'
import ConnectWalletModal from 'components2/Modal/ConnectWalletModal'
import GlobalModal from 'components2/static/GlobalModal'
import { DECIMALS_BID_PRICE, DEPOSIT_DURATION, LOCK_DURATION } from 'config/constants/prediction'
import { usePredictionContractsV1 } from 'hooks/useContract'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { setPredictionState } from 'state/predictionV1'
import { useActiveRoundIdSelectorV1, usePredictionV1, usePriceInPool } from 'state/predictionV1/selector'
import { useTransactionAdder } from 'state/transactions/hooks'
import { getBalanceNumber } from 'utils/formatBalance'
import { formatUnits, plus } from 'utils/formatNumber'
import { getTimeRemainDraw } from 'utils/time'
import { BackgroundPredictionStyled } from './BackgroundPrediction'
import { APYStyled, ClaimRewardStyled, ExperiencePriceStyled, PredictionRowInfoStyled } from './styled'

const spinnerIcon = <AutoRenewIcon spin color="currentColor" />

function PredictionRoundInfoV1({ widthGreen, widthRed, setWidthGreen, setWidthRed }) {
  const { status, predictionPools, longApy, shortApy } = usePredictionV1()
  const { initialPrice, isLocking, lastUpdatedAt } = status
  const [pidSelected, setPidSelected] = useState<number>(0)
  const [tradeVolume, setTradeVolume] = useState<BigNumber>(new BigNumber(0))
  const [longApyPercent, setLongApyPercent] = useState<number>(0)
  const [timeCountdown, setTimeCountdown] = useState<number>(null)
  const [countdown, setCountdown] = useState<string>(null)
  const [isSettling, setIsSettling] = useState<boolean>(false)
  const [isClaiming, setIsClaiming] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { account, chainId } = useWeb3React()
  const addTransaction = useTransactionAdder()
  const activeRoundId = useActiveRoundIdSelectorV1()
  const predictionContract = usePredictionContractsV1()
  const price = usePriceInPool()

  useEffect(() => {
    let totalLongPercent = 0
    let countPoolDeposited = 0
    let totalTradeBusd = new BigNumber(0)
    predictionPools.forEach((predictionPool, index) => {
      const { totalLongSupply, totalShortSupply } = predictionPool
      if (totalLongSupply !== '0' || totalShortSupply !== '0') {
        const totalSupply = plus(totalLongSupply, totalShortSupply)
        totalLongPercent += parseFloat(new BigNumber(totalLongSupply).div(totalSupply).times(100).toFormat(1))

        totalTradeBusd = totalTradeBusd.plus(totalSupply.multipliedBy(price[index]))
        countPoolDeposited++
      }
    })
    if (countPoolDeposited === 0) {
      setWidthGreen(50)
      setWidthRed(50)
      setTradeVolume(totalTradeBusd)
      return
    }
    const longPercent = totalLongPercent / countPoolDeposited

    setTradeVolume(totalTradeBusd)
    setWidthGreen(longPercent)
    setWidthRed(100 - longPercent)
  }, [pidSelected, predictionPools])

  useEffect(() => {
    setLongApyPercent((longApy / (longApy + shortApy)) * 100)
  })

  useEffect(() => {
    const now = Date.now()

    if (lastUpdatedAt !== null) {
      if (isLocking) {
        setTimeCountdown(lastUpdatedAt + LOCK_DURATION)
        if (now > lastUpdatedAt + LOCK_DURATION) {
          setIsSettling(true)
        } else if (isSettling) setIsSettling(false)
      } else {
        setTimeCountdown(lastUpdatedAt + DEPOSIT_DURATION)
        if (now > lastUpdatedAt + DEPOSIT_DURATION) {
          setIsSettling(true)
        } else if (isSettling) setIsSettling(false)
      }
    }
  }, [status])

  useEffect(() => {
    let interval
    if (timeCountdown !== null) {
      interval = setInterval(() => {
        setCountdown(getTimeRemainDraw(timeCountdown))
      }, 1000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [timeCountdown])

  const handleSelectPool = (pid) => {
    setPidSelected(parseInt(pid))
  }

  const claimReward = async () => {
    try {
      setIsClaiming(true)
      const tx = await predictionContract[activeRoundId].claimAllReward()
      tx.wait()
        .then(() => {
          addTransaction(tx, { summary: 'Claim rewards successfully!' })
          setIsClaiming(false)
        })
        .catch(() => {
          addTransaction(tx, { summary: 'Claim rewards fail!' })
          setIsClaiming(false)
        })
    } catch (error) {
      setIsClaiming(false)
      console.log('claim error : ', error)
    }
  }

  const handleChangeRound = (value: number) => {
    const newActiveRoundId = activeRoundId + value
    if (newActiveRoundId >= 0 && newActiveRoundId <= 2)
      dispatch(setPredictionState({ activeRoundId: newActiveRoundId }))
  }

  return (
    <div>
      <BackgroundPredictionStyled>
        <div className="slick-prediction Left">
          <img src="./images/fileDiviner/slick-left.png" alt="slick-left.png" onClick={() => handleChangeRound(-1)} />
        </div>
        <div className="slick-prediction Right">
          <img src="./images/fileDiviner/slick-right.png" alt="slick-right.png" onClick={() => handleChangeRound(1)} />
        </div>
        <PredictionRowInfoStyled>
          <div className="experience-table ">
            <h2>
              Pool {activeRoundId + 1}
              {status && status?.isLocking ? ' (Locking)' : ''}
            </h2>
            <p>
              Will the BNB price be higher than
              <span>
                {` $${initialPrice ? formatUnits(new BigNumber(initialPrice), DECIMALS_BID_PRICE).toFormat(2) : '_'} `}
              </span>
              ?
              {/* when the game ends
              <span> {countdown}</span> */}
            </p>
            {/* <JoinTopModal poolData={predictionPools[pidSelected]} onSelectPool={handleSelectPool} /> */}
            <div className="market-trade">
              <div className="market-trade-content">
                {isSettling ? (
                  <div>
                    <p>Waiting to settle</p>
                    <p>
                      <LoadingOutlined />
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>{isLocking ? 'Locking time' : 'Deposit time:'}</p>
                    <p>{countdown}</p>
                  </div>
                )}
              </div>
              <div className="market-trade-content">
                <p>Trade volume</p>
                <p>
                  <CardValue value={getBalanceNumber(tradeVolume)} decimals={2} prefix="$"></CardValue>
                </p>
              </div>
            </div>

            <APYStyled>
              <div className="experience-apy">
                {longApyPercent === 100 ? (
                  <span style={{ width: longApyPercent + '%', borderRadius: '10px' }}></span>
                ) : (
                  <span style={{ width: longApyPercent + '%' }}></span>
                )}

                {longApyPercent === 0 ? (
                  <span style={{ width: 100 - longApyPercent + '%', borderRadius: '10px' }}></span>
                ) : (
                  <span style={{ width: 100 - longApyPercent + '%' }}></span>
                )}
              </div>
              <div className="apy">
                <div className="price-apy">
                  <div className="pricel-apy">
                    <span>
                      <CardValue value={longApy || 0} decimals={0} text="%"></CardValue>
                    </span>
                  </div>
                  <span>APY</span>
                  <img src="./images/fileDiviner/group2.png" alt="Group2.png" />
                </div>
                <div className="price-apy">
                  <div className="pricer-apy">
                    <span>
                      <CardValue value={shortApy || 0} decimals={0} text="%"></CardValue>
                    </span>
                  </div>
                  <span>APY</span>
                  <img src="./images/fileDiviner/group2.png" alt="Group2.png" />
                </div>
              </div>
            </APYStyled>

            <ExperiencePriceStyled>
              <div className="ExperiencePriceTitle">
                <p>
                  <CardValue
                    value={
                      parseFloat(
                        new BigNumber(predictionPools[pidSelected]?.userData?.pendingCake)
                          .div(1e18)
                          .toFixed(4)
                          .toString(),
                      ) || 0
                    }
                    decimals={4}
                  ></CardValue>
                </p>
                <div className="ImgPrice">
                  <img src="./images/fileDiviner/pancakeswap.png" alt="Group.png" />
                </div>
              </div>

              <div className="ExperiencePriceTitle">
                <p>
                  <CardValue
                    value={
                      parseFloat(
                        new BigNumber(predictionPools[pidSelected]?.userData?.pendingDPT)
                          .div(1e18)
                          .toFixed(4)
                          .toString(),
                      ) || 0
                    }
                    decimals={2}
                  ></CardValue>
                </p>
                <div className="ImgPrice">
                  <img src="./images/fileDiviner/frameprice.png" alt="frameprice.png" />
                </div>
              </div>
            </ExperiencePriceStyled>

            <div className="LearnMore">
              <img src="./images/fileDiviner/learnmore.png" alt="Learnmore.png" />
              <Link to="/">Learn more</Link>
            </div>

            <ClaimRewardStyled
              onClick={() => {
                if (!account) GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
                else claimReward()
              }}
              disabled={
                isClaiming ||
                (predictionPools[pidSelected]?.userData?.pendingCake === '0' &&
                  predictionPools[pidSelected]?.userData?.pendingDPT === '0')
              }
            >
              Claim rewards {isClaiming && spinnerIcon}
            </ClaimRewardStyled>
          </div>
        </PredictionRowInfoStyled>
      </BackgroundPredictionStyled>
    </div>
  )
}

export default PredictionRoundInfoV1
