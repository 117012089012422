import React, { useEffect, useRef, useState } from 'react'
import ConnectWalletModal from 'components2/Modal/ConnectWalletModal'
import DailyMissionModal from 'components2/Modal/DailyMissionModal'
import GlobalModal from 'components2/static/GlobalModal'
import styled from 'styled-components'
import CenterHead from './Components/HeadCenter'
import { BoxHeader, HeaderMain, Left, Right } from './styled-main'
import MusicButton from './Components/MusicButton'

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'block' : 'none')};
  position: absolute;
  bottom: -22px;
  right: 0;
  left: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.contrast};
  color: ${({ theme }) => theme.colors.invertedContrast};
  border-radius: 16px;
  opacity: 0.7;
`

export default function Header({ account, logout }) {
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState<boolean>(false)
  const trimAccount = account ? `${account.slice(0, 4)}...${account.slice(-4)}` : '0x00...0000' // account length: 42

  return (
    <HeaderMain>
      <BoxHeader>
        <Left>
          <div className="avar">
            <img src="./images/home/avatar.png" alt="" />
            <span className="name">Diviner Protocol</span>
            <span className="id-number">
              {trimAccount}
              <img
                src="./images/home/icon-copy.png"
                alt=""
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard.writeText(account)
                    setIsTooltipDisplayed(true)
                    setTimeout(() => {
                      setIsTooltipDisplayed(false)
                    }, 1000)
                  }
                }}
              />
              <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
            </span>
          </div>

          <div className="daily">
            <img
              src="./images/home/daily-mision.png"
              alt="daily-mission"
              onClick={() => {
                GlobalModal.show(<DailyMissionModal onClose={GlobalModal.hide} />)
              }}
            />
          </div>
        </Left>

        <CenterHead />

        <Right>
          <div className="box-icon">
            {account ? (
              <img src="./images/home/logout-wallet.png" alt="" className="connect" onClick={logout} />
            ) : (
              <img
                src="./images/home/connect-wallet.png"
                alt=""
                className="connect"
                onClick={() => {
                  GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
                }}
              />
            )}
            <img src="./images/home/news.png" alt="" className="news" />
            <img src="./images/home/handbook.png" alt="" className="handbook" />
            <img src="./images/home/leader-board.png" alt="" className="leaderboard" />
            <img src="./images/home/friends.png" alt="" className="friends" />
            <img src="./images/home/setting.png" alt="" className="setting" />
            {/* <MusicButton music="./home-music.mp3"/> */}
            <MusicButton music="/" />
          </div>
        </Right>
      </BoxHeader>
    </HeaderMain>
  )
}
