import { useWeb3React } from '@web3-react/core'
import { Button, Progress } from 'antd'
import ConnectWalletModal from 'components2/Modal/ConnectWalletModal'
// import SpinnerMachine from 'components2/SpinnerMachine/SpinnerMachine'
import GlobalModal from 'components2/static/GlobalModal'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAuthSelector } from 'state/auth/reducer'
import styled from 'styled-components'
import HeaderToken from './Components/HeaderToken'
import Modal from './Components/Modal'
import { FooterStyled, HeadTopStyled, HomepageGameStyled, MainContentStyled, VideoBackground } from './Styled'

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'block' : 'none')};
  position: absolute;
  bottom: -22px;
  right: 0;
  left: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.contrast};
  color: ${({ theme }) => theme.colors.invertedContrast};
  border-radius: 16px;
  opacity: 0.7;
`

const HoverStyled = styled.div`
  z-index: 3;

  img {
    transition: 0.5s ease all;
    cursor: pointer;
    width: 100%;
    transition: 0.5s ease-in-out;

    &:hover {
      transform: scale(1.1);
      transition: 0.5s ease-in-out;
      filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
        drop-shadow(2px -2px 2px #ffffff);
    }
  }
`

function HomepageGame() {
  const { account, chainId, library } = useWeb3React()

  const { login, logout } = useAuth()
  const authSelector = useSelector(getAuthSelector)

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState<boolean>(false)
  const trimAccount = account ? `${account.slice(0, 4)}...${account.slice(38)}` : '0x00...0000' // account length: 42

  const handleShowComingSoon = () => GlobalModal.show(<Modal onClose={GlobalModal.hide} />)

  return (
    <HomepageGameStyled>
      <VideoBackground>
        <video autoPlay playsInline loop muted controls>
          <source src="/images/homepage-game/bg-game-home-page.webm" type="video/webm" />
          <source src="./bg-game-home-page.mov" type="video/quicktime" />
        </video>
      </VideoBackground>

      <HeadTopStyled>
        <div className="widget__avartar">
          <div className="widget__avartar-left">
            <img src="./images/homepage-game/avartar.png" alt="" />
          </div>

          <div className="widget__avartar-right">
            <span>{account ? 'Jenny402' : 'Guess'}</span>
            <p className="widget__avartar-right__codenumber">
              <span className="id-number">
                {trimAccount}
                <img
                  src="./images/homepage-game/icon-copy.png"
                  alt=""
                  onClick={() => {
                    if (navigator.clipboard) {
                      navigator.clipboard.writeText(account)
                      setIsTooltipDisplayed(true)
                      setTimeout(() => {
                        setIsTooltipDisplayed(false)
                      }, 1000)
                    }
                  }}
                />
                <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
              </span>
            </p>
            <div className="widget__avartar-right__level">
              <span>Lv.1</span>
              <p>
                <Progress percent={50} size="small" />
              </p>
            </div>
          </div>
        </div>

        <HeaderToken />

        <div className="widget__control">
          <Link to="/">
            <HoverStyled>
              <img src="./images/homepage-game/icon-back-home.png" alt="icon home" />
            </HoverStyled>
          </Link>

          <HoverStyled>
            <img src="./images/homepage-game/icon-checkin.png" alt="homepage-game" onClick={handleShowComingSoon} />
          </HoverStyled>
          {/* <Link to="/">
            <img src="./images/homepage-game/icon-check-in.png" alt="" />
          </Link> */}

          {account ? (
            <HoverStyled>
              <img src="./images/homepage-game/logout-game-h.png" alt="" className="connect" onClick={logout} />
            </HoverStyled>
          ) : (
            <HoverStyled>
              <img
                src="./images/homepage-game/icon-connect-h.png"
                alt=""
                className="connect"
                onClick={() => {
                  GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
                }}
              />
            </HoverStyled>
          )}

          <HoverStyled>
            <img src="./images/homepage-game/icon-setting-h.png" alt="homepage-game" onClick={handleShowComingSoon} />
          </HoverStyled>

          {/* <Link to="/">
            <img src="./images/homepage-game/icon-setting.png" alt="" />
          </Link> */}
        </div>
      </HeadTopStyled>

      <MainContentStyled>
        <div className="box__noitification">
          <span>This is notification about the game</span>
        </div>

        <div className="content__left">
          <HoverStyled>
            <Link to="/play-now">
              <img src="./images/homepage-game/play-now.png" alt="" />
            </Link>
          </HoverStyled>

          <HoverStyled>
            <Link to="staking">
              <img src="./images/homepage-game/stake-nfts.png" alt="" />
            </Link>
          </HoverStyled>

          <HoverStyled>
            <img src="./images/homepage-game/maket.png" alt="homepage-game" onClick={handleShowComingSoon} />
          </HoverStyled>
          {/* <Link to="/">
            <img src="./images/homepage-game/play-now.png" alt="" />
          </Link>
          
          <Link to="/">
            <img src="./images/homepage-game/maket.png" alt="" />
          </Link> */}
        </div>

        <div className="content__right">
          <div className="box__leaderboard">
            <div className="box__leaderboard-head">
              <div className="box__leaderboard-head__background">
                <h3>Leaderboard</h3>
              </div>
            </div>

            <div className="box__leaderboard-data">
              <div className="box__leaderboard-data__border">
                <div className="box__leaderboard-data__background">
                  <div className="box__leaderboard-data__button">
                    <Button type="primary">Best earning</Button>
                    <Button type="primary">Best winner</Button>
                  </div>

                  <div className="box__leaderboard-data__list">
                    <ol>
                      {Array.from({ length: 10 }, (_, index) => index + 1).map((i) => (
                        <li>
                          <span>Emily0{i}.VIP</span>
                          <span>2.982.364</span>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContentStyled>

      <FooterStyled>
        <div className="widget__box-messenger">
          <span className="notification">4</span>
          <img src="./images/homepage-game/messenger.png" alt="" />
        </div>

        <div className="widget__control-item">
          <HoverStyled>
            <Link to="/convert">
              <img src="./images/homepage-game/icon-convert.png" alt="" />
            </Link>
          </HoverStyled>

          <HoverStyled>
            <img src="./images/homepage-game/icon-quest.png" alt="homepage-game" onClick={handleShowComingSoon} />
          </HoverStyled>
          <HoverStyled>
            <img src="./images/homepage-game/icon-event-bot.png" alt="homepage-game" onClick={handleShowComingSoon} />
          </HoverStyled>
          {/* <Link to="/">
            <img src="./images/homepage-game/icon-quest.png" alt="" />
          </Link> */}
          {/* <Link to="/">
            <img src="./images/homepage-game/icon-event.png" alt="" />
          </Link> */}
          <HoverStyled>
            <Link to="/inventory">
              <img src="./images/homepage-game/icon-inventory.png" alt="" />
            </Link>
          </HoverStyled>

          <HoverStyled>
            <img src="./images/homepage-game/icon-fusion.png" alt="homepage-game" onClick={handleShowComingSoon} />
          </HoverStyled>
          {/* <Link to="/fusion">
            <img src="./images/homepage-game/fusion.png" alt="" />
          </Link> */}

          <HoverStyled>
            <img src="./images/homepage-game/icon-mail-new.png" alt="homepage-game" onClick={handleShowComingSoon} />
          </HoverStyled>
          <HoverStyled>
            <img src="./images/homepage-game/icon-loot-box.png" alt="homepage-game" onClick={handleShowComingSoon} />
          </HoverStyled>
          {/* <Link to="/">
            <img src="./images/homepage-game/mail.png" alt="" />
          </Link> 
          <Link to="/">
            <img src="./images/homepage-game/loot-box.png" alt="" />
          </Link>*/}
        </div>
      </FooterStyled>
    </HomepageGameStyled>
  )
}

export default HomepageGame
