import { useWeb3React } from '@web3-react/core'
import { Tabs } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { setCivilianId } from 'state/game'
import { useActiveCivilianId } from 'state/game/selector'
import styled from 'styled-components'
import HeaderToken from 'views2/HomepageGame/Components/HeaderToken'
import { useSelector } from 'react-redux'
import { fetchUserCharacters } from '../../../state/game/index'
import Back from '../components/Back'
import CivilianItem from '../components/CivilianItem'
import InventoryParameter from './Components/InventoryParameter'
import InventoryPodiumStand from './Components/InventoryPodiumStand'
import { State } from '../../../state/types'

const { TabPane } = Tabs

function Inventory() {
  const id = useActiveCivilianId()
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const civilians = useSelector((state: State) => state.game.userData.characters)

  useEffect(() => {
    if (account) {
      dispatch(fetchUserCharacters(account))
    }
  }, [account])

  const clickItem = (civilianId) => {
    dispatch(setCivilianId(civilianId))
  }

  const civilianSelected = civilians.find((c) => c.tokenId === id.toString())

  return (
    <InventoryStyled>
      <Back />
      <div className="header__token__styled">
        <HeaderToken />
      </div>

      <div className="inventory__content">
        <div className="inventory__content__left">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Civilian" key="1">
              <InventoryTabStyled>
                <div className="inventory__tab--item">
                  {civilians.map((civilian, index) => (
                    <CivilianItem
                      name={civilian.heroName}
                      star={parseInt(civilian.heroRarity) + 1}
                      img={civilian.img}
                      lv={civilian.level}
                      key={index}
                      rating={civilian.heroRating}
                      onClick={() => clickItem(civilian.tokenId)}
                      className={
                        civilian.tokenId === id.toString() ? 'civilianItem__style active' : 'civilianItem__style'
                      }
                    />
                  ))}
                </div>
              </InventoryTabStyled>
            </TabPane>
            <TabPane tab="Vehicle" key="2">
              <InventoryTabStyled>
                <div className="inventory__tab--item"></div>
              </InventoryTabStyled>
            </TabPane>
          </Tabs>
        </div>
        <div className="inventory__content__right">
          <InventoryPodiumStand img={civilianSelected?.img} name={civilianSelected?.name} />
          <div className="inventory__content__right-bottom">
            <InventoryParameter id={civilianSelected?.tokenId} civilian={civilianSelected} />
            <InventoryFusionStyled>
              {/* <Link to="/fusion"> */}
              <img src="./images/inventory-game/fusion.png" alt="fusion" />
              <p>FUSION</p>
              {/* </Link> */}
            </InventoryFusionStyled>
          </div>
        </div>
      </div>
    </InventoryStyled>
  )
}

const InventoryStyled = styled.div`
  background: url('./images/games/bg-inventory.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 1080px;
  width: 1920px;
  .header__token__styled {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .inventory__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 166px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    padding-right: 80px;
    &__right {
      width: auto;
      height: 834px;
      margin: 0 auto;
      position: relative;
      &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0px;
      }
    }
    &__left {
      width: 765px;
      margin: 0 auto;
      .ant-tabs-nav {
        margin-left: 50px;
        margin-bottom: 0px;
        &::before {
          border-bottom: unset;
        }
        .ant-tabs-tab-btn {
          color: #d0d6e3;
          font-size: 24px;
          font-weight: 400;
        }
        .ant-tabs-tab.ant-tabs-tab-active {
          background: url('./images/games/tab-inventory-active.svg');
          transition: ease all 0.5s;
          .ant-tabs-tab-btn {
            color: #fff;
          }
          &::before {
            opacity: 1;
          }
        }
        .ant-tabs-tab {
          background: url('./images/games/tab-inventory.svg');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 180px;
          height: 62px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: ease all 0.5s;
          &::before {
            opacity: 0.8;
          }
          &:nth-child(1) {
            &::before {
              content: '';
              background: url('./images/games/before-civilian.svg');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 34px;
              height: 34px;
            }
          }
          &:nth-child(2) {
            margin-left: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            &::before {
              content: '';
              background: url('./images/games/before-vehicle.svg');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 32px;
              height: 32px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
`

const InventoryTabStyled = styled.div`
  width: 765px;
  height: 768px;
  background: url('./images/games/tab-inventory.png');
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  .inventory__tab--item {
    overflow-y: scroll;
    max-height: 735px;
    height: auto;
    padding: 0px 40px;
    margin-top: 17px;
    margin-right: 15px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    &::-webkit-scrollbar {
      width: 12px;
      background: unset;
      border-radius: 27px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 27px;
      background: #94bfff;
    }
  }
`
const InventoryFusionStyled = styled.div`
  background: #18aff7;
  border-radius: 16px;
  width: 220px;
  height: 200px;
  position: relative;
  text-align: center;
  cursor: no-drop;
  transition: ease all 0.5s;
  &:hover {
    transform: scale(1.1);
  }
  img {
    width: 110px;
    position: relative;
    z-index: 100;
    padding-top: 24px;
  }
  p {
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    position: relative;
    z-index: 100;
  }
  &::after {
    content: '';
    position: absolute;
    top: 6px;
    width: 196px;
    height: 72px;
    background: #7ed5ff;
    border-radius: 16px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
  &::before {
    content: '';
    position: absolute;
    background: #68c9ff;
    height: 188px;
    width: 212px;
    top: 0;
    border-radius: 16px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
`

export default Inventory
