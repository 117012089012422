import React from 'react'

import styled from 'styled-components'

function CardItem({ className, onClick, index, price }) {
  return (
    <CardItemStyled
      className={price === 0 ? className + ' lose' : className}
      onClick={onClick}
      style={{ animation: ` cardAnimation ${index + 2}s ease-in` }}
    >
      <div className="card__styled">
        <div className="card__price">
          <img src="/images/play-now/token-dgt-new.png" alt="token-dgt" />
          <span>{price}</span>
        </div>
        <div className="card__chest">
          <img src="/images/play-now/chest.png" alt="chest" />
        </div>
      </div>
    </CardItemStyled>
  )
}

const CardItemStyled = styled.div`
  width: 260px;
  height: 405px;
  background-size: cover;
  border-radius: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 50px 30px #00ffff;
  background: url('./images/play-now/card-item.png') no-repeat;
  cursor: pointer;
  animation-delay: 2s;
  .card__styled {
    opacity: 0;
  }
  &.active {
    .card__styled {
      transition: ease all 1.5s;
      opacity: 1;
    }
    transition: ease all 1s;
    background: url('./images/play-now/card-item-open.svg') no-repeat;
    transform: rotate3d(0, 1, 0, 180deg);
    position: relative;
    .card__price {
      transform: rotate3d(0, 1, 0, 180deg);
      transition: ease all 0.5s;
      font-size: 48px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 10px;
        width: 52px;
        height: 52px;
        margin-top: 5px;
      }
      span {
        margin-top: -10px;
      }
    }
    .card__chest {
      width: 140px;
      padding-top: 20px;
    }
  }
  &.active.lose {
    box-shadow: 0px 0px 50px 30px #ff0000;
  }
  @keyframes cardAnimation {
    from {
      margin-top: 100%;
    }
    to {
      margin-top: 0%;
    }
  }
`
export default CardItem
