import React from 'react'
import styled from 'styled-components'

function TitleCivilian({title = 'Civilian Fusion'}) {
  return (
    <TitleCivilianStyled>
      <h3>{title}</h3>
    </TitleCivilianStyled>
  )
}

const TitleCivilianStyled = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: url('./images/civilian-fusion/bg-title.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 774px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    color: #fff;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000,
      1px -1px 0 #000, -1px 1px 0 #000;
    font-size: 38px;
    font-weight: 700;
  }
`
export default TitleCivilian
