import {createAsyncThunk} from '@reduxjs/toolkit'
import {groupBy, merge, keyBy, map, values as _values} from 'lodash'
import marketplaceAbi from 'config/abi/DivinerMarketplace.json'
import characterAbi from 'config/abi/character.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import {getMarketplaceAddress, getCharacterAddress, getFakeCharacterAddress} from 'utils/addressHelpers'
import {getMarketplaceContract, getCharacterContract, getFakeCharacterContract} from 'utils/contractHelpers'
import {_getCiviliansByIds, _getListingDataByIdentifiers} from 'state/marketplace/utils'
import {fetchingInventory} from 'state/marketplace/actions/actions'

export const fetchInventoryCivilians = createAsyncThunk(
  'marketplace/fetchInventoryCivilians',
  async (account: string, {dispatch}) => {
    let civilians = []
    let civilianIds = []
    let fakeCivilianIds = []

    try {
      const listingCivilians = await _fetchListingInventoryCivilians(account)
      const civiliansContract = getCharacterAddress() !== '' && getCharacterContract()
      const fakeCiviliansContract = getFakeCharacterAddress() !== '' && getFakeCharacterContract()

      await Promise.all([
        civiliansContract && civiliansContract?.tokensOfOwner(account),
        fakeCiviliansContract && fakeCiviliansContract?.tokensOfOwner(account),
      ]).then((values) => {
        civilianIds = values[0] ? [...values[0], ...map(listingCivilians[getCharacterAddress()], 'id')] : []
        fakeCivilianIds = values[1] ? [...values[1], ...map(listingCivilians[getFakeCharacterAddress()], 'id')] : []
      })

      await Promise.all([
        _getCiviliansByIds(getCharacterAddress(), characterAbi, 'getHero', civilianIds, true),
        _getCiviliansByIds(getFakeCharacterAddress(), fakeCharacterAbi, 'getHero', fakeCivilianIds, false),
      ]).then((values) => {
        civilians = [
          ..._values(merge(keyBy(values[0], '_id'), keyBy(listingCivilians[getCharacterAddress()], '_id'))),
          ..._values(merge(keyBy(values[1], '_id'), keyBy(listingCivilians[getFakeCharacterAddress()], '_id'))),
        ]
      })

      dispatch(fetchingInventory(false))
      return civilians
    } catch (e) {
      return []
    }
  },
)

const _fetchListingInventoryCivilians = async (account) => {
  const marketplaceContract = getMarketplaceContract()
  const listingTokens = await marketplaceContract?.getListingBySeller(account)

  const results = await _getListingDataByIdentifiers(
    getMarketplaceAddress(),
    marketplaceAbi,
    'getListingByIdentifier',
    listingTokens,
  )

  return groupBy(results, 'listingNft')
}
