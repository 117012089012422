import styled from 'styled-components'

export const HeadBackHome = styled.div`
  position: absolute;
  top: 32px;
  left: 0;
  cursor: pointer;
  transition: ease all 0.5s;
  z-index: 100;
  img {
    transition: 0.5s ease all;
    cursor: pointer;

    &:hover {
      filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
        drop-shadow(2px -2px 2px #ffffff);
    }
  }
`
