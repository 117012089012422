import React, { useState } from 'react'
import styled from 'styled-components'
import CivilianList from '../CivilianList'
import Back from '../components/Back'
import CivilianItemHover from '../components/CivilianItemHover'
import ReViewIcons from '../components/ReViewIcons'
import TitleCivilian from '../components/TitleCivilian'
import PageReview from '../PageReview'
import SelectCivilian from './components/SelectCivilian'

function CivilianFusion(props) {
  const [tutorial, setTutorial] = useState(true)
  const [selectedItems, setSelectedItems] = useState([null, null, null, null, null])

  const filterItem = CivilianList.find((element) => element.id === selectedItems[0])
  const clickItem = (itemId) => {
    const itemIndex = selectedItems.findIndex((x) => x === itemId)
    if (itemIndex === -1) {
      const firstEmptyIndex = selectedItems.findIndex((x) => x === null)

      if (firstEmptyIndex !== -1) {
        const newSelectedItems = [...selectedItems]
        newSelectedItems[firstEmptyIndex] = itemId
        setSelectedItems(newSelectedItems)
      }
    } else if (itemIndex === 0) {
      setSelectedItems([null, null, null, null, null])
    } else {
      const newSelectedItems = [...selectedItems]
      newSelectedItems[itemIndex] = null
      setSelectedItems(newSelectedItems)
    }
  }

  let CivilianListToRender = CivilianList

  if (filterItem) {
    CivilianListToRender = CivilianList.filter(
      (item) => item.rating === filterItem.rating && item.star === filterItem.star,
    )
  }

  // const id = useActiveCivilianId()
  // const civilianSelected = CivilianList.find((c) => c.id === id)
  // console.log('civilianSelected:', civilianSelected)
  // const dispatch = useAppDispatch()
  // dispatch(setCivilianId(civilianSelected))
  // console.log('setCivilianId(id):', setCivilianId(id))

  return (
    <CivilianFusionStyled>
      <Back />
      <TitleCivilian />
      <ReViewIcons onClick={() => setTutorial(!tutorial)} />

      <PageReview Styled={tutorial ? 'none' : 'block'} onClose={() => setTutorial(!tutorial)} />

      <div className="fusion__content">
        <div className="fusion__content__left">
          <SelectCivilian clickItem={clickItem} selectedItems={selectedItems} />
        </div>
        <div className="fusion__content__right">
          <div className="fusion__content__right--item">
            {CivilianListToRender.map((civilian, index) => {
              return (
                <CivilianItemHover
                  name={civilian.name}
                  star={civilian.star}
                  img={civilian.img}
                  lv={civilian.lv}
                  key={index}
                  rating={civilian.rating}
                  className={
                    selectedItems[0] === null
                      ? 'civilianItem__style'
                      : selectedItems.includes(civilian.id)
                      ? 'civilianItem__style active'
                      : 'civilianItem__style'
                  }
                  onClick={() => clickItem(civilian.id)}
                />
              )
            })}
          </div>
        </div>
      </div>
    </CivilianFusionStyled>
  )
}

const CivilianFusionStyled = styled.div`
  background: url('./images/civilian-fusion/bg-civilian-fusion.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 1080px;
  width: 1920px;

  .fusion__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 166px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    padding-right: 80px;

    &__right {
      width: 718px;
      height: 834px;
      background: url('./images/civilian-fusion/bg-item-right.png');
      background-size: cover;
      background-repeat: no-repeat;

      &--item {
        overflow-y: scroll;
        max-height: 780px;
        height: auto;
        padding: 0px 40px;
        margin-top: 30px;
        margin-right: 15px;
        margin-left: 10px;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        &::-webkit-scrollbar {
          width: 12px;
          background: unset;
          border-radius: 27px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 27px;
          background: #94bfff;
        }
      }
    }

    &__left {
      width: auto;
      height: 834px;
      margin: 0 auto;
      position: relative;
    }
  }
`
export default CivilianFusion
