import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mapKeys, omit, isEmpty} from 'lodash'
import {MarketplaceState} from 'state/marketplace/types/types'
import {
  fetchingMarket,
  fetchingInventory,
  increaseMarketPage,
  updateApproveToken,
  updateMarketPage,
} from './actions/actions'
import {fetchInventoryCivilians} from './actions/inventoryActions'
import {
  fetchListingCivilians,
  fetchSellerFee,
  fetchBuyerFee,
  addListing,
  cancelListing,
  purchaseListing,
} from './actions/marketActions'
import {fetchUserToken} from './actions/tokenActions'

const initialState: MarketplaceState = {
  listing: {},
  inventoryCharacters: {},
  pagination: {
    marketPage: 0,
    limit: 8,
  },
  sorts: {
    byPrice: 1,
    byLevel: 1,
    byRarity: 1,
  },
  filters: {
    isOwned: false,
    rarity: 0,
    level: [],
    rangePrice: {
      from: 0,
      to: 0,
    },
  },
  fetching: {
    market: false,
    inventory: false,
  },
  user: {
    dpt: {
      balance: '0',
      allowance: '0',
      isAllowance: false,
    },
  },
  fee: {
    sellerFee: '0',
    buyerFee: '0',
  },
}

export const marketplaceSlicer = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    sortByPrice: (state, action) => {
      state.sorts.byPrice = action.payload
    },
    sortByLevel: (state, action) => {
      state.sorts.byLevel = action.payload
    },
    sortByRarity: (state, action) => {
      state.sorts.byRarity = action.payload
    },
    filterByRank: (state, action) => {
      state.filters.rarity = action.payload
    },
    filterByLevel: (state, action) => {
      state.filters.level = action.payload
    },
    filterByPriceFrom: (state, action) => {
      state.filters.rangePrice.from = action.payload
    },
    filterByPriceTo: (state, action) => {
      state.filters.rangePrice.to = action.payload
    },
    filterByOwnership: (state, action) => {
      state.filters.isOwned = action.payload
    },
    resetState: (state) => {
      state.listing = {}
      state.inventoryCharacters = {}
      state.pagination = {
        marketPage: 0,
        limit: 8,
      }
      state.sorts = {
        byPrice: 1,
        byLevel: 1,
        byRarity: 0,
      }
      state.filters = {
        isOwned: false,
        rarity: 0,
        level: [0, 100],
        rangePrice: {
          from: 0,
          to: 0,
        },
      }
      state.fetching = {
        market: false,
        inventory: false,
      }
      state.user = {
        dpt: {
          balance: '0',
          allowance: '0',
          isAllowance: false,
        },
      }
      state.fee = {
        sellerFee: '',
        buyerFee: '',
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchingMarket, (state, action: PayloadAction<boolean>) => {
        state.fetching.market = action.payload
      })
      .addCase(fetchingInventory, (state, action: PayloadAction<boolean>) => {
        state.fetching.inventory = action.payload
      })
      .addCase(increaseMarketPage, (state) => {
        state.pagination.marketPage += 1
      })
      .addCase(updateMarketPage, (state, action) => {
        state.pagination.marketPage = action.payload
      })
      .addCase(fetchUserToken.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(fetchBuyerFee.fulfilled, (state, action) => {
        state.fee.buyerFee = action.payload
      })
      .addCase(fetchSellerFee.fulfilled, (state, action) => {
        state.fee.sellerFee = action.payload
      })
      .addCase(updateApproveToken, (state, action) => {
        state.user.dpt.isAllowance = action.payload
      })
      .addCase(fetchListingCivilians.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.listing = {...state.listing, ...mapKeys(action.payload, '_id')}
      })
      .addCase(fetchInventoryCivilians.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.inventoryCharacters = {...state.inventoryCharacters, ...mapKeys(action.payload, '_id')}
      })
      .addCase(addListing.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.inventoryCharacters = {
          ...state.inventoryCharacters,
          [`${action.payload.listingNft}_${action.payload.id}`]: action.payload,
        }
        state.listing = {...state.listing, [`${action.payload.listingNft}_${action.payload.id}`]: action.payload}
      })
      .addCase(cancelListing.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.inventoryCharacters = {
          ...state.inventoryCharacters,
          [`${action.payload.nftAddress}_${action.payload.tokenId}`]: action.payload,
        }
        state.listing = omit(state.listing, [`${action.payload.nftAddress}_${action.payload.tokenId}`])
      })
      .addCase(purchaseListing.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.inventoryCharacters = {
          ...state.inventoryCharacters,
          [`${action.payload.nftAddress}_${action.payload.tokenId}`]: action.payload,
        }
        state.listing = omit(state.listing, [`${action.payload.nftAddress}_${action.payload.tokenId}`])
      })
  },
})

export const {
  sortByPrice,
  sortByLevel,
  sortByRarity,
  filterByRank,
  filterByLevel,
  filterByPriceFrom,
  filterByPriceTo,
  filterByOwnership,
  resetState,
} = marketplaceSlicer.actions

export default marketplaceSlicer.reducer
