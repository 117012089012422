import {useEffect, useState} from 'react'
import axios from 'axios'
import {Table, Collapse} from 'antd'
import {useActiveWeb3React} from 'hooks'

import BeanButton from 'components2/Common/BeanButton'
import {PREDICTION_API_URL} from 'config'
import addresses from 'config/constants/contracts'
import ComingSoonModal from '../../../../components2/Modal/ComingSoonModal'

const {Panel} = Collapse

const TransactionPlayNowModal = ({onClose}) => {
  return (
    <ComingSoonModal styleInside={{padding: '0 10px', margin: '15px'}} onClose={onClose}>
      Coming Soon
    </ComingSoonModal>
  )
}

export default TransactionPlayNowModal
