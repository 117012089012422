import { playGame } from 'api/actions/game'
import ModalGamePlayNow from 'components2/Modal/ModalGamePlayNow'
import GlobalModal from 'components2/static/GlobalModal'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Back from 'views2/Games/components/Back'
import spinList, { wrongPositions } from './spinList'

const apiCheck = () => new Promise((resolve) => resolve(Math.random() > 0.5))

const indexSelectedPiceToDreg = (index, totalItem) => {
  const dreg1Pice = 360 / totalItem

  const numberDreg = dreg1Pice / 2

  const startDreg = index * dreg1Pice - numberDreg + 1

  const endDreg = (index + 1) * dreg1Pice - numberDreg - 1

  const dm = endDreg - startDreg

  const radom = endDreg - Math.trunc(Math.random() * (endDreg - startDreg))

  return -radom
}

function Wheel() {
  // const [isSpin, setIsSpin] = useState(false)
  const [spin, setSpin] = useState(0)
  const [list, setList] = useState(spinList().listSpin)
  const [isLoading, setIsLoading] = useState(false)
  const { state } = useLocation()
  const { push } = useHistory()

  useEffect(() => {
    if (!state?.tokenId) {
      push('/play-now')
    }
  }, [state])

  const ClickSpin = async (sp) => {
    try {
      setIsLoading(true)
      setSpin(1200)
      const resultPrice = await playGame({ tokenId: state?.tokenId, type: 2 })

      const isWin = resultPrice?.userReward !== 0
      let updateAPI = resultPrice.randomRewards

      if (isWin) updateAPI = [resultPrice.userReward, ...resultPrice.randomRewards.slice(0, 5)]

      const { listSpin, correctPosition } = spinList(updateAPI)
      setList(listSpin)
      const indexSelectedPice =
        resultPrice.userReward > 0 ? correctPosition : wrongPositions[Math.floor(Math.random() * wrongPositions.length)]

      let degToRotate = indexSelectedPiceToDreg(indexSelectedPice, listSpin.length)

      while (degToRotate < spin + 360 * 2) {
        degToRotate += 360
      }

      setSpin(degToRotate)

      setTimeout(() => {
        GlobalModal.show(
          <ModalGamePlayNow bonus={resultPrice.bonus} userReward={resultPrice.userReward} onClose={handCloseModal} />,
          { onPressBackground: null },
        )
      }, 6000)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handCloseModal = () => {
    GlobalModal.hide()
    push('/play-now')
  }

  return (
    <WheelStyled>
      <div className="bg-wheel"></div>
      <Back to="/play-now" />
      <div className="wheel__styled">
        <img className="img__spin-active" src="./images/play-now/arrow.png" alt="arrow.png" />
        <img
          onClick={ClickSpin}
          className={`spin-img ${isLoading || spin > 0 ? 'disabled' : ''}`}
          src="./images/play-now/spin-wheel.png"
          alt="spin-wheel"
        />
        <div className="wheel__content">
          <WheelContentStyled
            style={
              spin > 0
                ? { transform: `rotate(${spin + 720}deg)`, transition: 'ease-out all 5s' }
                : { transform: 'rotate(0deg)' }
            }
          >
            {list.map((wheel, index) => (
              <div
                className="sec"
                style={{
                  transform: `rotate(${index * 36}deg)`,
                  background: `${wheel.BgWheel} transparent`,
                  zIndex: `${index + 1}`,
                }}
              >
                <div className="price__content">
                  <div className="price">
                    <p>{wheel?.price}</p>
                    {wheel.iconPrice === null ? '' : <img src={wheel.iconPrice} alt="icon" />}
                  </div>
                </div>
              </div>
            ))}
          </WheelContentStyled>
        </div>
      </div>
    </WheelStyled>
  )
}

const WheelStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .bg-wheel {
    background: url('./images/play-now/spin-mini-game-new.png') no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .wheel__styled {
    width: 750px;
    height: 750px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .wheel__content {
      border-radius: 50%;
      background: linear-gradient(180deg, #cfe1fd 0%, #98bef8 100%);
      padding: 35px;
      z-index: -1;
    }

    .img__spin-active {
      position: absolute;
      top: -5%;
      z-index: 100;
    }

    #wheel:before {
      content: '';
      position: absolute;
      border: 4px solid rgba(0, 0, 0, 0.1);
      width: 730px;
      height: 730px;
      border-radius: 50%;
      z-index: 10;
      top: 10px;
      left: 10px;
    }

    #inner-wheel {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 6s cubic-bezier(0, 0.99, 0.44, 0.99);
    }

    @keyframes rotate {
      0%,
      100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
      }

      50% {
        transform: rotate(7deg);
        -webkit-transform: rotate(7deg);
      }
    }

    .spin {
      animation: rotate 0.1s;
    }

    .spin-img {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      width: 255px;
      box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.5), inset 53.4081px 20.6322px 99.2706px rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      &.disabled {
        pointer-events: none;
        filter: grayscale(1);
        cursor: no-drop;
      }
    }
  }
`

const WheelContentStyled = styled.div`
  width: 750px;
  height: 750px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 13px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px, rgba(0, 0, 0, 0.05) 0px 3px 0px;
  transform: rotate(0deg);
  display: flex;
  justify-content: center;
  align-items: center;

  div.sec {
    position: absolute;
    width: 245px;
    height: 375px;
    top: 0%;
    clip-path: polygon(0% 0px, 50% 100%, 100% 0);
    /* border-style: solid; */
    /* border-width: 375px 122px 0; */
    transform-origin: 122px 375px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;

    .price__content {
      position: absolute;
      top: 15%;
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-shadow: 2px 0 0 #000000, -2px 0 0 #000000, 0 2px 0 #000000, 0 -2px 0 #000000, 1px 1px #000000,
        -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000;
      p {
        margin-bottom: 10px;
      }
      img {
        width: 53px;
      }
    }

    /* height: 375px;
    width: 122px;
    background: linear-gradient(45deg, black, transparent);
    clip-path: polygon(0% 0px, 50% 100%, 100% 0); */
  }
`
export default Wheel
