import styled from 'styled-components'

export const HomepageGameStyled = styled.div`
  /* background: url('../images/homepage-game/bg-game-homepage.png') no-repeat; */
  background-size: cover;
  background-position: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const VideoBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

export const HeadTopStyled = styled.div`
  position: relative;
  z-index: 99;
  background: url('../images/homepage-game/bg-head.png') no-repeat;
  background-size: cover;
  background-position: 100%;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 7px 40px 10px;

  .widget {
    &__avartar {
      display: flex;
      gap: 24px;

      &-left {
        img {
          max-width: 80px;
        }
      }

      &-right {
        font-size: 1.125rem;
        color: #ffffff;

        &__codenumber,
        &__level {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        .id-number {
          img {
            padding-left: 5px;
          }
        }

        p {
          margin-bottom: 0;
          width: 127px;

          .ant-progress-text {
            color: #ffffff;
          }

          .ant-progress-inner {
            background-color: #f5f5f582;
          }

          .ant-progress-bg {
            background-color: #ffffff;
          }
        }

        &__codenumber {
          img {
            cursor: pointer;
          }
        }
      }
    }

    &__token {
      display: flex;
      align-items: center;
      gap: 24px;

      &-item {
        background: #0d5fabdb;
        border-radius: 40px;
        font-size: 1.125rem;
        color: #ffffff;
        padding: 5px 13px 5px 7px;

        span {
          text-align: right;
          padding-left: 16px;
          text-shadow: 2px 0 0 #000000, -2px 0 0 #000000, 0 2px 0 #000000, 0 -2px 0 #000000, 1px 1px #000000,
            -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000;
        }
      }
    }

    &__control {
      display: flex;
      align-items: center;
      gap: 2px;

      img {
        cursor: pointer;
        transform: scale(1);
        transition: 0.5s ease-in-out;
        max-width: 100px;

        &:hover {
          transform: scale(1.2);
          transition: 0.5s ease-in-out;
        }
      }
    }
  }
`

export const MainContentStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 70px 40px 10px;
  position: relative;
  z-index: 99;

  .character {
    position: absolute;
    top: 342px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  }

  .box__noitification {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 44px;
    width: 100%;
    max-width: 739px;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 20.83%,
      rgba(0, 0, 0, 0.6) 77.6%,
      rgba(0, 0, 0, 0) 100%
    );
    display: flex;
    align-items: center;

    &:before {
      content: '';
      background: url('../images/homepage-game/noitice-icon.png');
      background-size: contain;
      background-position: center;
      width: 44px;
      height: 42px;
      display: flex;
      justify-content: flex-start;
      text-align: left;
    }

    span {
      font-size: 1.125rem;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .content {
    &__left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      a {
        transform: scale(1);
        transition: 0.5s ease-in-out;

        &:hover {
          transform: scale(1.2);
          transition: 0.5s ease-in-out;
        }
      }
    }

    &__right {
      width: 423px;

      .box {
        &__leaderboard {
          &-head {
            background: linear-gradient(180deg, #fbb22f 0%, #ff8f3e 100%);
            box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
            padding: 6px;
            width: 305px;
            margin: 0 auto -30px;
            position: relative;
            z-index: 2;

            &:before {
              content: '';
              background-image: url('../images/homepage-game/crown.png');
              background-size: contain;
              background-position: center;
              width: 65px;
              height: 40px;
              position: absolute;
              top: -40px;
              left: 50%;
              transform: translateX(-50%);
              z-index: 2;
            }

            &__background {
              background: linear-gradient(180deg, #fbb22f 0%, #ff8f3e 100%);
              border-radius: 20px;
              border: 1px dashed #ffffff;
              padding: 6px;

              h3 {
                color: #ffffff;
                text-align: center;
                text-transform: uppercase;
                font-size: 1.625rem;
                font-weight: 600;
                margin-bottom: 0;
              }
            }
          }

          &-data {
            background-color: #f69650;
            border-radius: 28px;
            padding: 10px;

            &__border {
              border-radius: 30px;
              border: 1px dashed #ffffff;
              padding: 5px;
            }

            &__background {
              background-color: #ffffff;
              border-radius: 26px;
              padding: 30px;
            }

            &__button {
              margin-bottom: 24px;
              display: flex;
              justify-content: center;
              gap: 15px;
              align-items: center;

              button {
                padding: 12px;
                width: 164px;
                height: 54px;
                transform: scale(1);
                transition: 0.5s ease-in-out;

                &:hover {
                  transform: scale(1.1);
                  transition: 0.5s ease-in-out;
                }
              }

              span {
                font-size: 1.25rem;
                font-weight: 600;
              }

              button {
                &:first-of-type {
                  background: #ff8f3e;
                  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                  border-radius: 12px;
                  border: none;
                  color: #ffffff;
                }

                &:nth-child(2) {
                  background: #f1f0ef;
                  box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.25);
                  border-radius: 12px;
                  border: none;
                  color: #6a6a6a;
                }
              }
            }

            &__list {
              ol {
                list-style: none;
                counter-reset: circle-counter;

                li {
                  counter-increment: circle-counter;
                  background: #f1f0ef;
                  border-radius: 50px;
                  margin-bottom: 12px;
                  padding: 5px 16px;
                  font-size: 1rem;
                  color: #000000;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  position: relative;

                  &:first-of-type,
                  &:nth-child(2),
                  &:nth-child(3) {
                    &:before {
                      content: '';
                      background-size: contain;
                      background-position: center;
                      width: 40px;
                      height: 40px;
                      position: absolute;
                      top: 50%;
                      left: 0;
                      transform: translateY(-50%);
                    }
                  }

                  &:first-of-type {
                    &:before {
                      background-image: url('../images/homepage-game/icon-one.png');
                    }
                  }

                  &:nth-child(2) {
                    &:before {
                      background-image: url('../images/homepage-game/icon-two.png');
                    }
                  }

                  &:nth-child(3) {
                    &:before {
                      background-image: url('../images/homepage-game/icon-three.png');
                    }
                  }

                  &:before {
                    content: counter(circle-counter);
                    display: inline-block;
                    font-size: 1.3rem;
                    color: #ffffff;
                    -webkit-text-fill-color: white;
                    -webkit-text-stroke-width: 0.7px;
                    -webkit-text-stroke-color: black;
                    position: absolute;
                    top: 50%;
                    left: 12px;
                    transform: translateY(-50%);
                  }

                  span {
                    &:first-of-type {
                      padding-left: 35px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const FooterStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 40px 40px;
  margin-top: auto;
  position: relative;
  z-index: 5;

  .widget {
    &__box-messenger {
      position: relative;
      animation: pulse 2s infinite;

      .notification {
        position: absolute;
        top: -10px;
        right: -10px;
        background: radial-gradient(50% 50% at 62.5% 42.5%, #ff4646 0%, #ff2020 40.42%, #de0000 100%);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #ffffff;
        font-size: 1.375rem;
        font-weight: 600;
        text-align: center;
        line-height: 32px;
      }
    }

    &__control-item {
      display: flex;
      gap: 40px;
      position: relative;

      &:before {
        content: '';
        width: 820px;
        height: 80px;
        background: linear-gradient(270deg, #6ea9ef 72.12%, rgba(110, 169, 239, 0) 100%);
        opacity: 0.6;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        position: absolute;
        bottom: -20px;
        right: -40px;
      }

      a {
        transform: scale(1);
        transition: 0.5s ease-in-out;

        &:hover {
          transform: scale(1.2);
          transition: 0.5s ease-in-out;
        }
      }
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.9);
    }
    80% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`
