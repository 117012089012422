import {chestConfig} from './types'

const chests: chestConfig[] = [
  {
    type: 'Copper',
    n: '42',
    r: '36',
    sr: '21',
    ssr: '5',
    sssr: '0',
  },
  {
    type: 'Silver',
    n: '29',
    r: '29',
    sr: '30',
    ssr: '12',
    sssr: '0',
  },
  {
    type: 'Gold',
    n: '10',
    r: '23',
    sr: '42',
    ssr: '25',
    sssr: '0',
  },
]

export default chests
