import ReviewSkin from 'components2/Modal/ReviewSkin'
import ButtonGame from 'views2/Games/ButtonGame'
import CivilianList from 'views2/Games/CivilianList'
import GlobalModal from 'components2/static/GlobalModal'
import styled from 'styled-components'
import CivilianViewSkin from './CivilianViewSkin'
import CivilianItem from '../../components/CivilianItem'
import CivilianPodiumStand from './CivilianPodiumStand'

function SelectCivilian({selectedItems, clickItem}) {
  const findItem1 = CivilianList.find((element) => element.id === selectedItems[0])
  const findItem2 = CivilianList.find((element) => element.id === selectedItems[1])
  const findItem3 = CivilianList.find((element) => element.id === selectedItems[2])
  const findItem4 = CivilianList.find((element) => element.id === selectedItems[3])
  const findItem5 = CivilianList.find((element) => element.id === selectedItems[4])

  return (
    <SelectCivilianStyled>
      <div className="select__style">
        <CivilianPodiumStand
          selectedItems={selectedItems[0]}
          img={findItem1?.img}
          star={findItem1?.star}
          name={findItem1?.name}
        />

        <SelectItem>
          <div className={selectedItems[3] === null ? 'select__item' : 'select__block'}>
            <img className="item__lock" src="./images/civilian-fusion/lock.svg" alt="lock" />
            <CivilianItem
              onClose={() => clickItem(findItem4?.id)}
              lv={findItem4?.lv}
              img={findItem4?.img}
              star={findItem4?.star}
              rating={findItem4?.rating}
            />
          </div>
          <div className={selectedItems[1] === null ? 'select__item' : 'select__block'}>
            <img className="item__lock" src="./images/civilian-fusion/lock.svg" alt="lock" />
            <CivilianItem
              onClose={() => clickItem(findItem2?.id)}
              lv={findItem2?.lv}
              img={findItem2?.img}
              star={findItem2?.star}
              rating={findItem2?.rating}
            />
          </div>

          <div className={selectedItems[0] === null ? 'select__item active' : 'show__selectItem'}>
            <CivilianItem
              onClose={() => clickItem(findItem1?.id)}
              lv={findItem1?.lv}
              img={findItem1?.img}
              star={findItem1?.star}
              rating={findItem1?.rating}
            />
          </div>
          <div className={selectedItems[2] === null ? 'select__item' : 'select__block'}>
            <img className="item__lock" src="./images/civilian-fusion/lock.svg" alt="lock" />
            <CivilianItem
              onClose={() => clickItem(findItem3.id)}
              lv={findItem3?.lv}
              img={findItem3?.img}
              star={findItem3?.star}
              rating={findItem3?.rating}
            />
          </div>

          <div className={selectedItems[4] === null ? 'select__item' : 'select__block'}>
            <img className="item__lock" src="./images/civilian-fusion/lock.svg" alt="lock" />
            <CivilianItem
              onClose={() => clickItem(findItem5?.id)}
              lv={findItem5?.lv}
              img={findItem5?.img}
              star={findItem5?.star}
              rating={findItem5?.rating}
            />
          </div>
        </SelectItem>
      </div>
      <div className="select__button">
        <CivilianViewSkin />
        <ButtonGame
          className={selectedItems[0] === null && 'button__game disable'}
          name="FUSION"
          disabled={selectedItems[0] === null && true}
          onClick={() =>
            GlobalModal.show(
              <ReviewSkin
                lv={findItem1?.lv}
                img={findItem1?.img}
                star={findItem1?.star}
                rating={findItem1?.rating}
                onClose={GlobalModal.hide}
              />,
            )
          }
        />
      </div>
    </SelectCivilianStyled>
  )
}

const SelectCivilianStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0px);
  .select__style {
    display: flex;
    justify-content: center;
    align-items: center;
    .select__podiumStand {
    }
  }

  .select__button {
    margin-top: 110px;
    position: relative;
    display: flex;
    align-items: center;
    .button__game.disable {
      background: #878787;
      &::before {
        background: #bababa;
      }
      &::after {
        background: #a7a7a7;
      }
      button {
        background: unset;
        color: #fff;
      }
    }
  }
`

const SelectItem = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 600px;
  height: 120px;
  position: absolute;
  top: 125px;
  .select__item {
    position: relative;
    background: radial-gradient(50% 50% at 50% 50%, #4f76c0 0%, #032464 100%);
    width: 100px;
    height: 100px;
    border-radius: 12px;
    border: 3px solid #7cc8ff;
    transition: ease all 0.5s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
    .item__lock {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
    }
    &.active {
      border: 3px solid #fff;
      transform: scale(1.2);
      box-shadow: rgb(255 255 255 / 35%) 0px 5px 20px 5px;
    }
    &:hover {
      border: 3px solid #fff;
      box-shadow: rgb(255 255 255 / 35%) 0px 5px 20px 5px;
    }
    .civilianItem__style {
      display: none;
      width: 90px;
      height: 90px;

      .item__content {
        width: 90px;
        height: 90px;
        img {
          width: 90px;
          height: 90px;
        }
      }
    }
  }

  .select__block {
    .item__lock {
      display: none;
    }
  }

  .show__selectItem {
    .civilianItem__style {
      transform: scale(1.2);
    }
  }

  .civilianItem__style {
    cursor: unset;
    .item__content {
      width: 90px;
      height: 90px;
    }

    .item__lv {
      top: 10px;
    }

    .item__close {
      display: block;
      cursor: pointer;
    }
  }

  .civilianItem__style {
    width: 100px;
    height: 100px;
    margin-bottom: 0px;
    .item__rating {
      top: -1px;
      left: 9px;
    }
  }

  .civilianItem__style {
    .item__star {
      img {
        width: 12px;
        height: 12px;
        margin: 1px;
      }
    }
  }
`
export default SelectCivilian
