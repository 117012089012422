import styled from 'styled-components'

const ProgressBar = ({value, total}) => {
  return (
    <ProgressBarStyled>
      <div className="bar-inside" style={{width: `${(value / total) * 100}%`}}>
        <div className="bar-value">
          {value}/{total}
        </div>
      </div>
    </ProgressBarStyled>
  )
}

export default ProgressBar

const ProgressBarStyled = styled.div`
  width: 100%;
  height: 24px;

  background: #ffffff;
  box-shadow: inset 4px -2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  .bar-inside {
    position: absolute;
    background: #55b3f8;
    border-radius: 10px;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ::after {
      position: absolute;
      content: '';
      top: 4px;
      left: 5px;
      right: 5px;
      height: 10px;

      background: #7cc8ff;
      border-radius: 10px;
    }
  }

  .bar-value {
    position: relative;
    z-index: 1;
    left: 25px;

    font-family: Chalkboard SE;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.06em;

    color: #333333;
  }
`
