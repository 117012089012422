import React from 'react'
import styled from 'styled-components'
import {MAX_ENERGY} from '../../../../../config/constants/stakingNft'
import {secondToDdHhMm} from '../../../../../utils/time'

function StakingNFTTime({stamina}) {
  return (
    <StakingNFTTimeStyled>
      <p>Recover time: {secondToDdHhMm((MAX_ENERGY - stamina) / 3)}</p>
      <h3>{secondToDdHhMm((MAX_ENERGY - stamina) / 3)}</h3>
    </StakingNFTTimeStyled>
  )
}

const StakingNFTTimeStyled = styled.div`
  text-align: center;
  color: #fff;
  font-weight: 400;
  padding: 23px 0px 30px 0px;
  p {
    font-size: 20px;
  }
  h3 {
    font-size: 32px;
    color: #fff;
  }
`
export default StakingNFTTime
