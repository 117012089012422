import { PredictionPool } from '../../state/types'

export const DECIMALS_BID_PRICE = 8
export const DECIMALS_DEFAULT = 18

export const CAKE_PRICE = 11
export const BUSD_USDT_PRICE = 2
export const DPT_PRICE = 0.000481746
export const DPT_BNB_PRICE = 3.48

export const DEPOSIT_DURATION = 86400000
export const LOCK_DURATION = 518400000

const prediction: PredictionPool[] = [
  {
    pid: '0',
    symbol: 'CAKE',
    lpAddresses: {
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      97: '0xF61e1c34386ADD959937a99117C1fa3fE7DFf2F7',
    },
    price: 16,
    linkPancake: 'https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    totalLongSupply: null,
    totalShortSupply: null,
    userData: null,
    allocPoint: null,
  },
  {
    // USDT-BUSD LP
    pid: '1',
    symbol: 'USDT-BUSD LP',
    lpAddresses: {
      56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
      97: '0x2C7Ab52226EDCA513C6FDa19263f2F444747BC84',
    },
    price: 2,
    linkPancake:
      'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059fF775485246999027B3197955',

    totalLongSupply: null,
    totalShortSupply: null,
    userData: null,
    allocPoint: null,
  },
  {
    pid: '2',
    symbol: 'DPT',
    lpAddresses: {
      56: '0xE69cAef10A488D7AF31Da46c89154d025546e990',
      97: '0x4d87EE06A797Ea69f39Bf47C0505a3866Ced1273',
    },
    price: 0.05,
    linkPancake: 'https://pancakeswap.finance/swap?outputCurrency=0xe69caef10a488d7af31da46c89154d025546e990',

    totalLongSupply: null,
    totalShortSupply: null,
    userData: null,
    allocPoint: null,
  },
  {
    pid: '3',
    symbol: 'DPT-BNB LP',
    lpAddresses: {
      56: '0x141e9558f66Cc21c93628400cCa7d830c15c2c24',
      97: '0x157a35a0d28b8d371f89d503a0bc6b793b6adec6',
    },
    price: 500.05,
    linkPancake: 'https://pancakeswap.finance/add/BNB/0xE69cAef10A488D7AF31Da46c89154d025546e990',

    totalLongSupply: null,
    totalShortSupply: null,
    userData: null,
    allocPoint: null,
  },
]

export const priceList = [
  { id: 0, price: 10, src: './images/fileDiviner/price10.png' },
  { id: 1, price: 25, src: './images/fileDiviner/price25.png' },
  { id: 2, price: 50, src: './images/fileDiviner/price50.png' },
  { id: 3, price: 75, src: './images/fileDiviner/price75.png' },
  { id: 4, price: 100, src: './images/fileDiviner/price100.png' },
]

export const pancakeApy = [68, 10.21, 0, 400]

export const price = [CAKE_PRICE, BUSD_USDT_PRICE, DPT_PRICE, DPT_BNB_PRICE]

export default prediction
